import amplitude from "amplitude-js";
import moment from "moment";
import PremiumSliderLabel from "../Components/Popups/PolicyPopup/PremiumSliderLabel/PremiumSliderLabel";
import { setUserSubscriptions } from "../AppSlice";
import { utcToZonedTime } from "date-fns-tz";

export function getCampaignTypeById(id) {
    const types = [
        "Retention",
        "Nurturing",
        "Custom",
        "Policy Review",
        "New Product Introduction",
        "Other",
    ]
    if(types.length >= id) {
        return types[id-1]
    }
    return ""
}

export function getCampaignTypeId(name) {
    const types = [
        "Retention",
        "Nurturing",
        "Custom",
        "Policy Review",
        "New Product Introduction",
        "Other",
    ]
    return types.indexOf(name) + 1  
}


export function getCampaignProviderId(name) {
    const types = [
        "Remark",
        "HubSpot",
        "SalesForce",
        "SmartOffice",
        "Zinnia",
        "Atidot Life",
    ]
    return types.indexOf(name) + 1
}

export function getTrimedValue(str) {
    const comma = str.indexOf(".")

    return str.length >= 6 ? str.slice(0, comma ? comma+2 : 5) + str[str.length-1] : str
}

export function calculateFunnelData(arr) {
    let email = 0
    let open = 0
    let click = 0
    let bookings = 0
    let meetings = 0
    let submited = 0
    let deals = 0
    arr?.filter(({status}) => status !== 100).forEach(({ count }) => email += count);
    arr
        ?.filter(({status}) => status > 200)
        .forEach(({ count }) => open += count)
    arr
        ?.filter(({status}) => status > 300)
        .forEach(({ count }) => click += count)
    arr
        ?.filter(({status}) => status > 400)
        .forEach(({ count }) => bookings += count)
    arr
        ?.filter(({status}) => status > 500)
        .forEach(({ count }) => meetings += count)
    arr
        ?.filter(({status}) => status > 700)
        .forEach(({ count }) => submited += count)
        deals = arr?.find(({status}) => status === 800)?.count || 0

    return {
        email,
        open,
        click,
        bookings,
        meetings,
        "in-pipeline": submited,
        deals
    } 
}

export function getConversionHistoryData (metaData) {
    const { open, click, bookings, meetings, deals } = metaData
    const submited = metaData["in-pipeline"]
    const arr = []
    for (let index = 0; index < 7; index++) {
        arr.push({
            "date": `${index+1} Jun`,
            "open": open + 8*index,
            "click": click + 7*index,
            "bookings": bookings + 5*index,
            "meetings": meetings + 3*index,
            "inpipeline": submited + 2*index,
            "deals": deals + index
        })
    }
    return arr;
}

export function getLabelBySearchType (type) {
    const labels = {
        "name": "Name",
        "created_at": "Date",
        "num_of_policies": "No. Policies",
    }

    return labels[type] ? labels[type] : ""
}

export function isEqual(a, b) {
    if (a.length !== b.length)
      return false;
    else {
  
      for (var i = 0; i < a.length; i++)
        if (a[i] !== b[i])
          return false;
      return true;
    }
}

export function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  }
  
export function isObject(object) {
  return object != null && typeof object === 'object';
} 

export function getDate(string) {
  const date = new Date(string);
  const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
  const day = date.getDate() > 9 ? date.getDate() : "0"+date.getDate();
  return `${month}/${day}/${date.getFullYear()}`
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

        return JSON.parse(jsonPayload);
};

export const get_permission_limit = (userPermissions, permission_name) => userPermissions?.filter(p => p.action === permission_name)[0]?.limit

export const check_permission_limit = (userPermissions, permission_name, current_amount) => {
    const permissionLimit = get_permission_limit(userPermissions, permission_name)
    return (permissionLimit === null || current_amount < permissionLimit)
}


class Amplitude {
    instance = amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY)

    _setUser(user) {
        amplitude.getInstance().setUserId(user)
    }

    _throwEvent(event, params = {}) {
        amplitude.getInstance().logEvent(`P_${event.toUpperCase()}`, params)
    }
}

export const amplitudeService = new Amplitude()


export const processUserLoginData = (response, dispatch, setUserInitials, setUserRole, setUserPermissions, setUserLimit, setUserCompany, setMenuDisabled, setCurrentUser, setAccountType) => {
    localStorage.setItem("token", response.data["access_token"])
    localStorage.setItem("refresh", response.data["refresh_token"])
    const payload = parseJwt(response.data["access_token"])
    dispatch(setCurrentUser(payload))
    localStorage.setItem("userName", payload['username'] || "")
    payload['name'] && localStorage.setItem("name", payload['name'] || "")
    localStorage.setItem("userID", payload['user_id'])
    localStorage.setItem("companyID", payload['company_id'])
    localStorage.setItem("accountType", payload['account_type'])
    localStorage.setItem("can_see_hubspot", response.data?.user["can_see_hubspot"])
    response.data?.user["subscriptions"] && localStorage.setItem("subscriptions", JSON.stringify(response.data?.user["subscriptions"]))

    dispatch(setUserInitials(
        payload['username']?.includes("americanequity") ? "AE" : payload['username']?.charAt().toUpperCase()
    ));

    payload['roles'] && dispatch(setUserRole(payload['roles']))
    payload['permissions'] && dispatch(setUserPermissions(payload['permissions']))
    response.data?.user["subscriptions"] && dispatch(setUserSubscriptions(response.data.user["subscriptions"]))
    payload['user_limit'] && dispatch(setUserLimit(payload['user_limit']))
    payload["company_name"] && dispatch(setUserCompany(payload["company_name"]))
    payload["account_type"] && dispatch(setAccountType(payload["account_type"]))

    amplitudeService._setUser(payload['user_id'])
    
    dispatch(setMenuDisabled(payload["company_name"] === "platform-anonym"))

    return payload
}

export const reverseFormatToBytes = (size) => {

    if (size === "0B") return 0

    const k = 1000;
    var regex = new RegExp('([0-9]+)|([a-zA-Z]+)', 'g');
    var number = size.match(regex)[0];
    var unit = size.match(regex)[1];

    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    if (String(unit).length === 1) {
      return parseFloat(number)
    } else {
      if (String(unit) === "KB") {
        return parseFloat(Math.floor(parseFloat(number) * (Math.pow(100, sizes.findIndex(el => el === unit)))))
      } else {
        return parseFloat(Math.floor(parseFloat(number) * (Math.pow(k, sizes.findIndex(el => el === unit)))))
      }

    }
  }

const si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
];

export const nFormatter = (num, digits, currency = "") => {
    if (!num) num=0
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    /*eslint no-useless-escape: "off"*/
    const regex = new RegExp('^-?\\d+(?:\.\\d{0,' + (digits || -1) + '})?');
    const trimedNumber = parseFloat((num / si[i].value).toString().match(regex)[0])
    return currency + trimedNumber.toString().replace(rx, "$1") + si[i].symbol;
}

export const nBuilder = (formatedNumber) => {
    let returnValue = formatedNumber
    si.filter(({ symbol }) => symbol).forEach((format) => {
        if(formatedNumber.includes(format.symbol)) { 
          const slice = formatedNumber.includes(".") ? 
          formatedNumber
            .replace(format.symbol, "")
            .replace("<", "")
            .split(".")[1].length + 1 
          : 1
          returnValue = formatedNumber.replace(format.symbol, `${format.value}`.slice(slice))
        }
    })
    return parseInt(returnValue.replace('.', ''))
}

export const getCountryCurrencySymbol = () => {
    const currencySymboles = {
        "US": "$",
        "IL": "$",
        "ES": "€",
        "FR": "€",
        "GB": "£",
    }
    const symbol = localStorage.getItem("token") ? currencySymboles[parseJwt(localStorage.getItem("token"))["country_code"]] : "$"

    return symbol ? symbol : ""
}

export const getNextMonths = (data, months = 12) => {
    const timelineKeys = data?.potential_premium_by_date ? Object.keys(data.potential_premium_by_date) : null
    const firstMonth = timelineKeys ? timelineKeys[timelineKeys.length < months ? 0 : timelineKeys.length-months] : null
    const date = timelineKeys ? moment(firstMonth) : moment()
    const twelveMonths = [
        date.format("MMM YYYY")
    ]
    for (let index = 0; index < months-1; index++) {
        twelveMonths.push(date.set('month', date.get('month')+1).format("MMM YYYY"))
    }
    return twelveMonths
}

export const getLastQuarters = () => {
    /* 
    - Generate last 6 quarter from today
    */
    let date = moment();
    let sixQuarters = [];
    [...Array(6)].forEach( () => {
        sixQuarters.push('Q' + date.format("Q_YYYY"))
        date.set('month', date.get('month')-3)
    });
    return sixQuarters
}

export const getTableauDashboard = (url) => {
    /* 
    - 
    */
}

export const validateValue = (item) => {
  if (parseInt(item) && item >= 0) {
    return true
  }
  return false
}

export const scrollTo = (position) => {
    window.scrollTo({
      top: position,
      behavior: "smooth"
    });
}; 

export const precisionKeys = {
    "low": 'low',
    "mid": 'medium',
    "high": 'high',
    "high+": 'super_high'
}  

export const getMoneyQueryFromAction = (action) => {

    if (action.payload["All"]) { return null}
  
    const currency = '$'
    const clean = value => value.replace(currency, "").replace("<", "").replace("K", "000")
    const selectedEntries = Object.entries(action.payload).filter((item) => item[1])
    
    const query = []
    selectedEntries.forEach((filter) => {
      const value = filter[0]
      if (value.includes("-")) {
        const range = value.split("-")
        query.push({
          "operator": 'between',
          "value": `${nBuilder(clean(range[0]))},${nBuilder(clean(range[1]))}`
        })
      } else if (value[0] === "<") {
        query.push({"operator": 'lte', "value": `${nBuilder(clean(value))}`})
      } else if (value.includes("+")) {
        query.push({"operator": 'gte', "value": `${nBuilder(clean(value))}`})
      }
    })
  
    return query
}
  
export const getPercentageQueryFromAction = (action) => {
  
      if (action.payload["All"]) { return null}
    
      const convert = value => parseFloat(value.replace('%', "").replace("<", ""))
      const selectedEntries = Object.entries(action.payload).filter((item) => item[1])
      
      const query = []
      selectedEntries.forEach((filter) => {
        const value = filter[0]
        if (value.includes("-")) {
          const range = value.split("-")
          query.push({
            "operator": 'between',
            "value": `${convert(range[0])},${convert(range[1])}`
          })
        } else if (value[0] === "<") {
          query.push({"operator": 'lte', "value": `${convert(value)}`})
        } else if (value.includes("+")) {
          query.push({"operator": 'gte', "value": `${convert(value)}`})
        }
      })
    
      return query
}
  
export const getQueryFromPlainAction = (action) => {
    const query = []
  
    const selected = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
  
    if(selected.length > 0) {
        selected.forEach((key) => {
  
        if (!query.find(({value}) => value === key)) {
            query.push({
            "operator": "equals",
            "value": key
          })
        }
        
      })
  
      return query
    }
  
    return null
}
  
  
export const getQueryFromPriority = (
  action, 
  longType = false, 
  replacement = {
    "high": 'H', "medium": "M", "low": "L"
  }
) => {
  
    const query = []
  
    const selected = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
    if(selected.length > 0) {
      selected.forEach((key) => {
  
        console.log(key);
      if (!query.find(({value}) => value === key)) {
          query.push({
          "operator": "equals",
          "value": longType ? key[0]?.toUpperCase()+key.slice(1, key?.length) : replacement[key]
        })
      }
      
    })
  
    return query
  }
  
  return null
}

export const addRealMonths = (date, count) => {
  const fm = moment(date).add(count, 'M');
  const fmEnd = moment(fm).endOf('month');
  return date.date() != fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm;  
}

export const filterMissingFields = (key, list, mapping) => {
  
  if (["Probability to lapse", "Priority"].includes(key)) {
    return true 
  }
  if (list?.length) {
    const isNotMissing = list[0][mapping[key]] !== undefined
    return isNotMissing
  }
  return true 
}


export const getSuggestedPremiumMarksBroker = (average, current, max) => {
  const avgValue = 194*(average/max)
  const value = 194*(current/max)
  
  return {
    avgValue,
    value,
    position: avgValue !== value ? "relative" : "absolute",
    left: avgValue !== value ? value-1 : "unset",
  }
}

export const getSuggestedPremiumMarks = (average, current, currency) => {
  const max = average > current ? average * 1.25 : current * 1.25
  const avgValue = average > 0 ? Math.floor((average/(max))*100) : 42
  const value = current > 0 && average > 0 ? Math.floor((current/(max))*100) : 10
  
  return {
    avgValue,
    value,
    marks: [
    {
      value: 0,
      label: ''
    },
    {
      value: avgValue,
      label: <PremiumSliderLabel title="Suggested Premium" value={average > 0 ?  nFormatter(average, average > 999 ? 2 : 0, currency) : "N/A"}/>
    },
    {
      value,
      label: <PremiumSliderLabel title="Current Premium" value={current > 0 ? nFormatter(current, average > 999 ? 2 : 0, currency) : "N/A"}/>
    },
    {
      value: 100,
      label: ''
    },
  ]}
}

export const getRetainedValueMarks = (value) => {
  switch (value) {
    case 3:
      return 194;
    case 2:
      
      return 129;
    case 1:
      
      return 64;
  
    default:
      return 64;
  }
}

export const getLapseRisk = (policy, precisionConfig) => {
  if (!precisionConfig) {
    return ""
  }
  const { high_m_res, med_m_res, low_m_res, lapse_score } = policy

  if (high_m_res > precisionConfig.high) {
    return "high"
  }

  if (med_m_res > precisionConfig.medium) {
    return "medium"
  }

  if (low_m_res > precisionConfig.low) {
    return "low"
  }  

  if (lapse_score > precisionConfig.high) {
    return "high"
  }

  if (lapse_score > precisionConfig.medium) {
    return "medium"
  }
  
  if (lapse_score > precisionConfig.low) {
    return "low"
  }

  return "low"
}

export const getTimeElapsed = (date) => {
  const now = new Date()
  const start = new Date(date) 
  const time = (now.valueOf() - start.valueOf())/ 1000

  if (time < 60) {
    return "Few moments ago"
  }
  if (time < 3600) {
    const minutes = Math.floor(time/60)
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`
  }
  if (time < 86400) {
    const hours = Math.floor(time/3600)
    return `${hours} hour${hours < 1 ? "s" : ""} ago`
  }
  const monthDiff = now.getMonth() - start.getMonth() + 
  (12 * (now.getFullYear() - start.getFullYear()))
  if (monthDiff < 1) {
    const days = Math.floor(time/86400)
    return `${days} days${days < 1 ? "s" : ""} ago`
  }
  const yearDiff = now.getFullYear() - start.getFullYear()
  if (yearDiff < 1) {
    return `${monthDiff} month${monthDiff < 1 ? "s" : ""} ago`
  }
  return `${yearDiff} year${yearDiff < 1 ? "s" : ""} ago`


} 

export const buildQueryString = (filters) => {
  const queries = []
  Object.keys(filters)
  .filter((item) => filters[item]?.length)
  .map((item) => {
    const arr = filters[item]
    if (["age_at_issue", "face_amount", "annualized_premium", "census_household_income_average_weighted", "annualized_premium_recommended"].includes(item)) {
      let query = ""
      if (arr.length === 1 && ["lte", "gte"].includes(arr[0].operator)) {
        query = `${item}:${arr[0].operator}=${arr[0].value}`
      }
      if (arr.length === 1 && arr[0].operator === "between") {
        query = `${item}:between=${arr[0].value}`
      }
      if (arr.length > 1) {
        const offset = item === "age_at_issue" ? 1 : 0 
        const end = arr[arr.length-1].value.includes(",") ? arr[arr.length-1].value.split(",")[1] : arr[arr.length-1].value
        const start = arr[0].value.includes(",") ? arr[0].value.split(",")[0] : arr[0].value
        if (arr[0].operator === "lte" && arr[arr.length-1].operator !== "gte") {
          query = `${item}:lte=${end}`
        } else if (arr[arr.length-1].operator === "gte" && arr[0].operator !== "lte") {
          query = `${item}:gte=${start}`
        } else if (arr[arr.length-1].operator !== "gte" && arr[0].operator !== "lte") {
          const breakPoints = []
          arr.map(({ value }, index) => {
            if (index !== arr.length - 1) {
              if (parseFloat(value.split(",")[1])+offset !== parseFloat(arr[index+1].value.split(",")[0])) {
                breakPoints.push(index)
                breakPoints.push(index+1)
              }
            }
            
          })
          if (breakPoints.length) {
            query = `${item}:between=`
            breakPoints.forEach((id, index) => {
              if(index === 0) {
                query = query + `${start},${arr[id].value.split(",")[1]}|$or|`
              } else if(index === breakPoints.length-1) {
                query = query + `${arr[id].value.split(",")[0]},${end}`
              }
            })
          } else {
            query = `${item}:between=${start},${end}`
          }
        } 
      }
      queries.push(query)
    } else {
      let query = `${item}=`
      arr.forEach(({ operator, value }, index) => {
        if (operator === "equals") {
          query = query + `${value}${index !== arr.length - 1 && arr.length > 1 ? "|$or|" : ""}`
        }
      })
      queries.push(query)
    }
  })

  return queries.join("&")+`${queries.length > 0 ? "&" : ""}`
}

export const getNameString = (first_name, last_name) => {
  if (first_name && last_name) {
    return first_name + " " + last_name
  }
  if (first_name) {
    return first_name
  }
  if (last_name) {
    return last_name
  }

  return ""
}

export const getDateString = (value) => {
  const string = value?.toString()
  if (value && string) {
    return `${string.slice(0, 4)}-${string.slice(4, 6)}-${string.slice(6, 8)}`
  }

  return ""
}


export const getLapseRiskBin = (item) => {
  if (item?.toLowerCase()?.includes("high")) {
    return "high"
  }
  if (item?.toLowerCase()?.includes("medium")) {
    return "mid"
  }
  if (item?.toLowerCase()?.includes("low")) {
    return "low"
  }
  return "low"
}

export const getPriorityFromRank = (rank, max) => {
  if (!max) {
    return "low"
  }
  const step = Math.floor(max/3)
  if (rank >= 1 && rank <= step) {
    return "high"
  }
  if (rank > step && rank <= step*2) {
    return "medium"
  }
  if (rank > step*2) {
    return "low"
  }
  return "low"
}

const dateToTime = (local) => {
  let hours = local.getHours();
  let minutes = local.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0'+minutes : minutes;
  let time = hours + ':' + minutes + ' ' + ampm;
  return time
}

export const getLocalTime = (micro, timezone) => {
  return dateToTime(utcToZonedTime(micro, timezone));
}

export const getPtdDate = (micro, timezone) => {
  const local = utcToZonedTime(micro, timezone);
  return `${local.toLocaleString('default', { month: 'long' })} ${local.getDate()},  ${local.getFullYear()} at ${dateToTime(local)} PDT`;
}

export const getTimeRange = (date, range) => {
    const startSlot = parseInt(range.start.split(":")[0])+parseInt(range.start.split(":")[1]/60)
    const startOfDay = new Date(date);
    const endOfDay = new Date(date);
    if (startOfDay.getHours()+startOfDay.getMinutes()/60 < startSlot) {
        startOfDay.setHours(parseInt(range.start.split(":")[0]), parseInt(range.start.split(":")[1], 0, 0))
    }
    endOfDay.setHours(parseInt(range.end.split(":")[0]), parseInt(range.end.split(":")[1]), 0, 0);
    const startTime =  Math.floor(startOfDay.getTime()/1000) 
    const start = startTime-startTime%300+300
    const end = Math.floor(endOfDay.getTime()/1000)
    return [start, end]
}

export const getRanges = (slots, date) => {
    const ranges = slots
        .filter(({ day, end }) => {
            return day === date.getDay() &&
            date.getHours()+date.getMinutes()/60 < parseInt(end.split(":")[0])+parseInt(end.split(":")[1]/60) &&
            (day !== 0 || day !== 6)
        }
        )
        .map((range) => getTimeRange(date, range))

    return ranges 
}

