import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTableName,
  selectProjectId,
  selectDBType
} from "../../../Pages/NewProject/NewProjectSlice";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import newProjectApiService from "../../../Pages/NewProject/NewProjectService";

import { useStyles } from './style'

function SnowFlakeConn({ onDBConneted, onNext }) {
  const classes = useStyles();
  const [DBName, setDBName] = React.useState("");
  const [SchemaName, setSchemaName] = React.useState("");
  const [TableName, setTableName] = React.useState("");

  const [ButtonText, setButtontext] = React.useState("Test Connection");
  const [loadingConnection, setLoadingConnection] = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState("");
  const [severityAlert, setSeverityAlert] = React.useState("");
  const [tablesList, setTablesList] = React.useState([]);
  const [isFull, setIsFull] = React.useState(false);
  const dispatch = useDispatch();
  const tableName = useSelector(selectTableName);
  const projectId = useSelector(selectProjectId);
  const DBType = useSelector(selectDBType);
  const ApiService = new newProjectApiService();
  

  const handleAway = () => {
  };

  React.useEffect(() => {
    const generalDBData = (
      DBName !== "" &&
      SchemaName !== "" &&
      TableName !== ""
    )
    
    if (
      generalDBData 
    ) {
      setIsFull(true);
    } else {
      setIsFull(false);
    }
  }, [DBName, SchemaName, TableName]);


  function testConnectionClick() {
    setDisplayMessage("");
    setSeverityAlert("");
    setTablesList([]);
    setLoadingConnection(true);
    setButtontext("Testing Connection");

      const payload = {
          credentials: {
            database: DBName,
            schema: SchemaName,
            table: TableName,
          },
          type: "snowflake"
        }

      ApiService._postDataBaseConnection(projectId ? projectId : null, payload, (response) => {
        setLoadingConnection(false);
        setTablesList(DBType !== "s3" ? response.data.table_name : []);
        onDBConneted(response)

        setDisplayMessage("Successful Connection");
        setSeverityAlert("success");
      }, (error) => {
        console.log(error);
        setLoadingConnection(false);

        setDisplayMessage(
          "Unable to connect to the database, please check your credentials"
        );
        setSeverityAlert("error");
      })
    
  }

  return (
    <ClickAwayListener onClickAway={handleAway}>
      <Grid container direction="column"
          alignItems="center"
          spacing={0}>
        <Grid>
          <Typography variant="h4" className={classes.customTitle}>
            <Box sx={{ fontWeight: 500, m: 1 }}>Data source parameters</Box>
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={0}
          className={classes.root}
          data-testid="dbConnWrapper"
        >
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6} className={classes.maxWidth}>
              <div className={classes.customInput}>
                <label className={classes.lblNombre}>DB name</label>
                <input
                    id="db_name"
                    value={DBName}
                    type="text"
                    required
                    // className={classes.styleInput}
                    onChange={(event) => setDBName(event.target.value)}
                    
                  ></input>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.maxWidth}>
              <div className={classes.customInput}>
                <label className={classes.lblNombre}>Schema name</label>
                <input
                    id="schema_name"
                    value={SchemaName}
                    type="text"
                    required
                    // className={classes.styleInput}
                    onChange={(event) => setSchemaName(event.target.value)}
                    
                  ></input>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.maxWidth}>
              <div className={classes.customInput}>
                <label className={classes.lblNombre}>Table name</label>
                <input
                    id="table_name"
                    value={TableName}
                    type="text"
                    required
                    // className={classes.styleInput}
                    onChange={(event) => setTableName(event.target.value)}
              
                  ></input>
              </div>
            </Grid>

            <Grid item style={{ display: "contents" }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  className={
                    loadingConnection
                      ? classes.loadingButton
                      : classes.testingButton
                  }
                  style={{ textTransform: "none" }}
                  onClick={testConnectionClick}
                  disabled={!isFull}
                  data-testid="testButton"
                >
                  {ButtonText}
                  {loadingConnection ? (
                    <CircularProgress
                      style={{
                        color: "#005071",
                        marginLeft: "7px",
                        width: "13px",
                        height: "13px",
                      }}
                    />
                  ) : null}
                </Button>
                {displayMessage !== "" && severityAlert !== "" ? (
                  <Alert
                    variant="outlined"
                    severity={severityAlert}
                    className={
                      severityAlert === "error"
                        ? classes.alertErrorStyle
                        : classes.alertSuccessStyle
                    }
                    iconMapping={{
                      success: <CheckIcon fontSize="inherit" />,
                    }}
                    data-testid={severityAlert !== "error" ? "testConnSuccess" : "testConnError"}
                  >
                    {displayMessage}
                  </Alert>
                ) : null}
              </Grid>
            </Grid>
            {severityAlert === "success" && DBType !== "s3" && DBType !== "url"  ? (
              <Grid item xs={12}>
                <div className={classes.customInput}>
                  <label className={classes.lblNombre}>Tables Name</label>
                  <Select
                    value={tableName}
                    onChange={(event) =>
                      {debugger; dispatch(setTableName(event.target.value))}
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    placeholder="Choose a Tables Name"
                    className={classes.customNameInput}
                    sx={{'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#363ed3',
                  }}}
                  >
                    {tablesList.map((value, idx) => (
                      <MenuItem key={idx} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
            ) : null}
          </Grid>
          <Grid container justifyContent="center" className={classes.nextButtonContainer}>
            <Button onClick={onNext} className={classes.nextButton} disabled={severityAlert !== "success"}>Next</Button>
          </Grid>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}

export default SnowFlakeConn;
