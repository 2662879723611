import React, { useEffect, useState } from "react";
import { Route, useHistory, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CsvBuilder } from "filefy";

import { Button, Grid, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CircularProgress, makeStyles } from "@material-ui/core";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

// Components
import campaignApiService from "./CampaignService";
import { setCampaignData, setCampaignId } from "../CreateCampaign/CreateCampaignSlice";
import CampaignView from "./CampaignView";
import CampaignItem from "./CampaignItems/CampaignItems";

// Redux
import { resetRunLeads, selectRunLeads, setActiveOption, selectRunLeadsLoading } from "../../AppSlice";

import { calculateFunnelData, getConversionHistoryData, parseJwt, amplitudeService } from "../../global/helpers";

import "./style.css"
import sampleData from "./sampleCampaign.json"
import sampleMetadata from './sampleCampaignMetadata.json'
import moment from 'moment/moment.js';
import HeaderContainer from "../../Components/Headers/HeaderContainer/HeaderContainer";
import { User } from "../../global/user";

const useStyles = makeStyles(() => ({
  tableCointainer: {
    borderRadius: 6,
    border: "solid 1px #ecf1f9",
    backgroundColor: "#fff",
    marginBottom: 10,
    marginLeft: 10,
    padding: 30
  },
  tableCointainerView: {
    borderRadius: 6,
    border: "solid 1px #ecf1f9",
    backgroundColor: "#fff",
    marginBottom: 10,
    marginTop: 10,
    padding: 30
  },
  tableTitle: {
    marginTop: 24,
    marginLeft: 32.1,
    marginBottom: 16,
    fontSize: 16,
    fontWeight: 600
  },
  exportToCSVIcon: {
    color: '#d82832',
  },
  exportToCSVButtonContainer: {
    border: '1px solid #d9e1ee',
    borderRadius: 6,
    color: '#d9e1ee'
  },
  exportToCSVButton: {
    backgroundColor: '#fff!important',
    borderRadius: "6px!important",
    textTransform: 'capitalize!important',
    fontSize: "12px!important"

  },
  marginRightButton: {
    marginRight: "18px"
  },
  table: {
    width: "auto !important",
    marginTop: 16,
    height: 724,
  },
  tableHeader: {
    borderRadius: 6,
    backgroundColor: "#eef0f4",
    height: 60,
    '& .MuiTableCell-stickyHeader': {
      textAlign: "left",
      backgroundColor: "#eef0f4",
      paddingBottom: 0,
      paddingTop: 0,
    },
    '& th:first-child': {
      borderRadius: '6px 0 0 6px'
    },
    '& th:last-child': {
      borderRadius: '0 6px 6px 0'
    },
  },
  tableCell: {
    borderBottom: "none !important",
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: "14px",
    color: "#312E40",
    fontWeight: 400,
    paddingTop: 0,
    paddingBottom: 0
  },
  tableRow: {
    backgroundColor: "#eef0f4",
    '& td:first-child': {
      borderRadius: '6px 0 0 6px'
    },
    '& td:last-child': {
      borderRadius: '0 6px 6px 0'
    },
    height: 60,
  },
  actionsPopoverContainer: {
    borderRadius: 6,
    border: "solid 1px #ecf1f9",
    backgroundColor: "#fff",
    padding: 12,
    maxWidth: 250
  },
  actionButtonContainer: {
    width: '100%',
    textAlign: "left",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0000001f"
    }
  },
  actionButton: {
    color: "#312E40",
    padding: "3px 0",
    margin: 0,
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 400,
    textTransform: "capitalize"
  },
  cellText: {
    "&.MuiTypography-root": {
      fontFamily: "Roboto",
      fontSize: 13,
      fontWeight: 700
    }
  },
  noProjectsGrid: {
    opacity: 0.5,
    height: "50vh",
    textAlign: 'center',
    paddingTop: "25vh"
  },
  portfolioAnalysis: {
    paddingRight: 24
  },
  portfolioAnalysisTitle: {
    fontFamily: "Roboto",
    "&.MuiTypography-root": {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: "29px",
      paddingBottom: 5,

    }
  },
  portfolioAnalysisText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px"
  },
  portfolioAnalysisValue: {
    fontFamily: "Lato!important",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "17px",
    color: '#232033'
  },
}))


const tableHeaders = [
  'Campaign Name',
  'Start Date',
  'Status',
  'Type',
  'Prospects',
  'Premium Opportunity',
  'Conversions',
  'Not interested/ \n unsubscibed',
  'Generated Premium',
  'Export'
]

const ApiService = new campaignApiService()

const Campaigns = ({ getCampaigns }) => {
  const currentUser = parseJwt(localStorage.getItem("token"))
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [openActionsPopover, setOpenActionsPopover] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState({ left: 0, top: 0 })
  const [selectedCampaignId, setSelectedCampaignId] = useState(null)
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [selectedCampaignStatus, setSelectedCampaignStatus] = useState(null)
  const [selectedCampaignDownloadStatus, setSelectedCampaignDownloadStatus] = useState(false)
  const [campaignPolicies, setCampaignPolicies] = useState(null)
  const [campaignDetails, setCampaignDetails] = useState(null)
  const [policiesByStatus, setPoliciesByStatus] = useState(null)
  const [funnelData, setFunnelData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [page, setPage] = useState(0);
  const [numOfPolicies, setNumOfPolicies] = useState(0);

  const runLeads = useSelector(selectRunLeads)
  const loading = useSelector(selectRunLeadsLoading)

  const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleActionsClick = (e, id) => {
    e.stopPropagation();
    const position = e.target.getBoundingClientRect();
    setPopoverAnchor({ top: position.top, left: position.left });
    setSelectedCampaignId(id);
    const lead = runLeads.find((item) => item.id === id);
    lead?.status && setSelectedCampaignStatus(lead.status);
    lead?.webhooks_export_s3_path && !isBroker && setSelectedCampaignDownloadStatus(lead.policies_s3_filename);
    setSelectedCampaign(lead)
    setOpenActionsPopover((openActions) => !openActions);
  }

  const handleEditClick = () => {
    if (selectedCampaignId && runLeads) {
      dispatch(setCampaignId(selectedCampaignId))
      const selectedCampaign = runLeads?.find(({ id }) => id === selectedCampaignId)
      if (selectedCampaign?.status !== "Stopped") {
        dispatch(setCampaignData({
          ...selectedCampaign,
          type: selectedCampaign.type_info,
          provider: selectedCampaign.provider_info.id
        }))
        history.push("/NewCampaign")
      }
    amplitudeService._throwEvent("CAMPAIGN_EDIT")

    }
  }

  const handleArchiveClick = () => {
    if (selectedCampaignId) {
      if (isBroker) {
        ApiService._patchEditCampaginBroker(selectedCampaignId, { "is_archived": true }, () => {
          getCampaigns()
        }, (error) => console.error(error))
        amplitudeService._throwEvent("CAMPAIGN_ARCHIVE")
        
      } else {
        const payload = {
          "campaign_id": selectedCampaignId,
          "updates": { "status": 999 }
        }
        ApiService._patchDeleteCampagin(selectedCampaignId, payload, () => {
          getCampaigns()
        }, (error) => console.error(error))
        amplitudeService._throwEvent("CAMPAIGN_ARCHIVE")
        
      }
  }
  }

  const handlePauseClick = () => {
    if (selectedCampaignId) {
      if (isBroker) {
        ApiService._patchEditCampaginBroker(selectedCampaignId, { "status": selectedCampaignStatus !== 40 ? "40" : "30" }, () => {
          getCampaigns()
        }, (error) => console.error(error))
      } else {
        ApiService._patchUpdateCampaginStatus(selectedCampaignId, selectedCampaignStatus !== 40 ? 40 : 30, () => {
          getCampaigns()
        }, (error) => console.error(error))
      }

      const message = selectedCampaignStatus !== 40 ? 'CAMPAIGN_PAUSE' : 'CAMPAIGN_RESUME'
      amplitudeService._throwEvent(message)
  }
  }

  const exportToCsv = (policiesString, name) => {
    if (policiesString?.length > 0) {
      setSelectedCampaignId(null)
      const data = policiesString.split('\n')
      const csvHeaders = data.shift().split(',')
      const csvData = data.map((row) => {
        const rowValue = row.split(",")
        return rowValue
      })

      let fileName = name ? name.replace(" ", "_") + ".csv" : "campaign_default.csv";
      let csvBuilder = new CsvBuilder(fileName.replace(" ", "_"))
        .setColumns(csvHeaders)
        .addRows(csvData)
      csvBuilder.exportFile()
    }
  }

  const handleExportClick = (e, exportId = null) => {
    if (isBroker && selectedCampaignId) {
      ApiService._getExportCsv(exportId || selectedCampaignId, (response) => {
        exportToCsv(response.data, selectedCampaign.name)
      }, (err) => console.error(err))
    } else if (selectedCampaignId || exportId) {
      const selectedCampaign = runLeads?.find(({ id }) => id === selectedCampaignId || id === exportId)
      selectedCampaign?.webhooks_export_s3_path ?
        ApiService._getExportCsvDownload(exportId || selectedCampaignId, (response) => {
          if (response.data?.status_code !== "403") {
            exportToCsv(response.data, selectedCampaign.name)
          }
        }, (err) => console.error(err))
        : ApiService._getExportCsvById(selectedCampaignId, (response) => {
          getCampaigns()
        }, (err) => {
          console.error(err)
        })
        amplitudeService._throwEvent("CAMPAIGN_EXPORT")
      }
  }

  const loadCampaign = (id, openCampaignPage) => {
    // Display demo data instead real
    if (!localStorage.getItem(`demo_campaign_finished_${currentUser.email}`)) {

      processCampaignData(sampleData)
      processCampaignMetaData(sampleMetadata)
      if (openCampaignPage) {
        history.push("/campaigns/view?" + id)
      }
      return
    }

    if (isBroker) {
      ApiService._getViewCampaignList(id, (response) => {
        processCampaignDataBroker(response.data)
      }, (err) => console.error(err), page)
      ApiService._getCampaignById(id, (response) => {
        processCampaignData(response.data)
        processCampaignMetaDataBroker(response.data)
        if (openCampaignPage) {
          history.push("/campaigns/view?" + id)
        }
      }, (err) => console.error(err))
    } else {
      ApiService._getViewCampaign(id, (response) => {
        processCampaignData(response.data)
      }, (err) => console.error(err), page)
      ApiService._getCampaignData(id, (response) => {
        processCampaignMetaData(response.data)
        if (openCampaignPage) {
          history.push("/campaigns/view?" + id)
        }
      }, (err) => console.error(err))
    }
  }

  const processCampaignData = (data) => {
    setCampaignPolicies(data.data_list)
    setNumOfPolicies(data.count)
  }

  const processCampaignDataBroker = (data) => {
    setCampaignPolicies(
      data.policies
        ?.map((item) => ({
          email: item.email ? item.email : "N/A",
          issue_date: item.issue_date ? item.issue_date : "N/A",
          policy_id: item.policy ? item.policy : "N/A",
          status: item.status_in_campaign ? item.status_in_campaign : "N/A",
          premium: item.annualized_premium ? item.annualized_premium : "N/A"
        }))
    )
  }

  const processCampaignMetaData = (metadata) => {
    setCampaignDetails(metadata.metadata)
    const data = calculateFunnelData(metadata?.data_statuses_count)
    setPoliciesByStatus(data)
    data && setFunnelData(
      Object.entries(data).map((item, index) => ({
        "name": item[0][0].toUpperCase() + item[0].slice(1, item[0].length),
        "value": item[1],
        "color": `#363ed3${index !== 0 ? 100 - 5 * index : ''}`
      }))
    )
    setHistoryData(getConversionHistoryData(data))
    dispatch(setActiveOption(data["name"]))
    
  }

  const processCampaignMetaDataBroker = (res) => {
    setCampaignDetails({
      ...res.metadata,
      num_outcome_policies: res.metadata.policies_count,
      premium_opportunity: res.metadata.potential_premium,
      conversions: res.metadata.converted_policies,
      unsubscribed: res.metadata.unsubscribed_policies,
      aggregated_premium: res.metadata.converted_premium
    })
    setNumOfPolicies(res.metadata.policies_count)
    const data = calculateFunnelData(res?.policy_statuses)
    setPoliciesByStatus(data)
    data && setFunnelData(
      Object.entries(data).map((item, index) => ({
        "name": item[0][0].toUpperCase() + item[0].slice(1, item[0].length),
        "value": item[1],
        "color": `#363ed3${index !== 0 ? 100 - 5 * index : ''}`
      }))
    )
    setHistoryData(getConversionHistoryData(data))
    dispatch(setActiveOption(data["name"]))
    
  }

  const handleViewClick = (id) => {
    setSelectedCampaignId(id)
    if (id) {

      loadCampaign(id, true)

    }
  }

  useEffect(() => {
    if (!runLeads) {
      getCampaigns()
    }
  }, [runLeads])

  useEffect(() => {
    return () => {
      dispatch(resetRunLeads())
    }
  }, [])

  useEffect(() => {
    if (selectedCampaignId && page !== 0) {
      if (isBroker) {
        ApiService._getViewCampaignList(selectedCampaignId, (response) => {
          processCampaignDataBroker(response.data)
        }, (err) => console.error(err), page)
      } else {
        ApiService._getViewCampaign(selectedCampaignId, (response) => {
          processCampaignData(response.data)
        }, (err) => console.error(err), page)
      }
    }
  }, [page, selectedCampaignId])

  useEffect(() => {
    if (history.location.pathname === "/campaigns/view" && !selectedCampaignId) {
      dispatch(setActiveOption("Campaigns"))
      setSelectedCampaignId(history.location.search.replace("?", ""))
      loadCampaign(history.location.search.replace("?", ""), false)
    }
  }, [dispatch, history.location, selectedCampaignId, page])


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Switch>
        <Route exact path="/campaigns">
          <HeaderContainer title="Campaigns"/>
          <Grid container>
            <Grid item xs={12} className={classes.tableCointainer}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <Grid item className={classes.marginRightButton} style={{ display: "none" }}>
                  <div className={classes.exportToCSVButtonContainer}>
                    <Button
                      variant="outlined"
                      disableElevation={true}
                      disableFocusRipple={true}
                      className={classes.exportToCSVButton}
                      endIcon={<ArrowRightAltIcon className={classes.exportToCSVIcon} />}
                    >
                      Export to CSV
                    </Button>
                  </div>
                </Grid>
              </Grid>

              <TableContainer className={classes.table}>
                {loading ? 
                  <Grid container justifyContent="center" alignContent="center" style={{minHeight: 600}}>
                    <CircularProgress style={{color: "#005071",width:96,height:96}}/>
                  </Grid>
                  : !runLeads?.length ?
                  <Grid className={classes.noProjectsGrid}>
                    <Typography variant="h3"> No Campaigns </Typography>
                  </Grid>
                  : <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ borderRadius: 6 }}>
                    <TableRow className={classes.tableHeader}>
                      {tableHeaders.map((header, index) => (
                        <TableCell className={classes.tableCell} key={index} align="center">
                          <Grid container justifyContent="space-between">
                            <Grid item sx={{ padding: "10px 0" }}>
                              <Typography className={classes.cellText}>{header}</Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                      ))}
                      <TableCell style={{ padding: 0 }} />
                    </TableRow>
                    <Popover
                      open={openActionsPopover}
                      anchorReference="anchorPosition"
                      anchorPosition={popoverAnchor}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClick={() => setOpenActionsPopover(false)}>
                      <Grid container className={classes.actionsPopoverContainer}>
                        {
                          (
                            selectedCampaignStatus !== "Stopped" &&
                            selectedCampaignStatus !== "Paused"
                          ) &&
                          <Grid item className={classes.actionButtonContainer} onClick={handlePauseClick}>
                            <Typography className={classes.actionButton}>
                              {selectedCampaignStatus !== 40 ? "Pause/Stop" : "Resume"}
                            </Typography>
                          </Grid>
                        }
                        { !selectedCampaign?.demo && <Grid item className={classes.actionButtonContainer} onClick={handleArchiveClick}>
                          <Typography className={classes.actionButton}>
                            Archive
                          </Typography>
                        </Grid>}
                        <Grid item className={classes.actionButtonContainer} onClick={handleExportClick}>
                          <Typography className={classes.actionButton}>
                            {selectedCampaignDownloadStatus ? "Download" : "Export"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Popover>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ height: 2 }}></TableRow>
                    {
                      runLeads
                        ?.slice()
                        ?.sort((a, b) => moment(a?.created_at) > moment(b?.created_at) ? 1 : -1)
                        ?.map((lead) => {
                          return <CampaignItem
                            item={lead}
                            selectedCampaignId={selectedCampaignId}
                            handleClick={handleViewClick}
                            handleActions={handleActionsClick}
                            handleExport={handleExportClick} />
                        })

                    }
                  </TableBody>
                </Table>
                }
              </TableContainer>
            </Grid>
          </Grid>
        </Route>

        {/* Dashboard **/}
        <Route path="/campaigns/view">
          <HeaderContainer title="Campaign Results"/>
          <CampaignView
            campaignDetails={campaignDetails}
            campaignPolicies={campaignPolicies}
            numOfPolicies={numOfPolicies}
            policiesByStatus={policiesByStatus}
            funnelData={funnelData}
            EnagagementData={historyData}
            page={page}
            handleChangePage={handleChangePage} />
        </Route>
      </Switch>
    </Grid>
  )
}

export default Campaigns