import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    controlsContainer: {
        paddingBottom: 18
    },
    tableTitle: {
        fontFamily: "Roboto",
        fontSize: 24,
        fontWeight: 700,
        lineHeight: "28px",
        textAlign: "center",
        paddingBottom: 15
    },
    tableCointainer: {
        borderRadius: 6,
        border: "solid 1px #ecf1f9",
        backgroundColor: "#fff",
        padding: 30,
        paddingTop: 0,
    },
    tableHeader: {
        borderRadius: 6,
        backgroundColor: "#F9FAFB",
        height: 44,
        '& .MuiTableCell-root': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '18px',
            padding: 12,
            color: "#667085",
            borderBottom: "none"
        },
        '& .MuiTableCell-stickyHeader': {
            textAlign: "left",
            backgroundColor: "#F9FAFB",
        },
        '& th:first-child': {
            borderRadius: '6px 0 0 6px'
        },
        '& th:last-child': {
            borderRadius: '0 6px 6px 0',
            width: 176
        },
        '& td': {
            padding: 12,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '18px',
        }
    },
    tableCell: {
        borderBottom: "none !important",
        fontFamily: "Roboto",
        fontSize: 14,
        color: "#101828",
        fontWeight: 500,
        paddingTop: 0,
        paddingBottom: 0
    },
    tableBody: {
        backgroundColor: '#fff',
        borderRadius: 6,
        borderCollapse: 'collapse'
    },
    tableRow: {
        borderBottom: "2px solid #ECF1F9;",
        marginLeft: 6,
        marginTop: 16,
        fontSize: 14,
        '& .MuiTableCell-root': {
            padding: '8px 16px'
        },
        '& td:last-child': {
            paddingLeft: 0
        },
    },
    tableButton: {
        color: "#3D3D3D",
        height: 48,
        width: 48,
        '& svg': {
            width: 24
        },
        '& path': {
            fill: "#3D3D3D"
        }
    },
    nameContainer: {
        display: "flex",
        alignItems: "center",
        gap: 12
    },
    noResult: {
        minHeight: 330,
        alignItems: "center",
        '& .MuiTypography-root': {
            fontSize: 32,
            fontWeight: 500
        }
    },
}));