import { makeStyles, Slider, styled } from "@material-ui/core";
import BgMale from "../../../Assets/icon-male-alt.svg";
import BgFemale from "../../../Assets/icon-female-alt.svg";

export const useStyles = makeStyles(() => ({
    popoverRoot: {
        background: "rgb(0 0 0 / 50%)",
        "& .MuiPopover-paper": {
            maxWidth: 1196,
            width: "100%",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.22)",
            borderRadius: 6,
            overflowX: "auto"
        }
    },
    backdrop: {
        background: "rgb(0 0 0 / 50%)",
    },
    popoverRetention: {
        "& .MuiPopover-paper": {
            background: "#F9FAFB",
        }
    },
    popoverNewbuisness: {
        "& .MuiPopover-paper": {
            background: "#F9FAFB",
        }
    },
    rootContainer: {
        width: "100%",
        padding: "24px",
    },
    popupContentContainer: {
        gap: 12,
    },
    detailsContainer: {
        gap: 12,
        "& h1": {
            fontFamily: "Roboto",
            fontWeight: 500,
            fontSize: 20,
            margin: 0,
            color: "#312E40"
        }
    },
    detailsContent: {
        width: "100%",
        padding: 25,
        borderRadius: 6,
        border: "1px solid #D9E1EE",
        background: "#FFFFFF",
        padding: "24px"
    },
    premiumContent: {
        maxWidth: 164,
        gap: 16,
        padding: "14px",
        "& p": {
            margin: 0,
            "&.current": {
                fontSize: 16,
                lineHeight: "18px"
            },
            "&.recommend": {
                fontSize: 13,
                fontWeight: 500,
                color: "#1F8A41",
                display: "flex",
                alignItems: "center",
                "&.increase": {
                    color: "#1F8A41",
                },
                "&.decrease": {
                    color: "#FF4B62",
                    "& svg": {
                        transform: "rotate(180deg)"
                    },
                    "& path": {
                        stroke: "#FF4B62"
                    },
                },
                "& svg": {
                    marginLeft: 2
                },
            }
        }
    },
    storyContent: {
        width: "100%",
        maxHeight: 408,
        overflowY: "auto"
    },
    detailsSmall: {
        maxWidth: 286,
    },
    detailsMid: {
        maxWidth: 322,
    },
    detailsBig: {
        maxWidth: 516
    },
    detailsAccordion: {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: 14,
        color: "#3D3D3D",
        flexDirection: "column",
        gap: 18,
        padding: 0,
        "& h2": {
            fontSize: 14,
            fontWeight: 500,
            color: "#312E40",
            "& span": {
                color: "#005071"
            }
        },
        "& p": {
            margin: 0,
            textTransform: "capitalize"
        },
        "& p.value": {
            color: "#312E40",
            "& a": {
                color: "#363ED3",
                textDecoration: "none",
            },
        },
        "& .MuiIconButton-root": {
            padding: 0,
            borderRadius: 6
        },
        "& .MuiIconButton-label": {
            color: "#3D3D3D",
            fontSize: 12
        }
    },
    detailsItem: {
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
            textTransform: "capitalize",
            color: "#312E40",
            fontSize: 12,
            lineHeight: "14px",
            marginLeft: 4,
        },
    },
    opportunityItem: {
        maxWidth: 277,
    },
    opportunityContainer: {
        gap: 7
    },
    genderItem: {
        maxWidth: 91,
        textAlign: "center"
    },
    stateItem: {
        maxWidth: 164,
        textAlign: "center"
    },
    genderContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    genderIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 42,
        height: 42,
        borderRadius: "50%",
        background: "#D9D9D9"
    },
    statusContainer: {
        "& .MuiSelect-select.MuiSelect-select": {
            padding: "0 24px 0 0",
            fontSize: 12,
            fontWeight: 500,
            textTransform: "capitalize",
        },
        "& .MuiInput-root": {
            marginTop: 3,
        }
    },
    premiumContainer: {
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        height: "100%"
    },
    chartContainer: {
        display: "flex",
        position: "relative",
        width: "100%",
        top: -2,
        height: 67
    },
    demographicContainer: {
        textAlign: "center",
        paddingTop: 17,
        height: "100%",
        "& p": {
            width: "100%"
        }
    },
    dataContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // height: "100%",
        paddingTop: 17
    },
    bottomContainer: {
        height: "100%"
    },
    dataItem: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "8px 20px 8px 13px",
        border: "1px solid #D9E1EE",
        "& span": {
            display: "flex",
            color: "#000000",
            "& svg": {
                marginRight: 5
            },
        },
        "& .MuiTypography-root": {
            color: "#6A6C71",
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "16px"
        },

    },
    flightRiskItem: {
        "& .story--title": {
            textTransform: "capitalize",
            lineHeight: "22px!important"
        }
    },
    flightRiskList: {
        paddingTop: 10,
        fontFamily: "Roboto",
        fontSize: 14,
        lineHeight: "16px",
        margin: 0,
        paddingLeft: 14,
        paddingTop: 15,
        "& li": {
            textTransform: "capitalize"
        }
    },
    sensitivityDesription: {
        "&.MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 14,
            lineHeight: "16px",
            margin: 0,
            paddingTop: 10,
            textTransform: "capitalize"
        }
    },
    opportunityTitle: {
        "&.MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 18,
            fontWeight: 400,
            lineHeight: "22px",
            textTransform: "capitalize"
        },
        paddingBottom: 25

    },
    lapseItem: {
        height: 69,
        borderRadius: 6,
        border: "1px solid #D9E1EE",
    },
    lapseContent: {
        paddingLeft: 12,
        paddingTop: 4
    },
    suggestedTitle: {
        paddingLeft: 12
    },
    suggestedItem: {
        height: 69,
        borderRadius: 6,
        border: "1px solid #D9E1EE",
    },
    storyItem: {
        gap: 8
    },
    suggestedBarContainer: {
        width: 194,
        height: 8,
        marginLeft: 4,
        background: "#EFEFEF",
        borderRadius: "0px 6px 6px 0px"
    },
    suggestedBarItem: {
        height: 8,
        borderRadius: "0px 6px 6px 0px",
        position: "absolute",
        display: "flex",
        justifyContent: "flex-end",
    },
    suggestedBarLabel: {
        textAlign: "center",
        position: "absolute",
        "& p": {
            position: "relative",
            margin: 0,
            fontFamily: "Roboto",
            fontSize: 10,
            lineHeight: "16px",
            fontWeight: 400,
        },
        "& span": {
            fontWeight: 700,
            lineHeight: "16px",
        },
    },
    suggestedBarLabelTop: {
        top: -16,
        textAlign: "left"
    },
    suggestedBarLabelBottom: {
        bottom: -16,
        textAlign: "right",
        textTransform: "capitalize"
    },
    scoringTitle: {
        "&.MuiTypography-root": {
            color: "#312E40",
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: 500
        },
        "& span": {
            color: "#000676",
            background: "#E8EFFD",
            borderRadius: 6
        },
    },
    brokerIconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        background: "#C0E1F6",
        width: 27,
        height: 27,
        "& svg": {
            width: 14,
            height: 14,
        },
        "& path": {
            fill: "#0953A8"
        }
    },
    plusIcon: {
        "& svg": {
            width: 7,
            height: 7,
            marginRight: 5
        },
        "& rect": {
            fill: "#005071"
        }
    },
    accordionIcon: {
        "& path": {
            fill: "#3D3D3D",
        }
    },
    brokerListItem: {
        gap: 8,
        "& p": {
            margin: 0,
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: 400,
            textTransform: "none!important",
        },
        "& .description": {
            color: "#312E40",
            background: "#F3F4F7",
            padding: "6px 12px",
            borderRadius: 6,
            textTransform: "none!important",
            "& span": {
                fontSize: 12,
                fontWeight: 500
            }
        },
        "& .time": {
            color: "#595959",
            paddingLeft: 12,
            paddingTop: 6,
            fontSize: 10
        }
    },
    titleRetention: {
        "&.MuiTypography-root": {
            color: "#0BCB47"
        }
    },
    titleNewbuisness: {
        "&.MuiTypography-root": {
            color: "#005071"
        }
    },
    title: {
        "&.MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "16px",
            color: "#312E40"
        },
    },
    titleSecondary: {
        "&.MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: 600,
            lineHeight: "26px",
            color: "#312E40"
        },
    },
    text: {
        "&.MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 400,
            color: "#312E40"
        },
        "& span": {
            fontWeight: 700
        },

    },
    titleSemiBold: {
        "&.MuiTypography-root": {
            fontWeight: 500,
            paddingRight: 5,
            textTransform: "capitalize"
        },
    },
    titlePolicyholder: {
        "&.MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 18,
            fontWeight: 400,
            lineHeight: "18px",
            color: "#000"
        },

    },
    titleContainer: {
        gap: 5,
        paddingBottom: 14
    },
    titleItem: {
        display: "flex"
    },
    closeIconContainer: {
        position: "absolute",
        left: 0,
        top: 0,
        height: 42,
        paddingRight: 13,
        paddingLeft: 48,
        background: "linear-gradient(270.34deg, rgba(57, 179, 68, 0.3) 0.57%, rgba(56, 121, 140, 0.5) 36.01%, #363ED3 99.99%)",
        "& .MuiTypography-root": {
            fontSize: 20,
            fontWeight: 500,
            color: "#FFFFFF"
        },
    },
    closeIconContent: {
        height: 14
    },
    closeIcon: {
        width: 14,
        height: 14,
        cursor: "pointer"
    },
    positive: {
        color: "#0BCB47"
    },
    negative: {
        color: "#FF4B62"
    }

}))

export const PremiumSliderStyles = {
    '&.MuiSlider-root': {
        height: 3
    },
    '&.MuiSlider-root.Mui-disabled': {
        color: "#4FCCDE"
    },
    '& .MuiSlider-rail': {
        height: 3
    },
    '& .MuiSlider-thumb': {
        height: 11,
        width: 11,
        marginTop: -4
    },
    '& .MuiSlider-mark': {
        display: "none"
    },
    '&.MuiSlider-marked': {
        margin: 0
    },
    '& .MuiSlider-markLabel': {
        display: "none"
    },
    '& .MuiSlider-markLabelActive': {
        display: "block"
    },
}

export const PremiumSliderMore = styled(Slider)({
    ...PremiumSliderStyles,
    '& .MuiSlider-thumb[data-index="1"]': {
        height: 16,
        width: 16,
        marginTop: -8,
        background: `center / contain no-repeat url('${BgMale}') #9B9FE9`,
        border: "2px solid #9B9FE9"
    },
    '& .MuiSlider-markLabel[data-index="2"]': {
        top: -21
    },
});  

export const PremiumSliderLess = styled(Slider)({
    ...PremiumSliderStyles,
    '& .MuiSlider-thumb[data-index="0"]': {
        height: 16,
        width: 16,
        marginTop: -8,
        background: `center / contain no-repeat url('${BgMale}') #9B9FE9`,
        border: "2px solid #9B9FE9"
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
        top: -21
    },
});

export const PremiumSliderFemaleMore = styled(Slider)({
    ...PremiumSliderStyles,
    '& .MuiSlider-thumb[data-index="1"]': {
        height: 16,
        width: 16,
        marginTop: -8,
        background: `center / contain no-repeat url('${BgFemale}') #9B9FE9`,
        border: "2px solid #9B9FE9"
    },
    '& .MuiSlider-markLabel[data-index="2"]': {
        top: -21
    },
});  

export const PremiumSliderFemaleLess = styled(Slider)({
    ...PremiumSliderStyles,
    '& .MuiSlider-thumb[data-index="0"]': {
        height: 16,
        width: 16,
        marginTop: -8,
        background: `center / contain no-repeat url('${BgFemale}') #9B9FE9`,
        border: "2px solid #9B9FE9"
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
        top: -21
    },
}); 


export default useStyles;