import React, { useEffect, useState } from "react";
import PremiumBreakdown from "../../../Components/Run/PremiumBreakdown";
import { useDispatch, useSelector } from "react-redux";
import { selectCharts, setUILimits, setUILimitsMarks } from "../../../Components/Run/Charts/ChartsSlice";
import { selectRun, setFaceAmountRanges, setLoaded, setModel, setPoliciesActual,  setRunMetaDataBroker, setTotal, setZeroValuesByGender } from "../../../Components/Run/RunSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { selectFilters } from "../../../Components/Filters/FiltersSlice";
import RunApiService from "../../../Components/Run/RunService";
import { deepEqual, getCountryCurrencySymbol, nFormatter, parseJwt, validateValue, scrollTo, buildQueryString } from "../../../global/helpers";
import { checkData } from "../../../Components/Run/Functions";
import { Grid, makeStyles } from "@material-ui/core";
import HeaderRun from "../../../Components/Headers/HeaderRun";
import { selectIds, selectLabels, setProjectIds, setProjectLabels, setProspectsHeaders, setRunsMeta, setStoriesIsLoading } from "../BookAnalysisSlice";
import appApiService from "../../../AppService";
import { selectUserCompany, setColumns } from "../../../AppSlice";
import Loader from "../../../Components/Loader/Loader";
import { defaultColumnsBroker, defaultColumnsLife } from "../../../global/constants";
import BookAnalysisService from "../BookAnalysisService";
import NumberFormat from "react-number-format";
import { ReactComponent as Arrow } from '../../../Assets/icon-arrow-right.svg';

const useStyles = makeStyles(() => ({
  main: {
      justifyContent: "space-between",
      background: "#FFFFFF",
      "& .MuiGrid-grid-xs-5": {
        maxWidth: "49%",
        flexBasis: "49%",
      },
      "& .MuiGrid-grid-xs-4": {
        maxWidth: "32%",
        flexBasis: "32%",
      }
  },
  backdrop: {
      position: "fixed",
      height: "100vh",
      background: "#00000095",
      top: 0,
      left: 80,
      zIndex: 1000
  },
  totalsContainer: {
    paddingBottom: 17,
    paddingTop: 5,
      "& .contacts-text": {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "22px",
        "& span": {
          color: "#005071"
        }
      },
      "& p": {
        margin: 0
      },
      "& .premium-text": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "22px",
        color: "#7E7C87"
      },
      "& svg": {
        width: 12,
        height: 8,
        margin: "0 12px",
        "& path": {
          fill: "#7E7C87"
        }
      },
  },
}));

const ApiService = new RunApiService()
const AppService = new appApiService()
const BookService = new BookAnalysisService()

const Showcase = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currency = getCountryCurrencySymbol()
    const classes = useStyles()

    const charts = useSelector(selectCharts)
    const filters = useSelector(selectFilters)
    const projectIds = useSelector(selectIds)
    const projects = useSelector(selectLabels)
    const user = useSelector(selectUserCompany)
    const run = useSelector(selectRun)

    const [ clear, setClear ] = useState({
      existing: false,
      recomended: false,
      status: false
    })
    const [isDataLoaded, setIsDataLoaded] = useState(null)
    const [open, setOpen] = useState(true);  
    const [mapData, setMapData] = useState([])
    const [mapDataByCounties, setmapDataByCounties] = useState({})
    const [uiByStateData, setUIbyStateData] = useState([])
    const [uiByGenderData, setUIbyGenderData] = useState([])
    const [uiDistributionData, setUIDistributionData] = useState([])
    const [uiByIncomeData, setUIbyIncomeData] = useState([])
    const [uiByAgeData, setUIbyAgeData] = useState([])
    const [uiByTopAgentData, setUIbyTopAgentData] = useState([])
    const [uiByBottomAgentData, setUIbyBottomAgentData] = useState([])
    const [uiByClusterData, setUIbyClusterData] = useState([])
    const [uiByPartnerData, setUIbyPartnerData] = useState([])
    const [emptyValues, setEmptyValue] = useState({
      age: false,
      gender: false,
      state: false,
      income: false,
      duration: false,
      agent: false
    })

    const scrollToTop = () => {
      scrollTo(0)
    };
    
    const BuildData = (chartsData) => {
        let mapTemp = []
        let mapByCountiesTemp = []
        let UIbyStateTemp = []
        let UIbyGenderTemp = []
        let UIbyIncomeTemp = []
        let UIbyAgeTemp = []
        let UIbyAgentTemp = []
        let UIbyClusterTemp = []
        let UIbyPartnerTemp = []
        let UIDistributionTemp = []
        
        const contryCode = parseJwt(localStorage.getItem("token"))["country_code"]

        let totalAge = 0
        let totalGender = 0
        let totalIncome = 0
        let totalState = 0
        let totalDuration = 0
        let totalAgent = 0
        let totalPartner = 0
        let totalCluster = 0
        chartsData.age && Object.values(chartsData.age).forEach((item) => totalAge = totalAge + item)
        chartsData.gender && Object.values(chartsData.gender).forEach((item) => totalGender = totalGender + item)
        chartsData.income && Object.values(chartsData.income).forEach((item) => totalIncome = totalIncome + item)
        chartsData.duration && Object.values(chartsData.duration).forEach((item) => totalDuration = totalDuration + item)
        chartsData.agent && Object.values(chartsData.agent).forEach((item) => totalAgent = totalAgent + item)
        chartsData.partner && Object.values(chartsData.partner).forEach((item) => totalPartner = totalPartner + item)
        chartsData.cluster && Object.values(chartsData.cluster).forEach((item) => totalCluster = totalCluster + item)
        chartsData.location.states && Object.values(chartsData.location.states).forEach((item) => totalState = totalState + item)
        setEmptyValue({
        age: chartsData.age !== null || totalAge !== 0,
        gender: chartsData.gender !== null || totalGender !== 0,
        state: chartsData.location.states !== null || totalState !== 0,
        income: chartsData.income !== null || totalIncome !== 0,
        duration: chartsData.duration !== null || totalDuration !== 0,
        agent: chartsData.agent !== null || totalAgent !== 0,
        partner: chartsData.partner !== null || totalPartner !== 0,
        cluster: chartsData.cluster !== null || totalCluster !== 0,
        })
        
        //Populate By Age
        if(chartsData.age?.length > 0) {
        chartsData.age.forEach((ageItem) => {
            const entries = Object.entries(ageItem)
            entries.forEach((item) => {
            if (validateValue(item[1])) {
                const ranges = item[0].split("_")
                let id = ""
                if (ranges.length === 1) {
                id = `${item[0]}<`
                } else if (ranges[0] === '0') {
                id = `<${ranges[1]}`  
                } else {
                id = `${ranges[0]}-${ranges[1]}`
                }
                UIbyAgeTemp.push({ id, value: item[1]})
            }
            })
        })
        UIbyAgeTemp.reverse()
        } else {
        UIbyAgeTemp = []
        setUIbyAgeData(UIbyAgeTemp)
        }
            
        //Populate UI by Gender Temp Array
        if(chartsData.gender?.length > 0) {
        UIbyGenderTemp = []
        chartsData.gender.forEach((gender) => {
            const entries = Object.entries(gender)
            entries.forEach((item) => {
            if (validateValue(item[1])) {
                if (item[0] === "F") {
                UIbyGenderTemp.push({ id: "Female", value: item[1], color: "#ff656f", real: 2 })
                } else if (item[0] ===  "M") {
                UIbyGenderTemp.push({ id: "Male", value: item[1], color: "#4b54ff",real: 1 })
                }
            }
            })
        })
        dispatch(setZeroValuesByGender([...UIbyGenderTemp.filter(({value}) => !value).map(({id}) => id), "Not specified"]))
        } else {
        UIbyGenderTemp = [
            { id: "Female", value: 0, color: "#ff656f", real: 2 },
            { id: "Male", value: 0, color: "#4b54ff",real: 1 },
        ]
        setUIbyGenderData(UIbyGenderTemp)
        dispatch(setZeroValuesByGender(["Male","Female","Not specified"]))
        }

        //Populate By Duration Segment 
        if(chartsData.duration?.length > 0) {
        chartsData.duration.forEach((duration) => {
            const entries = Object.entries(duration)
            entries.forEach((item) => {
            if (parseFloat(item[1]) >= 0) {
                UIDistributionTemp.push({ id: item[0], value: item[1]})
            }
            })
        })
        } else {
        UIDistributionTemp = []
        setUIDistributionData(UIDistributionTemp)
        }

        //Populate By Income Groups
        if(chartsData.income?.length > 0) {
        chartsData.income.forEach((income) => {        
            const entries = Object.entries(income)
            entries.forEach((item) => {
            if (validateValue(item[1])) {
                const ranges = item[0].split("_")
                let id = "<50"
                if (ranges.length === 1 && item[0] !== "100") {
                id = `${item[0]}<`
                UIbyIncomeTemp.push({ id, value: item[1]})
                } else if (ranges[0] === '0') {
                id = `<${ranges[1]}` 
                UIbyIncomeTemp.push({ id, value: item[1]})
                } else if (ranges.length === 2) {
                id = `${ranges[0]}-${ranges[1]}`
                UIbyIncomeTemp.push({ id, value: item[1]})
                }
            }
            })
        })
        UIbyIncomeTemp = [ ...UIbyIncomeTemp, { id:"100<", value: chartsData.income[100]}]
        UIbyIncomeTemp.reverse()
        } else {
        UIbyIncomeTemp = []
        setUIbyIncomeData(UIbyIncomeTemp)
        }

        //Populate By State
        if(chartsData.location.states?.length > 0 && chartsData.location.cities?.length > 0) {
        mapTemp = []
        UIbyStateTemp = []
        chartsData.location.states.forEach((state) => {        
            const entries = Object.entries(state)
            entries.forEach((item) => {
            mapTemp.push({ id: contryCode === "IL" ? "US-"+item[0] : contryCode +"-"+item[0], value: item[1] })
            UIbyStateTemp.push({ id: item[0], value: item[1]})
            })
        })
        chartsData.location.cities.forEach((city) => {        
            const entries = Object.entries(city)
            entries.forEach((item) => {
            mapByCountiesTemp.push({ name: item[0], value: item[1]})
            })
        })
        } else {
        mapTemp = []
        UIbyStateTemp = []
        mapByCountiesTemp = []
        setUIbyStateData(UIbyStateTemp)
        setmapDataByCounties(mapByCountiesTemp)
        setMapData(mapTemp)
        }

        //Populate By Agent
        if(chartsData.agent?.length > 0) {
        chartsData.agent.forEach((agentItem) => {
            const entries = Object.entries(agentItem)
            entries.forEach((item) => {
            if (validateValue(item[1])) {
                UIbyAgentTemp.push({ id: item[0], value: item[1] })
            }
            })
        })
        } else {
        UIbyAgentTemp = []
        }

        //Populate By Partner
        if(chartsData.partner?.length > 0) {
        chartsData.partner.forEach((partnerItem) => {
            const entries = Object.entries(partnerItem)
            entries.forEach((item) => {
            if (validateValue(item[1])) {
                UIbyPartnerTemp.push({ id: item[0], value: item[1] })
            }
            })
        })
        } else {
        UIbyPartnerTemp = []
        }
        
        //Populate By Cluster
        if(chartsData.cluster?.length > 0) {
        chartsData.cluster.forEach((clusterItem) => {
            const entries = Object.entries(clusterItem)
            entries.forEach((item) => {
            if (validateValue(item[1])) {
                UIbyClusterTemp.push({ id: item[0], value: item[1] })
            }
            })
        })
        } else {
        UIbyClusterTemp = []
        }

        const resMap = Array.from(mapTemp.reduce((acc, { value, ...r }) => {
        const key = JSON.stringify(r);

        const current = acc.get(key) || { ...r, value: 0 };
        return acc.set(key, { ...current, value: current.value + parseFloat(value) });
        }, new Map()).values());

        const resUiByState = Array.from(UIbyStateTemp.reduce((acc, { value, ...r }) => {
        const key = JSON.stringify(r);
        const current = acc.get(key) || { ...r, value: 0 };
        return acc.set(key, { ...current, value: current.value + parseFloat(value) });
        }, new Map()).values());

        const resUIbyGender = Array.from(UIbyGenderTemp.reduce((acc, { value, ...r }) => {
        const key = JSON.stringify(r.id);
        const current = acc.get(key) || { ...r, value: 0 };
        return acc.set(key, { ...current, value: current.value + parseFloat(value) });
        }, new Map()).values());

        const resUIbyIncome = Array.from(UIbyIncomeTemp.sort((a, b) => a.real > b.real ? 1 : -1).reduce((acc, { value, ...r }) => {
        delete r.real
        const key = JSON.stringify(r.id);
        const current = acc.get(key) || { ...r, value: 0 };
        return acc.set(key, { ...current, value: current.value + parseFloat(value) });
        }, new Map()).values());

        const resUIbyAge = Array.from(UIbyAgeTemp.sort((a, b) => a.real > b.real ? 1 : -1).reduce((acc, { value, ...r }) => {
        delete r.real
        const key = JSON.stringify(r);
        const current = acc.get(key) || { ...r, value: 0 };
        return acc.set(key, { ...current, value: current.value + parseFloat(value) });
        }, new Map()).values());

        const resUIbyAgent = Array.from(UIbyAgentTemp.reduce((acc, { value, ...r }) => {
        delete r.real
        const key = JSON.stringify(r);
        const current = acc.get(key) || { ...r, value: 0 };
        return acc.set(key, { ...current, value: current.value + parseFloat(value) });
        }, new Map()).values());

        const resUIbyPartner = Array.from(UIbyPartnerTemp.reduce((acc, { value, ...r }) => {
        delete r.real
        const key = JSON.stringify(r);
        const current = acc.get(key) || { ...r, value: 0 };
        return acc.set(key, { ...current, value: current.value + parseFloat(value) });
        }, new Map()).values());

        const resUIbyCluster = Array.from(UIbyClusterTemp.reduce((acc, { value, ...r }) => {
        delete r.real
        const key = JSON.stringify(r);
        const current = acc.get(key) || { ...r, value: 0 };
        return acc.set(key, { ...current, value: current.value + parseFloat(value) });
        }, new Map()).values());

        const resUIDistribution = Array.from(UIDistributionTemp.sort((a, b) => a.real > b.real ? 1 : -1).reduce((acc, { value, ...r }) => {
        delete r.real
        const key = JSON.stringify(r);
        const current = acc.get(key) || { ...r, value: 0 };
        return acc.set(key, { ...current, value: current.value + parseFloat(value) });
        }, new Map()).values());

        if(checkData(chartsData)){
        setmapDataByCounties(mapByCountiesTemp)
        setMapData(resMap)
        setUIbyStateData(resUiByState)
        setUIbyGenderData(resUIbyGender)
        setUIbyIncomeData(resUIbyIncome)
        setUIbyAgeData(resUIbyAge)
        setUIbyClusterData(resUIbyCluster)
        setUIbyPartnerData(resUIbyPartner)
        setUIbyTopAgentData(
            resUIbyAgent
            .sort((a, b) => a.value > b.value ? 1 : -1)
            .slice(0, resUIbyAgent.length > 4 ? 5 : resUIbyAgent.length - 1)
        )
        setUIbyBottomAgentData(
            resUIbyAgent
            .sort((a, b) => b.value > a.value ? 1 : -1)
            .slice(0, resUIbyAgent.length > 4 ? 5 : resUIbyAgent.length - 1)
            .reverse()
        )
        setUIDistributionData(resUIDistribution)
        //handleRequestSort(1)
        }
        setOpen(false)
        

    }

    useEffect(() => {              
      if (!projectIds?.length) {
          dispatch(setStoriesIsLoading(true))

          AppService._getRuns(
              localStorage.getItem("companyID"),
              (res) => {
                  if (res.data?.count !== 0 || user === "platform-anonym") {
                      const meta = []
                      const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                          meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "", runs: runs.filter(({ status }) => status?.toLowerCase() !== "failed")})
                          return project_id
                      })
                      dispatch(setProjectIds(ids))
                      dispatch(setProjectLabels(meta))
                      dispatch(setRunsMeta(res.data))
                      
                      const data = res.data?.projects[0]?.runs[0]
                      const columnsConfig = data["columns_to_display"]?.columns
                      const tmp = {}
                      if (columnsConfig?.length > 0) {
                        Object.assign(tmp, ...Object.keys(defaultColumnsBroker)
                            .map((key) => ({[key]: columnsConfig.includes(key)})))
                        dispatch(setColumns(tmp))
                      } else {
                          Object.assign(tmp, ...Object.keys(defaultColumnsBroker)
                            .map((key) => ({[key]: defaultColumnsBroker[key]})))    
                          dispatch(setColumns(tmp))
                      }
                  } else { 
                      // Load demo data
                      AppService._getDemoRunsData((res) => {
                          history.push("/book?demo")                        
                          const meta = []
                          const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                              meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "", runs: runs.filter(({ status }) => status?.toLowerCase() !== "failed")})
                              return project_id
                          })
                          if (!projectIds?.length) {
                              dispatch(setProjectIds(ids))
                              dispatch(setProjectLabels(meta))
                              dispatch(setRunsMeta(res.data))
                          }
                          dispatch(setColumns(defaultColumnsLife))
                      },
                      (error) => { console.log(error) })

                  }

              }, 
          (error) => { dispatch(setStoriesIsLoading(false)); console.log(error);}
          )

      }
    }, [dispatch, projectIds])

    useEffect(() => {
            BookService._getPolicies(history.location.search?.includes("demo") || user === "platform-anonym", (response) => {
              dispatch(setProspectsHeaders(response.data))
            }, (error) => console.log(error))
    }, [dispatch, projectIds])

    useEffect(() => {
          setIsDataLoaded(false)
          scrollToTop()
          setOpen(true)
          let queryParamas = {
            "filters": filters
          }
          const payload = {}
          Object.assign(payload, ...Object.entries(queryParamas.filters).filter((item) => item[1]?.length).map((item) => ({ [item[0]]: item[0] === "precision" ? parseInt(item[1]) : item[1]}) ))
          const runs = projects?.length ? projects[0]?.runs : null
          if(runs?.length) {
            ApiService._getFiltersValues((response) => {
              dispatch(setRunMetaDataBroker(response.data))    

              // set dynamic filter values
              if (deepEqual(charts.uiFilter, [0, 0])) {
                dispatch(setUILimits(response.data["max_mean_premium_term"]))
                dispatch(setUILimitsMarks({ value: response.data["max_mean_premium_term"], label: nFormatter(response.data["max_mean_premium_term"], 1, currency)}))
              }
        
              if(response.data["face_amount_median"] && Object.keys(charts.faceAmountFilter).length === 1) {
                dispatch(setFaceAmountRanges(response.data['face_amount_median']))
              }
            }, () => {})

            ApiService._getPolicesAggregation((response) => {
              const data = response.data
              dispatch(setPoliciesActual(data["potential_premium"]))
              dispatch(setTotal(data["count"]))
              dispatch(setModel(runs[0].model_type))

              const ChartsData = {
                age: data?.by_age,
                gender: data?.by_gender,
                location: { states: data?.by_state, cities: data?.by_city },
                duration: data?.by_duration, 
                income: data?.by_income,
                agent: null,
                partner: null,
                cluster: null
              }
              BuildData(ChartsData)
              
              setIsDataLoaded(true)
              dispatch(setLoaded(true))
              setClear({
                existing: false,
                recomended: false,
                status: false
              })
            }, (error) => {
              console.log(error);
              setIsDataLoaded(true)
            }, buildQueryString(filters))
          }
    }, [dispatch, history, filters, charts.optimalTreshPrecision, projects])
  
    return (
      <Grid container style={{maxWidth: window.innerWidth-120}}>
        {!isDataLoaded && <Loader/>}

        <HeaderRun clear={clear} setClear={setClear} head={false} showTotal={false} broker/>
        
        <Grid container alignItems="center" className={classes.totalsContainer}>
          <p className="contacts-text">
            <NumberFormat value={run.count} displayType="text" thousandSeparator/> Contacts
          </p> 
          <Arrow/>
          <p className="premium-text">
            {nFormatter(run.policiesActual, 2, currency)} Premium
          </p>
        </Grid>

        <Grid container className={classes.main}>
          <PremiumBreakdown
              data={{
                  mapData,
                  mapDataByCounties,
                  uiByStateData,
                  uiByGenderData,
                  uiDistributionData,
                  uiByAgeData,
                  uiByIncomeData,
                  uiByTopAgentData,
                  uiByBottomAgentData,
                  uiByPartnerData,
                  uiByClusterData
              }}
              emptyValues={emptyValues}
              type=''
              isBroker
          />
        </Grid>
      </Grid>
    )
}

export default Showcase