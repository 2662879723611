import React from "react";
import useStyles from "./styles";
import { Grid, TableContainer, TableHead, TableBody, TableRow, TableCell, Table, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import { getCountryCurrencySymbol } from "../../../global/helpers";
import NumberFormat from "react-number-format";

const TermAgeTable = ({ data }) => {
    const classes = useStyles()
    const currency = getCountryCurrencySymbol()
    
    return (
        <Grid className={classes.termAgeTableContainer}>
            <p className={classes.termAgeTableTitle}>Duration Bank (Age of policy):</p>
            
            {data ?
            <TableContainer>
                
            <Table>
                <TableHead>
                    <TableRow className={classes.termAgeHeader}>
                        <TableCell className={classes.termAgeHeaderCell}>Age of Policy</TableCell>
                        <TableCell className={classes.termAgeHeaderCell}># of<br/>policies</TableCell>
                        <TableCell className={classes.termAgeHeaderCell}>Face Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(data?.policies)
                    ?.sort((a, b) => data.policies[a].face_amount > data.policies[b].face_amount ? -1 : 1)
                    ?.map((key) => {
                        const { count, face_amount } = data.policies[key]               
                        return (
                            <TableRow className={classes.termAgeHeader}>
                                <TableCell className={clsx(classes.termAgeCell, classes.termAgeCellDark)}>{key}</TableCell>
                                <TableCell className={classes.termAgeCell}>
                                    <NumberFormat value={count} displayType="text" thousandSeparator/>
                                </TableCell>
                                <TableCell className={classes.termAgeCell}>
                                    <NumberFormat value={face_amount} prefix={currency} displayType="text" thousandSeparator/>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    <TableRow className={classes.termAgeHeader}>
                        <TableCell 
                            className={
                                clsx(
                                    classes.termAgeCell,
                                    classes.termAgeCellDark,
                                    classes.termAgeCellBold,
                                    classes.termAgeCellEnd
                                )
                            }
                        >
                            Total:
                        </TableCell>
                        <TableCell className={clsx(classes.termAgeCell, classes.termAgeCellBold)}>
                            <NumberFormat value={data.total_count} displayType="text" thousandSeparator/>
                        </TableCell>
                        <TableCell className={clsx(classes.termAgeCell, classes.termAgeCellBold)}>
                            <NumberFormat value={data.total_face_amount} prefix={currency} displayType="text" thousandSeparator/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </TableContainer>
            :
            <Grid container justifyContent="center" alignContent="center" style={{paddingTop: 32}}>
                <CircularProgress style={{color: "#005071",width:96,height:96}}/>
            </Grid>
            }
        </Grid>
    )
}

export default TermAgeTable