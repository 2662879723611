import { Grid, makeStyles, Typography, Paper } from "@material-ui/core";
import React from "react";
import { useMobileDetect } from "../../global/useMobileDetect";
import backgroundImage from "../../Assets/register-background.png"
import { ReactComponent as ExlLogo } from "../../Assets/icon-exl-logo.svg";
import { ReactComponent as Logo } from "../../Assets/icon-logo.svg";

const useStyles = makeStyles(() => ({
    root: {
      minHeight: '100vh',
      justifyContent : 'center',
      backgroundColor:'#F9FAFB'
    },
    loginBackground :{
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent : 'center',
        minHeight: '100vh'
    },
    banner: {
        width: '33%',
        padding: "50px 7%",
        backgroundColor: "#FB4E0B",
        backgroundSize: "cover",
        color: '#fff',
        textAlign: "center",
        '& .MuiTypography-root': {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "25px"
        },
        '& h1': {
            fontSize: 40,
            fontWeight: 700,
            lineHeight: "18px",
            margin: 0
        }
    },
    loginContainer: {
        width: '67%',
        color: '#312E40',
        background: '#F9FAFB',
        alignItems: "center"
    },
    mobileContainer: {
      height: "100vh",
      fontFamily: "Roboto",
      fontSize: 24,
      fontWeight: 600,
      backgroundColor: "#f8f9fb"
    },
    powerdByAtidot: {
        display: "flex",
        alignItems: "flex-end",
        position: "absolute",
        bottom: 25,
        right: 25,
        gap: 8,
        '& svg': {
            position: "relative",
            bottom: 6,
        }
    }

}));

const LoginContainer = (props) => {
    const classes = useStyles();
    const isMobile = useMobileDetect()

    return(
        isMobile ? 
            <Grid container justifyContent='center' alignItems='center' className={classes.mobileContainer}>
                Mobile is Not Supported
            </Grid>
        :
            <Paper variant="elevation" elevation={2} className={classes.loginBackground} >  
                <Grid container direction="column" justifyContent="center" className={classes.banner} style={{ backgroundImage: `url('${backgroundImage}')` }}>

                    <Grid item>
                        <ExlLogo/>
                        <Typography>Make sense of data,<br/>drive business forward</Typography>
                        <Grid item className={classes.powerdByAtidot}>
                            <Typography>Powered by Atidot</Typography><Logo/>
                        </Grid>
                    </Grid>
                </Grid>    
                <Grid container  justifyContent="center" className={classes.loginContainer}> 
                    {props.children}
                </Grid>   
            </Paper>
    )

}

export default LoginContainer;