import { parseJwt } from "./helpers"

const currentUser = () => localStorage.getItem("token") ? parseJwt(localStorage.getItem("token")) : {"email": "anonym@atidot.com"} 
const hubspot = () => localStorage.getItem("can_see_hubspot") === "true"

export const User = () => {
    const user = currentUser()
    return {
        currentUser: user,

        isAdmin: user.roles === "platform:admin",
        isEditor: user.roles === "platform:editor",
        isAnonym: ["anonym@atidot.com", "anonym.broker@atidot.com"].includes(user.email),

        isSnowFlake: ['chaganton@gmail.com', 'sonya@atidot.com'].includes(user.email),
        isHideOnProd: ['localhost', 'broker', 'dev', 'stage'].some(cond => window.location.host.includes(cond)),
        isBroker: false,
        isDev: ['chaganton@gmail.com'].includes(user.email)
    }

}

export const checkPermission = (action) => currentUser().permissions?.filter(p => p.action === action).length > 0
export const checkHubSpotPermission = () => hubspot()