import { makeStyles } from "@material-ui/core";
import Charts from "../../Assets/fadedCharts.png";

export const useStyles = makeStyles(() => ({
    dashboardContainer: {
        gap: 12
    },
    metaContainer: {
        padding: 16,
        background: "#FFFFFF",
        boxShadow: "0px 0px 6px 0px rgba(106, 108, 113, 0.10)",
        borderRadius: 6,
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 22,
            fontWeight: 400,
            color: "#000000",
        },
    },
    metaTitle: {
        paddingBottom: 37
    },
    metaItemContainer: {
        paddingLeft: 12,
        "&.meta--item--first": {
            paddingLeft: 0
        },
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: 700
        },
        "& .MuiTypography-root.MuiTypography-h1": {
            fontFamily: "Roboto",
            fontSize: 22,
            fontWeight: 700,
            color: "#232033",
        },
        "& span.MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "22px",
            color: "#232033",
        },
        "& .MuiDivider-root": {
            width: "100%"
        },
    },
    metaItem: {
        background: "#FFFFFF",
        borderRadius: 6,
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
        padding: "19px 16px 5px 20px",
        marginTop: 10,
        minHeight: 194,
        "@media (max-width:1282px)": {
            minHeight: 211
        }
    },
    metaItemTitle: {
        paddingLeft: 20
    },
    metaListItem: {
        padding: "14px 0",
    },
    uiIcon: {
        "& path": {
            color: "#005071",
            fill: "#005071"
        }
    },
    lapseIcon: {
        "& path": {
            color: "#0BCB47",
            fill: "#0BCB47"
        },
        "& mask": {
            color: "#0BCB47",
            fill: "#0BCB47"
        },

    },
    primaryIcon: {
        "& path": {
            color: "#36C5D9",
            fill: "#36C5D9"
        }
    },
    secondaryIcon: {
        "& path": {
            color: "#37C173",
            fill: "#37C173"
        }
    },
    tretiaryIcon: {
        "& path": {
            color: "#36ACA0",
            fill: "#36ACA0"
        },
        "& mask": {
            color: "#36ACA0",
            fill: "#36ACA0"
        },

    },
    operationsContainer: {
        border: "1px solid #EBEBEB25",
        borderRadius: 6,
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
        padding: "30px 16px 16px 16px",
        marginTop: 31
    },
    operationsHeaderItem: {
        background: "#FFFFFF",
        borderRadius: 6,
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
        padding: 16,
        "&:last-child": {
            background: "#005071",
            color: "#FFFFFF"
        }
    },
    operationsPeriodContaner: {
        opacity: .25,
        "& ul": {
            display: "flex",
            listStyleType: "none",
            margin: 0,
            paddingTop: 27,
            paddingBottom: 17
        },
        "& li": {
            paddingLeft: 24
        },
    },
    chartsContaner: {
        background: `url('${Charts}'), no-repeat`,
        backgroundSize: 'cover',
        minHeight: 331,
        "@media (max-width:1397px)": {
            backgroundSize: 'contain'
        }
    },
    dialogNewCampaign: {
        textAlign: "center",
        padding: '2rem',
        height: '9rem',
        fontFamily: 'Roboto',
        fontSize: '1.5rem',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '1.75rem'
    },

    confirmButton: {
        color: "#FFF",
        background: "#005071",
        borderRadius: 8,
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center",
        textTransform: "none",
        padding: "14px 54px",
        margin: "0px 30px",
        height: 42,
        "&:hover": {
            background: "#005071",
            opacity: 0.85
        }
    },
    values: {
        textAlign: "center",
    },
    recomendedContainer: {
        maxWidth: 888,
        padding: "40px 48px",
        background: "#FFFFFF",
        border: "1px solid #D9E1EE",
        borderRadius: 6,
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 24,
            fontWeight: 500,
            color: "#312E40"
        },
    },
    recomendedHeader: {
        paddingBottom: 12,
        borderBottom: "1px solid #D9E1EE",
        color: "#312E40",
        "& .MuiDivider-root": {
            color: "#D9E1EE",
            margin: "0 12px",
        },
        "& .MuiIconButton-root": {
            padding: 0,
            color: "#005071",
            "&:hover": {
                backgroundColor: "unset"
            },
        },
    },
    recomendedHeaderTitle: {
        "&.MuiIconButton-root": {
            padding: 0,
            fontFamily: "Roboto",
            fontSize: 20,
            fontWeight: 500
        },
    },
    recomendedHeaderDescription: {
        margin: 0,
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400
    },
    actionsList: {
        gap: 20,
        paddingTop: 12,
        minHeight: 399,
    },
    actionsCheckbox: {
        "& .MuiIconButton-root": {
            padding: "0 12px",
            borderRadius: "unset",
        },
        "& .MuiIconButton-label svg": {
            width: 32,
            height: 32,
            background: "#FFFFFF"
        },
        "& .MuiIconButton-label path": {
            fill: "#005071"
        },
        "& .MuiCheckbox-colorSecondary.Mui-checked": {
            color: "#005071",
        },
    },
    actionsListItem: {
        maxWidth: 385,
        minHeight: 173,
        backgroundColor: "#FCFCFC",
        border: "1px solid #D9E1EE",
        borderRadius: 6,
        "&:hover": {
            border: "1px solid #005071",
        },
        "& p": {
            margin: 0
        },
        "& .MuiCheckbox-root": {
            borderRadius: 6,
            padding: "0 16px"
        },
    },
    actionsListItemContent: {
        cursor: "pointer",
        fontFamily: "Roboto",
        margin: "16px 0",
        paddingLeft: 11,
        borderLeft: "4px solid #005071",
        display: "flex",
        "& .policy--container": {
            display: "flex",
            flexDirection: "column",
            "& .policy--title": {
                flexGrow: 1
            },
        },
        "& .policy--icon": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "flex-start"
        },
    },
    actionsListItemIcon: {
        marginRight: 13
    },
    actionsListItemLabel: {
        fontSize: 14,
        fontWeight: 400,
        color: "#4D586A"
    },
    actionsListItemTitle: {
        fontSize: 20,
        fontWeight: 500,
        color: "#312E40",
        paddingBottom: 4,
        "& span": {
            color: "#005071",
            fontWeight: 600,
            marginLeft: 4
        },
    },
    actionsListItemDescription: {
        fontSize: 14,
        fontWeight: 400,
        paddingTop: 8,
        maxWidth: 289
    },
    actionsListItemText: {
        fontSize: 14,
        fontWeight: 400,
        "& span": {
            fontWeight: 600,
            color: "#3D3D3D",
        },
    },
    actionsButtonContainer: {
        position: "absolute",
        "& .MuiIconButton-root": {
            borderRadius: "50%"
        },
    },
    actionsButton: {
        position: "relative",
        top: 6,
        left: 344,
        width: 32,
        height: 32,
        "& .MuiIconButton-root": {
            borderRadius: "50%"
        },
    },
    actionsButtonItem: {
        fontSize: 16,
        fontWeight: 500,
        color: "#005071",
        fontFamily: "Roboto",
        alignItems: "center",
        borderRadius: "unset",
        "& .MuiIconButton-label path": {
            fill: "#005071"
        },
    },
    filtersContainer: {
        gap: 20,
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "14px"
        }
    },
    filtersItems: {
        gap: 12,
        display: "none",
        textTransform: "capitalize",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            width: 14,
            height: 14,
        },
        "& .MuiButtonBase-root": {
            padding: 0
        }
    },
    statusTabsContainer: {
        "& .MuiToggleButtonGroup-root": {
            gap: 28
        },
        "& .MuiButtonBase-root": {
            padding: "8px 0",
            marginLeft: "0!important",
            border: "none",
            borderLeft: "none!important",
            borderRadius: 0,
            borderBottom: "2px solid #595959",
            fontFamily: "'Inter'",
            fontSize: 12,
            fontWeight: 500,
            textTransform: "capitalize",
            color: "#595959",
            "& .task--count": {
                padding: "0 4px",
                marginLeft: 4,
                borderRadius: 6,
                background: "#F3F4F7",
                color: "#312E40",
            },
            "&.Mui-selected": {
                borderColor: "#363ED3",
                color: "#363ED3",
                background: "none",
                "& .task--count": {
                    background: "#E9F1FF",
                    color: "#363ED3",
                },
                "&:hover": {
                    background: "none"
                    
                    
                },
            },
        }
    },
    rankText: {
        position: "relative",
        top: 6,
        left: -12,
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: 600,
    },
}))

export default useStyles;