import { CircularProgress, Grid, styled } from "@material-ui/core"

const LoaderContainer = styled(Grid)(() => ({
    position: "fixed",
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#00000005",
    top: 0,
    left: 80,
    zIndex: 1000
  }));

const Loader = ({ size = 42, color = "#005071" }) => (
    <LoaderContainer className="loader">
        <CircularProgress style={{ width: size, height: size, color }}/>
    </LoaderContainer>
)

export default Loader