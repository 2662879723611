import React from "react";
import {
    makeStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    styled,
    Grid
} from "@material-ui/core";
import { ReactComponent as ErrorIcon } from "../../../Assets/icon-cross-error.svg"
import { ReactComponent as Close } from "../../../Assets/icon-circle-cross.svg"
import clsx from "clsx";
import { uiButtonStyles, cancelButtonStyles } from "../../ui/buttons";

export const useStyles = makeStyles(() => ({
    root: {
        "& .MuiPaper-root": {
            maxWidth: "unset",
            width: 655,
            padding: "36px 0",
            border: "1px solid #EBEBEB",
            borderRadius: 6,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#515151",
            textAlign: "center",
            "& .succes-dialog--container": {
                "& .MuiTypography-root": {
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 700,
                    lineHeight: "26px",
                    "& span": {
                        fontWeight: 400
                    }
                }
            }
        },
        "& .MuiDialogTitle-root": {
            padding: 0,
            "& .MuiTypography-root": {
                fontFamily: "Roboto",
                fontSize: 24,
                fontWeight: 700,
                lineHeight: "26px",
            },
        },
        "& .MuiDialogContent-root": {
            fontFamily: "Roboto",
            fontSize: 18,
            fontWeight: 500,
            lineHeight: "26px",
            paddingTop: 16,
            paddingBottom: 24,
            margin: 0
        },
        "& .MuiDialogActions-root": {
            padding: 0
        },
        "& .MuiDialogActions-spacing > :not(:first-child)": {
            marginLeft: 32
        }
    },
    error: {
        "& .MuiPaper-root": {
            borderColor: "#FF4B62",
            borderWidth: 2
        },
        "& .MuiDialogTitle-root": {
            color: "#FF4B62"
        }
    },
    closeIcon: {
        cursor: "pointer",
        marginRight: 30,
        marginBottom: 16
    }

}))

export const CloseButton = styled(Button)({
    color: "#005071",
    background: "#FFFFFF",
    border: "1px solid #005071",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 168,
    height: 41,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    textTransform: "capitalize",
    "&:hover": {
        opacity: .9
    }
})

export const ConfirmButton = styled(Button)({
    ...uiButtonStyles,
    width: "auto",
    minWidth: 168,
    height: 41,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    "&.Mui-disabled": {
        backgroundColor: "#005071",
        color: "#FFFFFF",
        opacity: .5
    }
})

const InformationPopup = ({
    open,
    onClose,
    onConfirm = null,
    confirmText = "Confirm",
    closeText = "Close",
    title = "",
    message = "",
    error = false,
    closeControl = true,
    CloseIcon = Close,
    buttonDirection = "left",
    children = <React.Fragment />,
    className = ""
}) => {
    const classes = useStyles()

    const buttons = [
        closeControl &&
        <CloseButton onClick={onClose} autoFocus className="action-btn--close">
            {closeText}
        </CloseButton>,
        onConfirm &&
        <ConfirmButton onClick={onConfirm} className="action-btn--confirm">
            {confirmText}
        </ConfirmButton>
    ]

    if (buttonDirection !== "right") buttons.reverse()

    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="information-dialog-title"
                aria-describedby="information-dialog-description"
                className={clsx(className, classes.root, error ? classes.error : "")}
            >
                <Grid container justifyContent="flex-end" className="popup-head">
                    {onClose && CloseIcon && <CloseIcon className={classes.closeIcon} onClick={onClose} />}
                </Grid>
                {error && <ErrorIcon />}
                {title && <DialogTitle id="information-dialog-title">
                    {title}
                </DialogTitle>}
                <DialogContent>
                    { children }
                    <DialogContentText id="information-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {buttons}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default InformationPopup