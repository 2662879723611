import React from 'react';
import ReactDOM from 'react-dom';
import './index.sass';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import App from './App';
import Login from './Pages/Login/Login';
import RegistrationPage from './Pages/Registration/RegistrationPage';
import { Provider } from 'react-redux';
import Store from './Store/Store';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme } from '@mui/material';
import Confirm from './Pages/Confirm/Confirm';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import ResetCode from './Pages/ResetCode/ResetCode';
import SendCode from './Pages/SendCode/SendCode';
import Scheduler from './Pages/Scheduler/Scheduler';
import SchedulerSuccess from './Pages/SchedulerSuccess/SchedulerSuccess';

const theme = createTheme({
  typography: {
   "fontFamily": `'Roboto', sans-serif`,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  },
  palette: {
    primary: {
      main: "#1976D2",
      appbar: "#FB4E0B",
      appbar_hover: "#F56E39",
      background: "#F9FAFB",
      border: "#D9E1EE",
      button: "#F56E39",
      white: "#FFFFFF",
      text_primary: "#005071",
      text_secondary: "#FFFFFF"
    },
    secondary: {
      main: "#1976D2",
      appbar: "#FFFFFF",
      appbar_hover: "#EAECF0",
      background: "#F9FAFB",
      border: "#D9E1EE",
      button: "#F56E39",
      white: "#FFFFFF",
      text_primary: "#005071",
      text_secondary: "#344054"
    },
  }
});

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <Login />
          </ThemeProvider>
        </Route>
        <Route exact path="/registration" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <RegistrationPage />
          </ThemeProvider>
        </Route>
        <Route exact path="/confirmaccount" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <Confirm />
          </ThemeProvider>
        </Route>
        <Route exact path="/sendnewcode" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <SendCode />
          </ThemeProvider>
        </Route>
        <Route exact path="/confirmreset" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <ResetCode />
          </ThemeProvider>
        </Route>
        <Route exact path="/forgotpassword" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <ForgotPassword />
          </ThemeProvider>
        </Route>
        <Route exact path="/scheduler/:agent_id/:lead" >
        <ThemeProvider theme={theme}> 
          <CssBaseline />
          <Scheduler />
        </ThemeProvider>
        </Route>
        <Route exact path="/scheduler-preview/:name" >
        <ThemeProvider theme={theme}> 
          <CssBaseline />
          <Scheduler />
        </ThemeProvider>
        </Route>
        <Route exact path="/scheduler-success" >
        <ThemeProvider theme={theme}> 
          <CssBaseline />
          <SchedulerSuccess />
        </ThemeProvider>
        </Route>
        <Route path="/" >
        <ThemeProvider theme={theme}> 
          <CssBaseline />
          <App />
        </ThemeProvider>
        </Route>
      </Switch> 
     
  </BrowserRouter>
</Provider>,
document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
