import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";

import { Grid, makeStyles, Typography } from "@material-ui/core";

import { checkPermission, User } from '../../global/user'

import UserManagement from '../UserManagement/UserManagement';
import Connectors from './Connectors/Connectors'
import HeaderContainer from "../../Components/Headers/HeaderContainer/HeaderContainer";
import MailsConfig from "../MailsConfig";
import MailsTemplate from "../MailsTemplate";
import SchedulerConfig from "../SchedulerConfig/SchedulerConfig";
import { useHistory } from "react-router-dom";
import StatusesConfig from "../StatusesConfig/StatusesConfig";
import ConnectorsBroker from "./ConnectorsBroker/ConnectorsBroker";
import UserViewPopup from "../../Components/Popups/UserViewPopup/UserViewPopup";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff",
        padding: "24px 46px",
        borderRadius: "6px",
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
    },
    tab: {
        background: '#FFFFFF',
        border: '1px solid #EBEBEB',
        borderBottom: 'none',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        width: 218,
        height: 38,
        textAlign: "center",
        paddingTop: 12,
        color: '#6A6C71',
        marginRight: 5,
        cursor: "pointer",
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "19px"
        },
        "@media (max-width:1279px)": {
            width: "auto",
            maxWidth: "fit-content",
            "& .MuiTypography-root": {
                padding: "0 12px",
            },
        }
    },
    tabActive: {
        color: '#005071',
        height: 39,
        position: 'relative',
        bottom: -1
    },
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 6,
        borderTopLeftRadius: 0,
        padding: "39px 52px 48px 52px",
    },
    userView: {
        "& .MuiPaper-root": {
            width: 433
        },
        "& .MuiDialogContent-root": {
            padding: 0,
            paddingBottom: 12
        },
        "& input": {
            width: "308px!important",
            height: 29
        },
    },
}))




const Settings = () => {
    const classes = useStyles()
    const history = useHistory()
    const rootRef = useRef(null)
    const isBroker = localStorage.getItem("accountType") === "broker" || User().isBroker;

    const [activeTab, setActiveTab] = useState(isBroker && !checkPermission('view_settings_page') ? 'Mail Templates' : 'User Management')
    const [tabs, setTabs] = useState(
            User().isSnowFlake ? [
            'User Management',
            'Connectors configuration',
        ] : isBroker ? ['Mail Templates', 'Mailer Configuration', 'Schedule Meeting', 'Policy Statuses', 'Connectors']
        : ['User Management']
    )

    //user view popup state
    const [openUserView, setOpenUserView] = useState(User().currentUser.email === "eyal_dev@atidot.com")

    const handleChangeTab = (item) => {
        setActiveTab(item)
    }

    const getStepComponent = () => {
        switch (activeTab) {
            case 'User Management':
                return <UserManagement />
            case 'Connectors configuration':
                return <Connectors />
            case 'Connectors':
                return <ConnectorsBroker />
            case 'Mail Templates':
                return <MailsTemplate />
            case 'Mailer Configuration':
                return <MailsConfig />
            case 'Schedule Meeting':
                return <SchedulerConfig />
            case 'Policy Statuses':
                return <StatusesConfig />

            default:
                break;
        }
    }

    useEffect(() => {
        if (isBroker && checkPermission('view_settings_page')) {
            setTabs(['User Management', ...tabs])
        }
    }, [isBroker])
 
    useEffect(() => {
        if (isBroker && history.location.search?.includes("code")) {
            setActiveTab('Schedule Meeting')
        }
    }, [history.location.search, isBroker])

    return (
        <Grid container direction="column" ref={rootRef}>
            <HeaderContainer title="Settings"/>
            <Grid container justifyContent="flex-start">
                {
                    tabs.map((item) => (
                        <Grid item key={`settings-tab-${item}`} className={clsx(classes.tab, activeTab === item ? classes.tabActive : '')} onClick={() => handleChangeTab(item)}>
                            <Typography>{item}</Typography>
                        </Grid>
                    ))
                }
            </Grid>
            <Grid className={classes.main} style={{ maxWidth: window.innerWidth - 143 }}>
                {
                    getStepComponent()
                }
            </Grid>
            <UserViewPopup
                className={classes.userView}
                open={openUserView}
                onClose={() => setOpenUserView(false)}
                onConfirm={() => setOpenUserView(false)}
            />    
        </Grid>
    )
}

export default Settings