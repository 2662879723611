import { createSlice } from "@reduxjs/toolkit";
import { getCountryCurrencySymbol, getMoneyQueryFromAction, getPercentageQueryFromAction, getQueryFromPlainAction, getQueryFromPriority, nBuilder } from "../../global/helpers";
import { usStatesData } from "../../global/constants";

const initialState = {
    product: null,
    premium: null,
    annualized_premium: null,
    face_amount: null,
    gender: null,
    age: null,
    age_at_issue: null,
    story_lapse: null,
    mean_premium_term: null,
    face_amount_prediction: null,
    storyType: null,
    state: null,
    res_state: null,
    PRIORITY: null,
    status: null,
    lapse_score: null,
    libr_status: null,
    CSV_LAG: null,
    MARKET_NAME_LAG: null,
    medium_ppr: null,
    YRS_REMAIN_SC: null,
    annualized_premium_sensitivity_NP: null,
    Duration_segment: null,
    duration_segment: null,
    agent_terminated: null,
    date: {
      date_start: null,
      date_end: null,
      query: null
    }
}

const name = 'filters'

export const FiltersSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setProductFilter: (state, action) => {
          state.product = getQueryFromPlainAction(action)
        },
        setProductNameFilter: (state, action) => {
          state.MARKET_NAME_LAG = getQueryFromPlainAction(action)
        },
        setStoryFilter: (state, action) => {
                const queryByStory = []
                const allSelectedStories = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
                if(allSelectedStories.length > 0) {
                    allSelectedStories.forEach((key) => {
                    if (!queryByStory.find(({value}) => value === key)) {
                        queryByStory.push({
                        "operator": "equals",
                        "value": key
                      })
                    }
                    state.story_lapse = queryByStory
                  })
                } else {
                  state.story_lapse = null
                }
        },
        setSurrYearValueFilter: (state, action) => {
          const query = []
            const allSelected = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
            if(allSelected.length > 0) {
                allSelected.forEach((key) => {
                if (!query.find(({value}) => value === key)) {
                    query.push({
                    "operator": "equals",
                    "value": key
                  })
                }
                state.YRS_REMAIN_SC = query
              })
            } else {
              state.YRS_REMAIN_SC = null
            }
        },
        setAgentStatusFilter: (state, action) => {
          state.agent_status = getQueryFromPlainAction(action)
        },
        setContractLossFilter: (state, action) => {
          state.contract_loss = getPercentageQueryFromAction(action)   
        },        
        setIncomeLossFilter: (state, action) => {
          state.income_lost = getPercentageQueryFromAction(action)  
        },
        setStoryTypeFilter: (state, action) => {
                if (action.payload === state.storyType) {
                  state.storyType = null
                } else {
                  if (action.payload === "Retention") {
                    state.storyType = 'lapse'
                  }
                  if (action.payload === "New Business") {
                    state.storyType = 'ui'
                  }
                }
        },
        setAgeFilter: (state, action) => {
            if (!action.payload["All"]) {
                const queryByAge = []
                const ageFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                ageFilterSelectedEntries.forEach((filter) => {
                    const value = filter[0]
                    if (value.includes("<") && ["50", "18"].includes(value.replace("<", ""))) {
                    queryByAge.push({"operator": 'lte', "value": value.replace("<", "")})
                    } else if (value.includes("+") && value.replace("+", "") === "80") {
                    queryByAge.push({"operator": 'gte', "value": "80"})
                    } else {
                    const range = value.split("-")
                    if (value !== '1') {
                        queryByAge.push({
                        "operator": 'between',
                        "value": range.join(",")
                        })            
                    }
                    }
                })
                state.age = queryByAge             
            } else {
                state.age = null
            }
        },
        setAgeAtIssueFilter: (state, action) => {
            if (!action.payload["All"]) {
                const queryByAge = []
                const ageFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                ageFilterSelectedEntries.forEach((filter) => {
                    const value = filter[0]
                    if (value.includes("<") && ["50", "18"].includes(value.replace("<", ""))) {
                    queryByAge.push({"operator": 'lte', "value": value.replace("<", "")})
                    } else if (value.includes("+") && value.replace("+", "") === "80") {
                    queryByAge.push({"operator": 'gte', "value": "80"})
                    } else {
                    const range = value.split("-")
                    if (value !== '1') {
                        queryByAge.push({
                        "operator": 'between',
                        "value": range.join(",")
                        })            
                    }
                    }
                })
                state.age_at_issue = queryByAge             
            } else {
                state.age_at_issue = null
            }
        },
        setGenderFilter: (state, action) => {
            if (!action.payload["All"]) {
                const queryByGender = []
                if(action.payload["Male"]) {
                  queryByGender.push({
                    "operator": "in",
                    "value":"M"
                  })
                }
                if(action.payload["Female"]) {
                  queryByGender.push({
                    "operator": "in",
                    "value":"F"
                  })
                }
                if(action.payload["Other"]) {
                  queryByGender.push({
                    "operator": "in",
                    "value":"O"
                  })
                }     
                state.gender = queryByGender       
            } else {
                state.gender = null
            }
        },
        setUIFaceAmountFilter: (state, action) => {
          const currency = getCountryCurrencySymbol()
            if (!action.payload["All"] && action.payload["All"] !== undefined) {
                const queryByFaceAmount = []
                const faceAmountFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                faceAmountFilterSelectedEntries.forEach((filter) => {
                  const value = filter[0]
                  if (value[value.length-1] === "<") {
                    queryByFaceAmount.push({"operator": 'gte', "value": `${nBuilder(value.replace(`<${currency}`, "").replace("<", ""))}`})
                  } else if (value[0] === "<") {
                    queryByFaceAmount.push({"operator": 'lte', "value": `${nBuilder(value.replace(`<${currency}`, ""))}`})
                  } else {
                    const range = value.split("-")
                    queryByFaceAmount.push({
                      "operator": 'between',
                      "value": `${nBuilder(range[0].replace(currency, ""))},${nBuilder(range[1].replace(currency, ""))}`
                    })
                  }
                })    
                state.face_amount = queryByFaceAmount       
            } else {
                state.face_amount = null
            }
        },
        setLapseFaceAmountFilter: (state, action) => {
            const queryByFaceAmount = []
            const allSelectedFaceAmounts = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
            if(allSelectedFaceAmounts.length > 0) {
                allSelectedFaceAmounts.forEach((key) => {
                if (!queryByFaceAmount.find(({value}) => value === key)) {
                    queryByFaceAmount.push({
                    "operator": "equals",
                    "value": key
                  })
                }
                state.face_amount_prediction = queryByFaceAmount
              })
            } else {
              state.face_amount_prediction = null
            }
        },
        setPremiumFilter: (state, action) => {
            const currency = getCountryCurrencySymbol()
            if (!action.payload["All"] && action.payload["All"] !== undefined) {
                const queryByIncome = []
                const incomeFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                incomeFilterSelectedEntries.forEach((filter) => {
                  const value = filter[0]
                  if (value.includes("-")) {
                    const range = value.split("-")
                    queryByIncome.push({
                      "operator": 'between',
                      "value": `${nBuilder(range[0].replace(currency, "").replace("<", ""))},${nBuilder(range[1].replace(currency, "").replace("<", ""))}`
                    })
                  } else if (value[0] === "<") {
                    queryByIncome.push({"operator": 'lte', "value": `${nBuilder(value.replace(currency, "").replace("<", ""))}`})
                  } else {
                    queryByIncome.push({"operator": 'gte', "value": `${nBuilder(value.replace(currency, "").replace("<", ""))}`})
                  }
                })    
                state.premium = queryByIncome       
            } else {
                state.premium = null
            }
        },
        setAnnualizedPremiumFilter: (state, action) => {
            const currency = getCountryCurrencySymbol()
            if (!action.payload["All"] && action.payload["All"] !== undefined) {
                const queryByIncome = []
                const incomeFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                incomeFilterSelectedEntries.forEach((filter) => {
                  const value = filter[0]
                  if (value.includes("-")) {
                    const range = value.split("-")
                    queryByIncome.push({
                      "operator": 'between',
                      "value": `${nBuilder(range[0].replace(currency, "").replace("<", ""))},${nBuilder(range[1].replace(currency, "").replace("<", ""))}`
                    })
                  } else if (value[0] === "<") {
                    queryByIncome.push({"operator": 'lte', "value": `${nBuilder(value.replace(currency, "").replace("<", ""))}`})
                  } else {
                    queryByIncome.push({"operator": 'gte', "value": `${nBuilder(value.replace(currency, "").replace("<", ""))}`})
                  }
                })    
                state.annualized_premium = queryByIncome       
            } else {
                state.annualized_premium = null
            }
        },
        setPremiumUIFilter: (state, action) => {
            const currency = getCountryCurrencySymbol()
            if (!action.payload["All"] && action.payload["All"] !== undefined) {
                const queryByIncome = []
                const incomeFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                incomeFilterSelectedEntries.forEach((filter) => {
                  const value = filter[0]
                  if (value.includes("-")) {
                    const range = value.split("-")
                    queryByIncome.push({
                      "operator": 'between',
                      "value": `${nBuilder(range[0].replace(currency, "").replace("<", ""))},${nBuilder(range[1].replace(currency, "").replace("<", ""))}`
                    })
                  } else if (value.includes("+")) {
                    queryByIncome.push({"operator": 'gte', "value": `${nBuilder(value.replace(currency, "").replace("+", ""))}`})
                  } else if (value[0] === "<") {
                    queryByIncome.push({"operator": 'lte', "value": `${nBuilder(value.replace(`<${currency}`, ""))}`})
                  }
                })    
                state.mean_premium_term = queryByIncome       
            } else {
                state.mean_premium_term = null
            }
        },
        setLapsePremiumFilter: (state, action) => {
            const currency = getCountryCurrencySymbol()
            if (!action.payload["All"] && action.payload["All"] !== undefined) {
                const queryByIncome = []
                const incomeFilterSelectedEntries = Object.entries(action.payload).filter((item) => item[1])
                incomeFilterSelectedEntries.forEach((filter) => {
                  const value = filter[0]
                  if (value.includes("-")) {
                    const range = value.split("-")
                    queryByIncome.push({
                      "operator": 'between',
                      "value": `${nBuilder(range[0].replace(currency, ""))},${nBuilder(range[1].replace(currency, ""))}`
                    })
                  } else if (value.includes("+")) {
                    queryByIncome.push({"operator": 'gte', "value": `${nBuilder(value.replace(currency, "").replace("+", ""))}`})
                  } else if (value[0] === "<") {
                    queryByIncome.push({"operator": 'lte', "value": `${nBuilder(value.replace(`<${currency}`, ""))}`})
                  }
                })    
                state.medium_ppr = queryByIncome       
            } else {
                state.medium_ppr = null
            }
        },
        setContractValueFilter: (state, action) => {
            state.cash_value = getMoneyQueryFromAction(action)       
        },
        setSurrValueFilter: (state, action) => {
            state.CSV_LAG = getMoneyQueryFromAction(action)      
        },        
        setLibrFilter: (state, action) => {
              state.libr_payment = getMoneyQueryFromAction(action)       
        },
        setIncomeAccFilter: (state, action) => {
        state.income_value = getMoneyQueryFromAction(action)       
        },
        setWithdravalActivityFilter: (state, action) => {
          state.withdraw_activity = getQueryFromPriority(action)
        },
        setSubActivityFilter: (state, action) => {
          state.sub_activity = getQueryFromPriority(action)
        },
        setPriorityFilter: (state, action) => {
          state.PRIORITY = getQueryFromPriority(action, true)
        },
        setStatusFilter: (state, action) => {
          state.status = getQueryFromPlainAction(action)
        },
        setLapseScoreFilter: (state, action) => {
          state.lapse_score = getQueryFromPriority(action, true)
        },
        setSensetivityFilter: (state, action) => {
          state.annualized_premium_sensitivity_NP = getQueryFromPriority(action, false, {
            "very sensitive": 1,
            "sensitive": 2, 
            "rather sensitive": 3, 
            "rather insensitive": 4,
            "insensitive": 5, 
            "very insensitive": 6
          })
        },
        setLIBRStatusFilter: (state, action) => {
          state.libr_status = getQueryFromPlainAction(action)
        },
        setStateFilter: (state, action) => {
          const queryByState = []
          const allSelectedState = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
          if(allSelectedState.length > 0) {
            allSelectedState.forEach((key) => {
              const stateKey = usStatesData.find(({ name }) => name.toUpperCase() === key)?.abbreviation
              if (!queryByState.find(({value}) => value === stateKey)) {
                queryByState.push({
                  "operator": "equals",
                  "value": stateKey
                })
              }
              state.filters.State = queryByState
            })
          } else {
            state.filters.State = null
          }
        },
        setResStateFilter: (state, action) => {
                const queryByResState = []
                const allSelectedResState = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
                if(allSelectedResState.length > 0) {
                  allSelectedResState.forEach((key) => {
                    const stateKey = usStatesData.find(({ name }) => name.toUpperCase() === key)?.abbreviation
                    if (!queryByResState.find(({value}) => value === stateKey)) {
                      queryByResState.push({
                        "operator": "equals",
                        "value": stateKey
                      })
                    }
                    state.filters.res_state = queryByResState
                  })
                } else {
                  state.filters.res_state = null
                }
        },
        setDurationFilter: (state, action) => {
          state.Duration_segment = getQueryFromPlainAction(action)
        },
        setDurationSegmentFilter: (state, action) => {
          state.duration_segment = getQueryFromPlainAction(action)
        },
        setDateStartFilter: (state, action) => {
          const queryByDate = []
          state.date.date_start = action.payload
          if (!state.date.date_end) {
            queryByDate.push({
              "operator": "gte",
              "value": new Date(action.payload).toISOString()
            })
          } else {
            queryByDate.push({
              "operator": "gte",
              "value": new Date(action.payload).toISOString()
            })
            queryByDate.push({
              "operator": "lte",
              "value": new Date(state.date.date_end).toISOString()
            })
          }
          state.date.query = queryByDate
        },
        setDateEndFilter: (state, action) => {
          console.log(new Date(action.payload).toISOString());
          const queryByDate = []
          state.date.date_end = action.payload
          if (!state.date.date_start) {
            queryByDate.push({
              "operator": "lte",
              "value": new Date(action.payload).toISOString()
            })
          } else {
            queryByDate.push({
              "operator": "gte",
              "value": new Date(state.date.date_start).toISOString()
            })
            queryByDate.push({
              "operator": "lte",
              "value": new Date(action.payload).toISOString()
            })
          }
          state.date.query = queryByDate
        },
        setOrphanFilter: (state, action) => {
          if (!action.payload["All"]) {
              const queryByOrphan = []
              if(action.payload["Yes"]) {
                queryByOrphan.push({
                  "operator": "equals",
                  "value":"true"
                })
              }
              if(action.payload["No"]) {
                queryByOrphan.push({
                  "operator": "equals",
                  "value":"false"
                })
              }
              state.agent_terminated = queryByOrphan       
          } else {
              state.agent_terminated = null
          }
        },
        resetFilters: (state) => {
          state.product = null
          state.premium = null
          state.annualized_premium = null
          state.face_amount = null
          state.face_amount_prediction = null
          state.gender = null
          state.age = null
          state.age_at_issue = null
          state.story_lapse = null
          state.storyType = null
          state.state = null
          state.priority = null
          state.libr_status = null
          state.status = null
          state.MARKET_NAME_LAG = null
          state.CSV_LAG = null
          state.cash_value = null
          state.PRIORITY = null
          state.mean_premium_term = null
          state.medium_ppr = null
          state.Duration_segment = null
          state.duration_segment = null
          state.agent_terminated = null
          state.date = {
            date_start: null,
            date_end: null,
            query: null
          }
        },
        resetExistingFilters: (state) => {
          state.product = null
          state.premium = null
          state.annualized_premium = null
          state.face_amount = null
          state.age = null
          state.age_at_issue = null
          state.gender = null
          state.CSV_LAG = null
          state.cash_value = null
          state.MARKET_NAME_LAG = null
          state.YRS_REMAIN_SC = null
          state.Duration_segment = null
          state.duration_segment = null
          state.agent_terminated = null
        },
        resetRecomended: (state) => {
          state.story_lapse = null
          state.lapse_score = null
          state.annualized_premium_sensitivity_NP = null
          state.PRIORITY = null
          state.mean_premium_term = null
          state.medium_ppr = null
          state.status = null
        },
        resetStatus: (state) => {
          state.status = null
        },
    }
})

export const { 
    setAgeFilter, setAgeAtIssueFilter, setGenderFilter, setStoryFilter, setActiveTab, setContractValueFilter, setSurrValueFilter, setSurrYearValueFilter, setIncomeAccFilter,
    setAgentStatusFilter, setLibrFilter, setWithdravalActivityFilter, setSubActivityFilter, setContractLossFilter, setIncomeLossFilter, setStoryTypeFilter, setDateStartFilter, setDateEndFilter,
    setProductFilter, setPremiumFilter, setAnnualizedPremiumFilter, setUIFaceAmountFilter, setLapsePremiumFilter, setLapseFaceAmountFilter, setProjectLabels, setStateFilter, setResStateFilter,
    setPriorityFilter, setLIBRStatusFilter, setLapseScoreFilter, setStatusFilter, setProductNameFilter, setPremiumUIFilter, setDurationFilter, setDurationSegmentFilter, setSensetivityFilter,
    setOrphanFilter, resetFilters, resetExistingFilters, resetRecomended, resetStatus  
} = FiltersSlice.actions

export const selectFilters = (state) => state.filters


export default FiltersSlice.reducer