import React, { useRef, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';


// Icons
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import DashboardActiveIcon from '@mui/icons-material/Dashboard';
import OptimizerActiveIcon from '@mui/icons-material/QueryStatsOutlined';
import RocketIcon from '@mui/icons-material/RocketOutlined';
import RocketActiveIcon from '@mui/icons-material/Rocket';
import MagnifierActiveIcon from '@mui/icons-material/StorageOutlined';
import SegmentationIcon from '@mui/icons-material/AssistantOutlined';
import SegmentationActiveIcon from '@mui/icons-material/Assistant';
import SettingIcon from '@mui/icons-material/SettingsOutlined';
import SettingIconAcive from '@mui/icons-material/Settings';
import ProspectsIcon from '@mui/icons-material/PeopleAltOutlined';
import ProspectsActiveIcon from '@mui/icons-material/PeopleAlt';
import ProjectsIcon from '@mui/icons-material/AccountTreeOutlined';
import ProjectsActiveIcon from '@mui/icons-material/AccountTree';
import LeadsIcon from '@mui/icons-material/PersonAddAlt';
import LeadsActiveIcon from '@mui/icons-material/PersonAddAlt1';
import {ReactComponent as PremiumPredictedIcon} from './Assets/icon-premium-predicted.svg';
import {ReactComponent as ProspectOpportunitiesIcon} from './Assets/icon-prospect-opportunities.svg';
import {ReactComponent as LogoBrokerIcon} from './Assets/icon-exl-logo.svg';

// MUI
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { styled } from '@mui/material/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Avatar, Button, Grid, Popover } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Components
import Project from './Components/Projects/Project';
import AlertDialog from './Components/AlertDialog/AlertDialog';
import DataManagement from './Components/DataManagement/DataManagement';



// Pages
import sampleCampaignList from './Pages/Campaigns/sampleCampaignList.json'
import Settings from './Pages/Settings/Settings';
import Campaigns from './Pages/Campaigns/Campaigns';
import RunPage from './Pages/RunPage/RunPage';
import Mapper from './Pages/Mapper/Mapper';
import Upload from './Pages/Upload/UploadFile';
import FileManagment from './Pages/FileManagement/FileManagement';
import NewProject from './Pages/NewProject/NewProject';
import NewDataSource from './Pages/NewDataSource/NewDataSource';
import NewUserDemo from './Pages/NewUserDemo/NewUserDemo';
import SampleInfo from './Pages/NewUserDemo/SampleInfo';
import Library from './Pages/Library/Library';
import { removeCampaignProgress, updateCampaignsProgress } from './Pages/CreateCampaign/CreateCampaignSlice';
import { setSelectSourceMode } from './Pages/NewProject/NewProjectSlice';
import BookAnalysis from './Pages/BookAnalysis/BookAnalysis';
import CreateCampaign from './Pages/CreateCampaign/CreateCampaign';
import DashboardPage from './Pages/Dashboard/Dashboard';
import ProspectsPage from './Pages/Prospects/Prospects';



// Redux
import { removeRunProgress, resetData, selectActiveOption, selectUserInitials, selectUserRole, setActiveOption,
  setCampaignsCounts,  setProjectInformatiom, setProjectsLoaded, setRunLeads, setSearch, setUserCompany,
  setUserInitials, setUserName, setUserRole, setUserPermissions, setUserLimit, updateRunsProgress,
  setMenuDisabled, selectMenuDisabled, selectUserCompany, setUiID, setLapseID, selectUserName, setCurrentUser,
  selectUserLimit, setDemoData, setProjectMetadata, selectLoading, setUserSubscriptions, selectUserSubscriptions,
  setRunLeadsBroker,
  setLeadsLoading,
  setRunProgressError} from './AppSlice';
import { setLoaded, setPoliciesActual } from './Components/Run/RunSlice';
import { resetFilters as resetBookFilters } from './Components/Filters/FiltersSlice';

// Common
import { amplitudeService, parseJwt } from './global/helpers';
import { useMobileDetect } from './global/useMobileDetect';
import { User, checkHubSpotPermission, checkPermission } from './global/user'
import appApiService from './AppService';
import { CircularProgress } from '@mui/material';
import TableauDemo from './Pages/TableauDemo/TableauDemo';
import DemoMapper from './Pages/DemoMapper/DemoMapper';
import { resetFilters } from './Components/Filters/FiltersSlice';
import Segmentation from './Components/Segmentation/Segmentation';
import LeadsPage from './Pages/Leads/Leads';
import { InfoTooltip } from './Components/ui/tooltips';
import PortfolioPage from './Pages/PortfolioPage/PortfolioPage';

const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    "& main": {
      "@media (max-width:1280px)": {
        paddingTop: 8
      }
    }
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 999
    },
  },
  headerActiveOption: {
    fontSize: 18,
    marginTop: 22,
    marginBottom: 22,
    marginLeft: 30,
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: 'white',
    color: 'black',
    boxShadow: "none",
    borderBottom: "1px solid #ecf1f9",
    zIndex: 998

  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuText: {
    fontSize: 12,
    fontWeight: 41100,
    lineHeight: "14px",
    textAlign: 'center',
    color: '#FFFFFF',
    marginBottom: 0
  },
  toolbar: theme.mixins.toolbar,
  centerToolbarIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: 'center',
    minHeight: 64
  },
  toolbarPadding: {
    padding: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.appbar,
    color: theme.palette.primary.text_secondary,
    fontSize: 9,
  },
  drawerPaperSecondary: {
    width: drawerWidth,
    backgroundColor: theme.palette.secondary.appbar,
    color: theme.palette.secondary.text_secondary,
    fontSize: 9,
  },
  listContainer: {
    "&.MuiList-padding": {
      "@media (max-width:1080px)": {
        padding: 0
      }
    }
  },
  listItem: {
    flexDirection: 'column',
    padding: 0,
    padding: "13px 0",
    "&:hover": {
      backgroundColor: theme.palette.primary.appbar_hover
    }
  },
  listItemSecondary: {
    flexDirection: 'column',
    padding: "13px 0",
    "&:hover": {
      backgroundColor: theme.palette.secondary.appbar_hover
    },
    "@media (max-width:1080px)": {
      padding: "8px 0",
    }
  },
  listItemContainer: {
    display: 'flex',
    justifyContent: "center",
    width: "100%",
  },
  listItemDisabled: {
    cursor: 'default',
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  listItemIcon: {
    color: theme.palette.primary.text_secondary,
    cursor: 'default',
    justifyContent: 'center',
    minWidth: 59,
    "& .MuiSvgIcon-root": {
      fontSize: "1.9rem"
    }
  },
  listItemIconSecondary: {
    color: theme.palette.secondary.text_secondary,
    cursor: 'default',
    justifyContent: 'center',
    minWidth: 59,
    "& .MuiSvgIcon-root": {
      fontSize: "1.9rem"
    }
  },
  listIconOutline: {
    stroke: "#fff",
    fill: '#FB4E0B'
  },
  prospectsContainer: {
    position: "fixed"
  },
  prospectsItemRoot: {
    position: "relative",
    left: 80,
    backgroundColor: theme.palette.primary.appbar,
    "& .MuiList-padding": {
      padding: 0
    }
  },
  prospectsItemRootSecondary: {
    position: "relative",
    left: 80,
    backgroundColor: theme.palette.secondary.appbar,
    border: "1px solid #D9E1EE",
    "& .MuiList-padding": {
      padding: 0
    }
  },
  prospectsItem: {
    color: theme.palette.primary.text_secondary,
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: theme.palette.primary.appbar_hover
    }
  },
  prospectsItemSecondary: {
    color: theme.palette.secondary.text_secondary,
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: theme.palette.secondary.appbar_hover
    }
  },
  prospectsItemIcon: {
    color: theme.palette.primary.text_secondary,
  },
  prospectsItemIconSecondary: {
    color: theme.palette.secondary.text_secondary,
  },
  bottomOptions: {
    position: 'fixed',
    bottom: 0
  },
  newProjectIcon: {
    color: '#d82832',
  },
  newProjectButtonContainer: {
    position: 'fixed',
    border: '1px solid #d9e1ee',
    borderRadius: 8,
    right: 80
  },
  newProjectButton: {
    backgroundColor: '#fff',
    borderRadius: 8,
    textTransform: 'capitalize',
    fontSize: 12

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#F9FAFB",
    minHeight: '100vh',
  },
  warningAlert: {
    color: "#dd9c00 !important",
    backgroundColor: "#fff7c5 !important",
    fontSize: 13,
    height: 40,
    padding: "4px 10px"
  },
  mobileContainer: {
    height: "100vh",
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 600,
    backgroundColor: "#F9FAFB"
  },
  logoutPopupRoot: {
    "& .MuiPopover-paper": {
        minWidth: 199,
        width: "fit-content",
        boxShadow: "0px 12px 16px -4px #10182814",
        border: "1px solid #F2F4F7",
        borderRadius: 6,
        paddingTop: 12
    }
  },
  logoutPopupContent: {
    gap: 12
  },
  logoutItemContainer: {
    paddingLeft: 16
  },
  logoutPopupName: {
    fontWeight: 500,
    color: "#344054",
    textTransform: "capitalize"
  },
  logoutPopupEmail: {
    color: "#667085",
    lineHeight: "20px"
  },
  logoutList: {
    width: "100%",
    padding: 0,
    "& a": {
      textDecoration: "none",
      color: "unset",
    }
  },
  logoutListItem: {
    borderTop: "1px solid #F2F4F7",
    cursor: "pointer",
    padding: "10px 16px",
    "&:hover": {
      background: "#EAECF0",
    },
    "& .MuiListItemText-root": {
      margin: 0,
    }
  },


}));


const outlineIconStyle = {
  stroke: "#fff",
  fill: '#FB4E0B'
}
const OptimizerOutlineIcon = styled(OptimizerActiveIcon)(outlineIconStyle)
const MagnifierOutlineIcon = styled(MagnifierActiveIcon)(outlineIconStyle)

const menuOptions = [
  {
    name: "Dashboard",
    icon: DashboardIcon,
    activeIcon: DashboardActiveIcon,
    url: "/dashboard"
  },
  {
    name: "Premium\nPredicted",
    icon: PremiumPredictedIcon,
    activeIcon: PremiumPredictedIcon,
    url: "/impacts"
  },
  {
    name: "Prospect\nOpportunity",
    icon: ProspectOpportunitiesIcon,
    activeIcon: ProspectOpportunitiesIcon,
    url: "/opportunities"
  },
  {
    name: "Book\nAnalysis",
    icon: OptimizerActiveIcon,
    activeIcon: OptimizerActiveIcon,
    url: "/book"
  },
  {
    name: "Prospects",
    icon: ProspectsIcon,
    activeIcon: ProspectsActiveIcon,
    url: "/prospects"
  },
  {
    name: "Clients",
    icon: ProspectsIcon,
    activeIcon: ProspectsActiveIcon,
    url: "/prospects"
  },
  {
    name: "Policyholders",
    icon: ProspectsIcon,
    activeIcon: ProspectsActiveIcon,
    url: "/prospects"
  },
  {
    name: "Campaigns",
    icon: RocketIcon,
    activeIcon: RocketActiveIcon,
    url: "/campaigns"
  },
  {
    name: "Projects",
    icon: ProjectsIcon,
    activeIcon: ProjectsActiveIcon,
    url: "/"
  },
  {
    name: "Data\nManagement",
    icon: MagnifierActiveIcon,
    activeIcon: MagnifierActiveIcon,
    url: "/datasources"
  },
  {
    name: "Recommend",
    icon: SegmentationIcon,
    activeIcon: SegmentationActiveIcon,
    url: "/segmentation"
  },
  {
    name: "Settings",
    icon: SettingIcon,
    activeIcon: SettingIconAcive,
    url: "/settings"
  },
]

const ApiService = new appApiService();


const App = (props) => {
  const { window } = props;
  const { currentUser } = User()
  const [themeName, setThemeName] = React.useState("primary")
  const theme = useTheme();
  const classes = useStyles({ ...theme, palette: theme.palette[themeName]});

  const activeOption = useSelector(selectActiveOption)
  const userInitials = useSelector(selectUserInitials)
  const userName = useSelector(selectUserName)
  
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [hover, setHover] = React.useState("")
  const [subMenuHover, setSubMenuHover] = React.useState("")
  const [activeItem, setActiveItem] = React.useState("")
  const [login, setLogin] = React.useState(true)
  const [menu, setMenuOptions] = React.useState([])
  const dispatch = useDispatch()
  const history = useHistory();
  const userRole = useSelector(selectUserRole)
  const userLimit = useSelector(selectUserLimit)
  const userCompany = useSelector(selectUserCompany)
  const menuDisabled = useSelector(selectMenuDisabled)
  const isLoading = useSelector(selectLoading)
  const subscriptions = useSelector(selectUserSubscriptions)
  const projectsLoaderRef = useRef()

  const [OpenErrorPopover, setOpenErrorPopover] = React.useState(false);
  const [OpenLogoutPopover, setOpenLogoutPopover] = React.useState(false);
  const [OpenProspectsPopover, setOpenProspectsPopover] = React.useState(false);
  const [socketProgress, setSocketProgress] = React.useState(null);
  const [segmentationOpen, setSegmentationOpen] = React.useState(false);
  const ErrorPopoverRef = React.useRef({ top: 0, left: 0});
  const ProspectsPopoverRef = React.useRef();
  const LogoutPopoverRef = React.useRef();
  const socketref = React.useRef(null)

  const isAnnuities = localStorage.getItem("accountType") === "annuities"
  const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;
  const blockedRouts = []
  const isMobile = useMobileDetect()

  useEffect(() => {
    setMenuOptions(
      menuOptions
        .filter((item) => {
          switch (item.name) {
            case "Prospects":
              return checkPermission('view_prospect_page') && !isBroker
            case "Policyholders":
              return checkPermission('view_prospect_page') && !checkHubSpotPermission() && isBroker
            case "Clients":
              return checkPermission('view_prospect_page') && checkHubSpotPermission() && isBroker
            case "Projects":
              return checkPermission('view_projects') && !isBroker
            case "Campaigns":
              return checkPermission('view_campaign')
            case "Settings":
              return checkPermission('view_settings_page') || isBroker
            case "Premium\nPredicted":
              return !isBroker
            case "Prospect\nOpportunity":
              return !isBroker
            case "Book\nAnalysis":
              return isBroker
          
            default:
              return true;
          }
        })
        .map((item) => ["Book\nAnalysis", "Data\nManagement"].includes(item.name) 
        ? ({
          ...item,
          icon: styled(item.activeIcon)({
            stroke: theme.palette[themeName].text_secondary,
            fill: theme.palette[themeName].appbar
          })
        }) : item
        )
    )
  }, [menuOptions, isBroker])
  
  useEffect(() => {
      if (!socketProgress && localStorage.getItem("refresh")) {  
        sessionStorage.removeItem("atidot_platform_data")
        const socket = ApiService._openProgressWebSocket() 
        socketref.current = socket
        setSocketProgress(socket) 
        
        socket.on("update_run_progress", (response) => {
          if (response.progress === -50) {
            dispatch(setRunProgressError(response))
          } else if (response.progress !== 100 && response.progress > -1) {
            dispatch(updateRunsProgress({
              ...response
            }))
          } else {
            sessionStorage.removeItem("atidot_platform_data")
            dispatch(removeRunProgress(response.id))
          }
          sessionStorage.removeItem("atidot_platform_data")
          resetProjects(response.progress)

 });

        socket.on("update_campaign_progress", (response) => {
          if (response.progress !== 100) {
            dispatch(updateCampaignsProgress({
              ...response
            }))
          } else {
            dispatch(removeCampaignProgress(response.id))
          }
          resetCampaigns()

        });

        socket.io.on("close", () => {
          console.log('close');
          setSocketProgress(null)  
        });        
        
        socket.on("leave_company_room", () => {
          sessionStorage.removeItem("atidot_platform_data")
          setSocketProgress(null)  
        });
        
      }
  }, [socketProgress, dispatch])

  function trackPageView() {
    amplitudeService._throwEvent("PAGE_OPEN", {'path': history.location.pathname})
  }

  React.useEffect(() => {
    // trackPageView() // To track the first pageview upon load
    const unlisten = history.listen(trackPageView); // To track the subsequent pageviews
    // stop the listener when component unmounts
    return unlisten
  }, [history]);

  const handleLeaveRoom = () => {
    if (socketProgress) {
      socketProgress.emit("leave_company_room", 'asd')    
    }
  }

  const resetProjects = (progress) => {
    // Hide loading
    !projectsLoaderRef.current && dispatch(setProjectsLoaded(false))

    // Get runs data
    ApiService._getRuns(
      localStorage.getItem("companyID"),
      (response) => {
        // if user doesn't has datasource uploaded - show demo data
        if (response.data?.count === 0 && history.location.pathname !== "/firstlogin") {

          // Load demo data
          ApiService._getDemoRunsData((res) => {
            dispatch(setDemoData(true))
            dispatch(setSearch(""))
            dispatch(setProjectInformatiom(res.data))
            res.data?.metadata && dispatch(setProjectMetadata(res.data))
            const runIds = []
            res.data?.projects?.forEach(({ runs }) => runs.forEach(({ run_id }) => runIds.push(run_id)))
            User().isAnonym && ApiService._getRuningCampaignsById(runIds, (res) => {
              dispatch(setCampaignsCounts(res.data))
            }, (err) => console.log(err))
  
            projectsLoaderRef.current = setTimeout(() => dispatch(setProjectsLoaded(true)), 1000)
            history.push("/?demo")
          },
          (error) => { console.log(error) })

        } else {
          // Data present. Save in state
          dispatch(setDemoData(false))
          dispatch(setSearch(""))
          dispatch(setProjectInformatiom(response.data))

          if (progress === undefined || progress === 100) {
            response.data?.metadata && dispatch(setProjectMetadata(response.data))
          }

          const runIds = []
          response.data?.projects?.forEach(({ runs }) => runs.forEach(({ run_id }) => runIds.push(run_id)))

          !User().isAnonym && ApiService._getRuningCampaignsById(runIds, (response) => {
            dispatch(setCampaignsCounts(response.data))
          }, (err) => console.log(err))

          projectsLoaderRef.current = setTimeout(() => dispatch(setProjectsLoaded(true)), 1000)
          history.location.search && history.push("/")
        }
      },
      (error) => console.log(error.response)
    )
  };

  const resetCampaigns = () => {
          dispatch(setLeadsLoading(true))
          if (isBroker) {
            ApiService._getCampaignsList((response) => {
              if (response.data.count !== 0 || localStorage.getItem(`demo_campaign_finished_${currentUser.email}`))
              {
                dispatch(setRunLeadsBroker(response.data.campaigns))
                localStorage.setItem(`demo_campaign_finished_${currentUser.email}`, true)
              }
              else {
                dispatch(setRunLeads(sampleCampaignList.metadata_list))
              }
            }, (err) => {
              dispatch(setLeadsLoading(false)); 
              console.log(err);
            })
          } else {
            ApiService._getAllCampaigns((response) => {
              if (response.data.count !== 0 || localStorage.getItem(`demo_campaign_finished_${currentUser.email}`))
              {
                dispatch(setRunLeads(response.data.metadata_list))
                localStorage.setItem(`demo_campaign_finished_${currentUser.email}`, true)
              }
              else {
                dispatch(setRunLeads(sampleCampaignList.metadata_list))
              }
            }, (err) => {
              dispatch(setLeadsLoading(false)); 
              console.log(err);
            })
        }
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const handleSegmentationToggle = () => {
    setSegmentationOpen(!segmentationOpen);
  };

  const changeActiveSelected = (url, name) => {
    if (url !== "/segmentation") {
      setSegmentationOpen(false)
    } else {
      handleSegmentationToggle()
    }
    if (
        name !== "Clients" && 
        url !== "/segmentation" && 
        userRole !== "file_upload_only" && 
        userRole !== "sftp" && 
        ((!["platform-anonym", "anonym_broker"].includes(userCompany) && !menuDisabled) || ["/book"].includes(url)) && 
        !(
          blockedRouts.includes(url) && 
          isAnnuities
        )
      ) {
      projectsLoaderRef.current = null
      // Return on dashboard if user is anonym or if user doesn't has access to dashboard 
      if (url === '/dashboard' && (User().isAnonym) ) { return }
      if(url !== "/run") {
        if (String(name.type) === 'Symbol(react.fragment)') { name = name.props.children.toString().replace(',[object Object],',' ')}
        amplitudeService._throwEvent("MENU_ITEM_"+name)
        dispatch(setLoaded(false))
        sessionStorage.removeItem("runId")
        history.push(url)
      } else {
        //amplitudeService._throwEvent("RESULT_VIEW")
        //moveToLastRun();
      }
    }
  }

  const handleOnHover = (hoveredItem, id) => {
    if (id === "Clients") {
      setOpenProspectsPopover(true)
    } else if (hoveredItem !== "/prospects" && hoveredItem !== "") {
      setOpenProspectsPopover(false)
    }
    
    if (userRole !== "file_upload_only" && userRole !== "sftp" && hoveredItem !== "/run") {
      setHover(hoveredItem)
    }
  }

  const handleLogout = (redirect = true) => {
    amplitudeService._throwEvent("LOGOUT", {'email': User().currentUser.email})
    dispatch(resetData())
    dispatch(resetFilters())
    dispatch(resetBookFilters())
    dispatch(setMenuDisabled(false))
    dispatch(setUiID(0))
    dispatch(setLapseID(0))
    localStorage.removeItem("token")
    localStorage.removeItem("refresh")
    localStorage.removeItem("userName")
    localStorage.removeItem("userID")
    localStorage.removeItem("accountType")  
    localStorage.removeItem("companyID")
    localStorage.removeItem("name")
    sessionStorage.removeItem("atidot_platform_data")
    handleLeaveRoom()
    setLogin(false)
    redirect && history.push("/login")
  }

  
  useEffect(() => {
    if (login && ["/", "/book"].includes(history.location.pathname) && !history.location.search) {
      setLogin(false)
      ApiService._getDataSources((response) => {
        if (!response.data?.count && checkPermission('add_datasource')) {
          dispatch(setActiveOption(''))
          history.push("/firstlogin")
        }
      }, (error) => console.log(error))
    }
    return () => {
      if (User().isAnonym) {
        handleLogout(false)
      }
    } 
  }, [login, history, dispatch])

  useEffect(() => {
    if (!userInitials) {
      dispatch(setUserInitials(localStorage.getItem("userName")?.includes("americanequity") ? "AE" : localStorage.getItem("userName")?.charAt().toUpperCase()))
      dispatch(setUserName(localStorage.getItem("userName")))
    }
    //!userInitials && dispatch(setUserInitials(localStorage.getItem("userName")?.charAt().toUpperCase()))
  }, [userInitials, dispatch])

  useEffect(() => {
    if(history.location.pathname !== "/run") {
      dispatch(setPoliciesActual(0))
      sessionStorage.removeItem("run_data")
    }
    if(history.location.pathname !== "/") {
      projectsLoaderRef.current = null
    } else if (isBroker) {
      history.push("/book")
    }
    if (history.location.pathname !== "/settings" && history.location.pathname !== "/campaigns/view") {
      const activeComponent = menu.find(({url}) => url === history.location.pathname)
      dispatch(setActiveOption(activeComponent?.url === "/datasources" ? "Data Management" : activeComponent?.url === "/book" ? "Portfolio Analysis" : activeComponent?.name))
    }
    if(history.location.pathname === "/datasources") {
      dispatch(setSelectSourceMode(false));
    }
    if(history.location.pathname.includes("/mapper")) {
      handleLogout(false)
    } else if (!localStorage.getItem("refresh")) {
      history.push("/mapper/carrier")
    }
  }, [history.location.pathname, dispatch, history])
  
  useEffect(() => {
    if(localStorage.getItem("token")) {
      const payload = parseJwt(localStorage.getItem("token"))
      dispatch(setCurrentUser(payload))
      dispatch(setUserRole(payload["roles"]))
      payload['permissions'] && dispatch(setUserPermissions(payload['permissions']))
      localStorage.getItem("subscriptions") && dispatch(setUserSubscriptions(JSON.parse(localStorage.getItem("subscriptions"))))
      dispatch(setUserLimit(payload['user_limit']))
      dispatch(setUserCompany(payload["company_name"]))
    }
  }, [userRole, dispatch])

  useEffect(() => {
    if(userRole === "file_upload_only" || userRole === "sftp") {
      history.push("/fileupload")
      dispatch(setActiveOption('Atidot POC File Management'))
    }
  }, [userRole, dispatch, history, history.location.pathname])
  
  useEffect(() => {
    history.listen((location, action) => {
      setActiveItem(location.pathname)
    })
  }, [history])


  const drawer = (
    <div>
      <div className={classes.centerToolbarIcon} style={isBroker ? { minHeight: 76 } : {}}>
        <LogoBrokerIcon style={{ width: 65, height: 49}}/>
        <Typography style={{ fontSize: 10}}>By Atidot</Typography>
      </div>
      {(userRole !== "file_upload_only" && userRole !== "sftp") && <List className={classes.listContainer}>
        {
          menu
          .filter(
            (item) => {
              const showSettings = item.name !== "Settings" || (userLimit === null || userLimit >= 2) || isBroker;
              
              const showEmbedded = item.url !== `/tableau-demo` || userRole === "platform:admin"
              
              return showSettings && showEmbedded
              }
            
            )
            .map((item, index) => {
                return (
                  <ListItem 
                    className={clsx(
                      themeName === "primary" ? classes.listItem : classes.listItemSecondary, 
                        ((["platform-anonym", "anonym_broker"].includes(userCompany) || menuDisabled) && item.url !== "/book") || 
                        (
                          blockedRouts.includes(item.url) && 
                          isAnnuities
                        )
                      ? classes.listItemDisabled : " "
                      )} 
                    ref={item.name === "Run" ? ErrorPopoverRef : item.name === "Clients" ? ProspectsPopoverRef : null} button key={item.url} 
                    onClick={() => changeActiveSelected(item.url, item.name)} 
                    onMouseOver={() => handleOnHover(item.url, item.name)} onMouseLeave={() => handleOnHover("")}
                    >
                      <div className={classes.listItemContainer} style={
                        (history.location.pathname === item.url || activeItem === item.url || (history.location.pathname === "/leads" && item.url === "/prospects") || (segmentationOpen && item.url === "/segmentation")) 
                        && (!segmentationOpen || (segmentationOpen && item.url === "/segmentation"))
                        ? { borderLeft: "3px solid #FFFFFF" }
                        : {}
                        }>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <ListItemIcon className={themeName === "primary" ? classes.listItemIcon : classes.listItemIconSecondary}>
                                {
                                (history.location.pathname === item.url || activeItem === item.url || (history.location.pathname === "/leads" && item.url === "/prospects") || (segmentationOpen && item.url === "/segmentation")) 
                                && (!segmentationOpen || (segmentationOpen && item.url === "/segmentation"))
                                ? <item.activeIcon/> : <item.icon/>
                                }
                          </ListItemIcon>
                          <ListItemText data-testid={`menu${index}`} disableTypography={true} className={classes.menuText} primary={item.name}/>
                        </div>
                      </div>
       
                  </ListItem>
                )
            })
        }
      </List>
      }

      <List className={classes.bottomOptions}>
        {!User().isAnonym && <ListItem style={{ paddingLeft: 10 }} className={themeName === "primary" ? classes.listItem : classes.listItemSecondary} button key='Profile' onClick={() => setOpenLogoutPopover(true)}>
          <ListItemIcon className={classes.listItemIcon}>
          <InfoTooltip
            placement="right"
            title={
              isBroker ? <React.Fragment>
                <div>{currentUser['email']}</div>
                <div>{subscriptions?.length ? subscriptions[subscriptions.length-1].name?.toUpperCase() : ""}</div>
              </React.Fragment>
              : <React.Fragment>
              <div>{userName}</div>
              <div>{userCompany}</div>
              <div>{userRole.replace("platform:", "").toUpperCase()}</div>
            </React.Fragment>
            }
          >
            <Avatar ref={LogoutPopoverRef} style={{ backgroundColor: isBroker ? "#344054" : "#FFFFFF", color: themeName === "primary" ? "#FB4E0B" : "#FFFFFF" }}>{userInitials || currentUser?.email?.charAt()?.toUpperCase()}</Avatar>
          </InfoTooltip>

            </ListItemIcon>
        </ListItem>}             
      </List>

      <Popover 
        id="logout-popover"
        className={classes.logoutPopupRoot}
        anchorEl={LogoutPopoverRef.current}
        open={OpenLogoutPopover}
        onClose={() => setOpenLogoutPopover(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container direction='column' className={classes.logoutPopupContent}>
          <Grid item container className={classes.logoutItemContainer}>
            <Avatar>{userInitials || currentUser?.email?.charAt()?.toUpperCase()}</Avatar>
          </Grid>

          <Grid item container direction='column' className={classes.logoutItemContainer}>
            {currentUser?.name && currentUser?.name !== "full name" && 
            <Typography className={classes.logoutPopupName}>{currentUser?.name}</Typography>}
            <Typography className={classes.logoutPopupEmail}>{currentUser?.email}</Typography>

          </Grid>

          <Grid item container>
            <List className={classes.logoutList}>
              <a href='mailto:support@atidot.com'>
                <ListItem className={classes.logoutListItem}>
                  <ListItemText>Support</ListItemText>
                </ListItem>
              </a>
              <ListItem onClick={handleLogout} className={classes.logoutListItem}>
                <ListItemText><span style={{color: "#363ED3"}}>Log out</span></ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Popover>


      <Popover
        id="simple-popover"
        open={OpenErrorPopover}
        anchorEl={ErrorPopoverRef.current}
        onClick={(e) => { e.stopPropagation(); setOpenErrorPopover(false) }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        >
        <Alert severity='warning' className={classes.warningAlert}>No runs to explore</Alert>
       </Popover>
       <AlertDialog></AlertDialog>
    </div>
  );

  // End of Draver

  const container = window !== undefined ? () => window().document.body : undefined;



  return (
    <>
      <div className={classes.root}>
        {
        isMobile ? 
          <Grid container justifyContent='center' alignItems='center' className={classes.mobileContainer}>
            Mobile is Not Supported
          </Grid>
        : <>
          <Switch>
            
            <Route  exact path="/library">
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbarPadding}>
                          <Typography variant="h4" className={classes.headerActiveOption} noWrap>
                            {activeOption}
                          </Typography>
                        <div className={classes.newProjectButtonContainer}>
                          {checkPermission('create_project') && <Button data-testid="newProjectBtn"
                                                                variant="outlined"
                                                                disableElevation={true}
                                                                disableFocusRipple={true}
                                                                className={classes.newProjectButton}
                                                                startIcon={<AddIcon className={classes.newProjectIcon} />}
                                                                onClick={() => {
                                                                  
                                                                    ApiService._getDataSources((response) => {
                                                                      if (!response.data?.count) {
                                                                        dispatch(setActiveOption(''))
                                                                        history.push("/firstlogin")
                                                                      } else {
                                                                        sessionStorage.removeItem("current_id")
                                                                        dispatch(setActiveOption(''))
                                                                        history.push("/NewProject")
                                                                      }
                                                                    }, (error) => console.log(error))
                                                                  
                                                                }}
                          >
                            Set New Project
                          </Button>
                        }
                      </div>
                </Toolbar>
              </AppBar>
            </Route>

            <Route path="/upload"/>
            <Route path="/mapper"/>
            <Route path="/NewProject"/>
            <Route path="/firstlogin"/>
          </Switch>
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: themeName === "primary" ? classes.drawerPaper : classes.drawerPaperSecondary,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: themeName === "primary" ? classes.drawerPaper : classes.drawerPaperSecondary,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
            {OpenProspectsPopover && <div style={{ top: ProspectsPopoverRef.current?.getBoundingClientRect()?.top }} className={clsx(classes.prospectsContainer, "submenu--clients")}>
                        <List className={themeName === "primary" ? classes.prospectsItemRoot : classes.prospectsItemRootSecondary} onMouseOver={() => setOpenProspectsPopover(true)} onMouseLeave={() => setOpenProspectsPopover(false)}>
                          <ListItem onClick={() => history.push("/prospects")} onMouseOver={() => setSubMenuHover("prospects")} onMouseLeave={() => setSubMenuHover("")} className={themeName === "primary" ? classes.prospectsItem : classes.prospectsItemSecondary}>
                            <ListItemIcon className={themeName === "primary" ? classes.prospectsItemIcon : classes.prospectsItemIconSecondary}>
                              {subMenuHover === "prospects" ? <ProspectsActiveIcon/> : <ProspectsIcon/>}
                            </ListItemIcon>
                            <ListItemText>Policyholders</ListItemText>
                          </ListItem>
                          <ListItem onClick={() => history.push("/leads")} onMouseOver={() => setSubMenuHover("leads")} onMouseLeave={() => setSubMenuHover("")} className={themeName === "primary" ? classes.prospectsItem : classes.prospectsItemSecondary}>
                            <ListItemIcon className={themeName === "primary" ? classes.prospectsItemIcon : classes.prospectsItemIconSecondary}>
                              {subMenuHover === "leads" ? <LeadsActiveIcon/> : <LeadsIcon/>}
                            </ListItemIcon>
                            <ListItemText>New Leads</ListItemText>
                          </ListItem>
                        </List>        
                      </div>}   
          </nav>
          
          <Segmentation
            open={segmentationOpen}
            container={container}
            onClose={handleSegmentationToggle}
          />

          {!isLoading ? 
          <main className={classes.content}>
            <Switch>
              <Route path="/upload"/>
              <Route path="/mapper"/>
              <Route path="/NewProject"/>
              <Route path="/firstlogin"/>
            </Switch>
            {
              <Switch>
                <Route exact path="/">
                  <Project className={classes.projectStyle} resetProjects={resetProjects} />
                </Route>
                <Route exact path="/run">
                  <RunPage />
                </Route>
                <Route exact path="/datasources">
                  <DataManagement />
                </Route>
                <Route path="/mapper">
                  <DemoMapper />
                </Route>
                <Route exact path="/newmapper">
                  <Mapper />
                </Route>
                <Route exact path="/upload/:source_to_clear">
                  <Upload />
                </Route>
                <Route exact path="/upload">
                  <Upload />
                </Route>
                <Route path="/fileupload">
                  <FileManagment />
                </Route>
                {!["platform-anonym", "anonym_broker"].includes(userCompany) && <Route path="/firstlogin">
                  <NewUserDemo />
                </Route>
                }
                <Route path="/sample-info">
                  <SampleInfo />
                </Route>
                <Route path="/NewProject" >
                    <NewProject />
                </Route>
                <Route exact path="/NewDataSource" >
                    <NewDataSource />
                </Route>
                <Route exact path="/NewCampaign" >
                  <CreateCampaign />
                </Route>
                <Route exact path="/library" >
                    <Library />
                </Route>
                <Route exact path="/settings" >
                  {(((userLimit === null || userLimit >= 2) && checkPermission('view_settings_page')) || isBroker) ? 
                    <Settings />
                  : (userLimit !== null || userLimit < 2) && !checkPermission('view_settings_page') && 
                    <Project className={classes.projectStyle} resetProjects={resetProjects} />}
                </Route>
                <Route exact path="/book" >
                  <BookAnalysis demo={["platform-anonym", "anonym_broker"].includes(userCompany)}/>
                </Route>
                <Route exact path="/impacts" >
                  <PortfolioPage demo={["platform-anonym", "anonym_broker"].includes(userCompany)}/>
                </Route>
                <Route exact path="/opportunities" >
                  <PortfolioPage demo={["platform-anonym", "anonym_broker"].includes(userCompany)}/>
                </Route>
                {userRole === "platform:admin" && <Route exact path="/tableau-demo" >
                  <TableauDemo/>
                </Route>}
                    <Route path="/campaigns">
                      <Campaigns getCampaigns={resetCampaigns}/>
                    </Route>
                    <Route exact path="/prospects">
                      <ProspectsPage/>
                    </Route>
                    {checkHubSpotPermission() && <Route exact path="/leads">
                      <LeadsPage/>
                    </Route>}
                    <Route exact path="/dashboard">
                      <DashboardPage />
                    </Route>
              </Switch>
            }
          </main>
          :
          <Grid container justifyContent="center" alignContent="center" style={{height: "100vh"}}>
              <CircularProgress style={{color: "#000",width:96,height:96}}/>
          </Grid>
          }
        </>
        }
      </div>
    </>
  )
}


export default App;
