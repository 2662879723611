import { Box, CircularProgress, IconButton, Popover, TableCell, TableRow, Typography } from "@material-ui/core";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileDownloadOffOutlinedIcon from '@mui/icons-material/FileDownloadOffOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import { getCountryCurrencySymbol } from "../../../global/helpers";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { selectCampaignsProgress, selectCreatedCampaign } from "../../CreateCampaign/CreateCampaignSlice";
import campaignApiService from "../CampaignService";
import { Alert } from "@material-ui/lab";
import { getCampaignTypeById } from "../../../global/helpers";
import { InfoTooltip } from "../../../Components/ui/tooltips";

const useStyles = makeStyles(() => ({  
    tableCell: {
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        color: "#232033",
        fontWeight: 400,
        paddingTop: 0,
        paddingBottom: 0,
        borderBottom: "1px solid #EEF0F4 !important"
    },
    tableCellStatus: {
        minWidth: "130px !important",
    },
    tableRow: {
        backgroundColor: "#FFFFFF",
        height: 60,
        "&:hover": {
            backgroundColor: "#0000001f"
        }
    },
    rowClickable: {
        cursor: 'pointer'
    },
    completedAlert: {
      justifyContent: "center",
      backgroundColor: "transparent !important",
      color: "#25ca59 !important",
      fontSize: 13,
      borderRadius: 6,
      height: 26,
      padding: "4px 0!important",
      "& div": {
        padding: 0,
        overflow: "hidden"
      }
    },
    campName: {
        color: "#005071!important"
    },
    prospects: {
        color: "#005071!important",
        fontWeight: 700,
        lineHeight: "16px"
    },
    downloadStatusIcon: {
        padding: 8,
        color: "rgba(0, 0, 0, 0.54)",
        cursor: "auto",
        textAlign: "center"
    },
    downloadProgress: {
        position: 'relative', 
        display: 'inline-flex'
    },
    downloadProgressIcon: {
        width: 20,
        height: 20
    },
    downloadProgressTitle: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 12
    }
}))

const ApiService = new campaignApiService()

const CampaignItem = ({ item, selectedCampaignId, handleClick, handleActions, handleExport }) => {
    const {color, name, starts_at, conversions, unsubscribed, action, type, num_outcome_policies, premium_opportunity, aggregated_premium, status_info, webhooks_export_s3_path, id} = item
    const classes = useStyles()
    const downloadErrorPopoverRef = useRef(null);

    // const [open, setOpen] = useState(0)
    // const [click, setClick] = useState(0)
    const [progress, setProgress] = useState(null);
    const [runningStatus, setRunningStatus] = useState(false);
    const [exportedCampaignId, setExportedCampaignId] = useState(null);
    const [exportedCampaignProgress, setExportedCampaignProgress] = useState(null);
    const [downloadErrorOpen, setDownloadErrorOpen] = useState(false);
    const [downloadErrorMessage, setDownloadErrorMessage] = useState("Can't export from inactive campaign!");

    const campaignId = useSelector(selectCreatedCampaign)
    const runingCampaigns = useSelector(selectCampaignsProgress)
    const currency = getCountryCurrencySymbol()

    const handleExportClick = (e, exportId = null) => {
        if(id === selectedCampaignId || id === exportId) {
            webhooks_export_s3_path ?
            ApiService._getExportCsvDownload(exportId || selectedCampaignId, (response) => {
                if(response.data?.status_code !== "403") {
                    handleExport(response.data, exportId)
                } else {
                    setDownloadErrorMessage("Can't export from inactive campaign!")
                    setDownloadErrorOpen(true)
                }
            }, (err) => console.error(err))
            : ApiService._getExportCsvById(selectedCampaignId, (response) => {
                    setExportedCampaignId(selectedCampaignId)
                    setExportedCampaignId(null)
                    setExportedCampaignProgress(null)
                    //reset()
            }, (err) => {
                console.error(err)
                setDownloadErrorMessage("Can't export from inactive campaign!")
                setDownloadErrorOpen(true)
            })
        }
    }

    // useEffect(() => {
    //     let open = 0;
    //     let click = 0;
    //     policies_by_status?.filter(({status_info}) => !["Idle", "email"].includes(status_info.status)).forEach(({count}) => open += count)
    //     policies_by_status?.filter(({status_info}) => !["Idle", "email", "Open"].includes(status_info.status)).forEach(({count}) => click += count)
    //     setOpen(open)
    //     setClick(click)
    // }, [policies_by_status])

    useEffect(() => {
        const progressStatus = runingCampaigns?.find(({id}) => id === item.id)
        if (progressStatus) {
            setProgress(progressStatus.progress)
        }
    }, [runingCampaigns, item])

    return (
        <React.Fragment>
            <TableRow style={{ height: 3}}></TableRow>
            <TableRow 
                className={clsx(classes.tableRow, classes.rowClickable)} 
                onClick={() => handleClick(id)}>
                <TableCell className={clsx(classes.tableCell, classes.campName)} style={{borderLeft: `3px solid ${color}`}}>
                    {name}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {starts_at?.slice(0, 16)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {id === campaignId && runningStatus ?
                    (<p icon={false} className={classes.completedAlert} severity="success" data-testid="runingAlert">{status_info.status === "Processing" && progress !== null ? `Processing ${progress}%` : status_info.status }</p>)
                    : (<p icon={false} className={classes.completedAlert} severity="success" data-testid="successAlert">{status_info.status}</p>)}
                </TableCell>
                <TableCell className={classes.tableCell} style={{ minWidth: 110 }}>
                    {action ? action : getCampaignTypeById(type)}
                </TableCell>
                <TableCell className={clsx(classes.tableCell, classes.prospects)}>
                    {<NumberFormat value={num_outcome_policies ? num_outcome_policies : 0} displayType={'text'} thousandSeparator={true}/>}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {premium_opportunity ? <NumberFormat value={premium_opportunity} displayType={"text"} thousandSeparator={true} prefix={currency}/> : 0}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {conversions}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {unsubscribed}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {aggregated_premium ? <NumberFormat value={aggregated_premium} displayType={"text"} thousandSeparator={true} prefix={currency}/> : 0}
                </TableCell>
                <TableCell className={classes.tableCell} style={{padding: 0}} ref={id === selectedCampaignId ? downloadErrorPopoverRef : null}>
                    <div className={classes.downloadStatusIcon} style={ item?.demo ? {display: "none"} : {display: "block"} }>
                    {
                            exportedCampaignId === id ?
                            (
                                <InfoTooltip title="Export in progress">
                                    <Box className={classes.downloadProgress}>
                                        <CircularProgress variant="determinate" className={classes.downloadProgressIcon}/>
                                        <Box className={classes.downloadProgressTitle}>
                                            <Typography variant="caption" component="div" color="text.secondary">
                                            {`${exportedCampaignProgress}%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </InfoTooltip>
                              )
                            :
                            webhooks_export_s3_path && status_info.status  !== "Stopped" ?
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();
                                    handleExportClick(null,id)
                                }}>
                                    <FileDownloadOutlinedIcon/>
                                </IconButton>
                                : num_outcome_policies > 1000 && <FileDownloadOffOutlinedIcon/>
                    }
                    </div>
                    <div className={classes.downloadStatusIcon} style={ item?.demo ? {display: "block"} : {display: "none"} }>
                        <a href="/campaign-export.csv" download="/campaign-export.csv">
                            <IconButton onClick={(e) => e.stopPropagation()}><FileDownloadOutlinedIcon/></IconButton>
                        </a>
                    </div>
                </TableCell>
                <TableCell className={classes.tableCell} style={{padding: 0}}>
                    <IconButton onClick={(e) => handleActions(e, id)} style={{color: "#C4C4C4"}}>
                        <MoreVertIcon/>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow style={{ height: 3 }}></TableRow>
                        <Popover 
                            id="simple-popover"
                            open={downloadErrorOpen}
                            anchorEl={downloadErrorPopoverRef.current}
                            onClick={(e) => { e.stopPropagation(); setDownloadErrorOpen(false) }}
                            anchorOrigin={{
                                vertical: "center",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "center",
                                horizontal: "left"
                            }}>
                                <Alert severity='warning'>{downloadErrorMessage}</Alert>
                        </Popover>
        </React.Fragment>
    )  

}

export default CampaignItem