import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    cancelButton: {
    },
    confirmButton: {
    },
    closeButton: {
        cursor: "pointer",
        position: "absolute",
        right: 32
    },
    inputTitle: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px",
        paddingBottom: 6,
        paddingTop: 12,
        textTransform: "capitalize",
        color: "#1B1C1E"
    },
    formContainer: {
        gap: 24
    },
    formTitle: {
        fontFamily: "Roboto",
        fontSize: 18,
        fontWeight: 400,
        lineHeight: "22px"
    },
    alertBox: {
      paddingTop: 12,
    },
    loadedAlertContainer:{
      "& .MuiTypography-root": {
        fontSize: 17,
        fontWeight: 500,
        lineHeight: "21px",
        textAlign: "left"
      },
      "& .MuiAlertTitle-root": {
        textAlign: "left!important"
      }
    },
    dragnDropRoot:{
      cursor: "pointer",
      "& .MuiTypography-root": {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "19px",
        textAlign: "center"
      }
    },
    dragnDropText:{
        paddingTop: 12,
    },
    confirmOptions: {
        gap: 32,
        justifyContent: "center"
    },
}))

export const paperStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: 30,
    width: '100%',
    maxWidth: 620,
    borderRadius: "6px",
    boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
};

export const baseDropZoneStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    border: '2px dashed #1976D2',
    borderRadius: 6,
    backgroundColor: '#ffffff',
    color: '#C2C2C2',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};
  
export const activeDropZoneStyle = {
    borderColor: '#2196f3'
};
  
export const acceptDropZoneStyle = {
    borderColor: '#00e676'
};
  
export const rejectDropZoneStyle = {
    borderColor: '#ff1744'
};

export default useStyles
