import React, { useEffect, useState, useRef }  from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { resetRun, selectBehavioralClusterValues, selectRun, selectFaceAmountRanges, selectProductValues, setHeaderHeight, selectStatusValues, selectMarketLag, selectDurationValues } from '../Run/RunSlice';

import { setUIFilter } from '../Run/Charts/ChartsSlice';
import { resetValues } from '../../Pages/NewProject/NewProjectSlice';

import { nFormatter, getCountryCurrencySymbol } from '../../global/helpers';
import SelectFilter from '../Filters/SelectFilter';
import HeaderReport from './HeaderReport';
import HeaderContainer from './HeaderContainer/HeaderContainer';
import ProspectsTotal from '../Book/ProspectsTotal/ProspectsTotal';
import FiltersContianer from '../Filters/FiltersContianer/FiltersContianer';
import { selectColumns, selectUserSubscriptions, setColumns } from '../../AppSlice';
import { resetExistingFilters, setAgeFilter, setProductFilter, setPremiumFilter, resetFilters, setStoryFilter, setLapseScoreFilter, setUIFaceAmountFilter, resetRecomended, resetStatus, setStatusFilter, setContractValueFilter, setSurrValueFilter, setPriorityFilter, setGenderFilter, setLapsePremiumFilter, setProductNameFilter, setSurrYearValueFilter, setDurationFilter, setPremiumUIFilter, setSensetivityFilter, setAgeAtIssueFilter, setAnnualizedPremiumFilter, setDurationSegmentFilter, setOrphanFilter } from '../Filters/FiltersSlice';
import { defaultContractValueFilter, defaultGenderFilter, defaultOrphanFilter, defaultPriorityFilter, defaultSensetivityFilter, defaultYearsFilter } from '../Book/constants';
import PriorityIndicator from '../../Pages/BookAnalysis/PriorityIndicator/PriorityIndicator';
import ColumnsPopup from '../Popups/ColumnsPopup/ColumnsPopup';
import PrecisionPopup from '../Popups/PrecisionPopup/PrecisionPopup';
import BookAnalysisService from '../../Pages/BookAnalysis/BookAnalysisService';
import { User, checkPermission } from '../../global/user';

const useStyles = makeStyles(() => ({
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 20,
  },
  contentSubtitle: {
    fontSize: 11,
    lineHeight: '16px',
    letterSpacing: 0.15,
    fontWeight: 'bold',
    marginLeft: '-8px',
    color: '#171b59',

  },
  withoutPaddigBottom: {
    paddingBottom: "0px!important",
  },
  rootContent: {
    flexGrow: 1,
  },
  dividerHeader: {
    marginLeft: "20px!important",
    marginRight: "20px!important",
  },
  paperHeader: {
    backgroundColor: "#f3f7ff",
    borderRadius: 6,
    padding: "5px 13px 3px 12px",
  },
  UnderinsuredIcon: {
    width: 52,
    height: 48,
  },
  dividerMiddle: {
    marginRight: "22px !important ",
  },
  bigNumberCenter: {
    color: '#171B59',
    fontSize: 40,
    fontWeight: 500,
    lineHeight: '48px'
  },
  bigNumberCenterMd: {
    fontSize: 26,
    lineHeight: '26px'
  },
  bigNumberCenterPng: {
    color: '#171b59',
  },
  filterRow: {
    marginRight: 8,
    marginLeft: 18,
    marginBottom: 24,
    border: "1px solid #ECF1F9",
    borderRadius: 6
  },
  middleHeader: {
    width: '100%',
    padding: "28px 8px 28px 18px"
  },
  middleHeaderShrinked: {
    padding: "16px 8px 10px 18px"
  },
  projectInfo: {
    margin: 0,
    "& .MuiGrid-item": {
      display: "flex"
    }
  },
  projectInfoCard: {
    padding: "0 18px 0 12px",
  },
  projectInfoContainer: {
    padding: "0 12px", 
    paddingBottom: 21, 
  },
  projectInfoTitle: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "24px",
    textTransform: "capitalize"
  },
  projectInfoText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    textTransform: "capitalize",
    color: "#000000"
  },
  projectInfoValue: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    color: "#000000",
    textTransform: "capitalize"
  },
  infoContainer: {
    background: "#FFFFFF",
    borderRadius: 6,
    marginTop: 16
  },
  infoContainerShrinked: {
    marginTop: 0,
    position: "relative",
    top: -7
  },
  infoGrid: {
    background: "#F9FAFB",
    borderRadius: 6,
    display: 'flex',
    width: '100%', 
    alignItems: 'center',
    minHeight: 88, 
    "& .MuiDivider-root": {
      height: 50,
      width: 1,
      background: "#312E4010",
    }
  },
  infoGridShrinked: {
    minHeight: 70
  },
  selectContainer: {
    display: 'flex',
    border: "solid 1px #ecf1f9",
    height: 32,
    padding: '5px 12px !important',
    margin: '5px 0px',
    borderRadius: 6,
    paddingLeft: 12,
    marginLeft: 0,
    marginRight: 12,
    cursor: "pointer"
  },
  expandContainer: {
    display: 'flex',
    height: 32,
    padding: '5px 12px !important',
    margin: '5px 0px',
    borderRadius: 6,
    paddingLeft: 12,
    marginLeft: 0,
    marginRight: 12,
    cursor: "pointer"
  },
  selectValue: {
    borderRadius: 96,
    marginLeft: 10,
    backgroundColor: "#eef0f4",
    padding: "0 20px 0 20px;"

  },
  selectValue2: {
    borderRadius: 6,
    marginLeft: 10,
    backgroundColor: "#CCDBFA",
    padding: "0 20px 0 20px;"

  },
  agePopover: {
    width: 273,
    margin: '9px 12px 8px 7px',
    border: "solid 1px #d9e1ee",
  },
  locationPopOver: {
    width: 273,
    margin: '9px 12px 8px 7px',
    border: "solid 1px #d9e1ee",

  },
  customInputContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: '10px 18px',
  },
  customInputBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    border: '1px solid #363ed3',
    borderRadius: 8,
    height: 38,

  },
  customInput: {
    width: '100%',
    height: '98%',
    border: 'none',
    outline: '0px',
    marginLeft: 8,

    '&::-webkit-outer-spin-button , &::-webkit-inner-spin-button': {
      "-webkit-appearance": 'none',
      display: 'none',
    }
  },
  filterTitle: {
    marginTop: 12,
    marginLeft: 18,
    marginBottom: 11,
    fontSize: 12,
    textTransform: "uppercase"
  },
  ageSlider: {
    width: 200,
    marginLeft: 26,
    marginTop: 40
  },
  UISlider: {
    width: 200,
    marginLeft: 26,
    marginTop: 40,
    '& .MuiSlider-valueLabel': {
      fontSize: '10px',
    },
  },
  saveButton: {
    marginRight: 12
  },
  clearButton: {
    marginLeft: 12
  },
  GenderPopover: {
    width: 273,
    margin: '9px 12px 8px 7px',
    border: "solid 1px #d9e1ee"
  },
  checkBoxProps: {
    marginLeft: 26
  },
  checkLabel: {
    fontSize: 13
  },
  locationProps: {
    marginLeft: 26,
    marginTop: 15,
    marginBottom: 15,
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  MoreFiltersPopover: {
    width: 273,
    margin: '9px 12px 8px 7px',
    border: "solid 1px #d9e1ee"
  },
  customArrowDropDown: {
    height: 19,
    color: '#8F95AD',
    marginTop: '-3px',
  },
  customExpand: {
    height: 19,
    color: '#8F95AD',
  },
  customResetIcon: {
    height: "14px !important",
    color: '#8F95AD',
    cursor: "pointer",
    marginTop: '0px',
    position: "relative",
    top: "3px",
    lef: "3px",
    borderRadius: 85,
    width: "14px !important",
    '&:hover': {
      backgroundColor: '#BAC2E0',
    }
  },
  customResetIconMain: {
    width: 30,
    height: 18,
    marginLeft: 12,
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#312e40",
    cursor: "pointer",
    textDecoration: "underline",
    marginTop: '10px',
  },
  buttonCampaign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '10px 20px',
    border: 'solid 1px #d9e1ee',
    color: '#171b59',
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
    "@media  (maxWidth: 1500px)": {
      display: 'none',
    },
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    textTransform: 'none',
    width: 167,
    height: 36
  },
  filtersGrid: {
    display: 'flex',
    width: '100%',
    padding: '22px 22px 21px 18px'

  },
  saveButtonContainer: { 
    padding: '20px 0'
  }, 
  ProductPopover: {
    margin: '9px 12px 8px 7px',
    border: "solid 1px #d9e1ee",
    width: 273
  },
  filtersControlsContainer: {
    paddingTop: 9,
    marginRight: 10
  },
  projectModel: {
    "& .MuiTypography-root": {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "18px",
      color: "#3C8EAF",
      textTransform: "uppercase"
    }
  },
  infoCard: {
    minHeight: 152,
    gridColumn: 1,
    display: 'flex',
    flexGrow: 0,
    padding: "17px 0 24px 24px",
    paddingBottom: 0,
    border: "1px solid #D9E1EE",
    borderRadius: 6,
    marginLeft: 8,
    "& h6": {
      color: '#171B59',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: 0.15,
      textTransform: "capitalize",
      paddingLeft: 5,
      paddingTop: 8
    }
  },
  infoCardMd: {
    minHeight: "unset",
    height: 104,
    padding: "12px 6px 10px 14px",
    paddingBottom: 0,
  },
  infoCardHelper: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: "12px",
    color: "#171B5960",
    paddingLeft: 3
  },
  infoCardHelperText: {
    paddingTop: 12,
    paddingLeft: 35
  },
  infoCardIcon: {
    borderRadius: "50%",
    background: "rgba(133, 212, 178, 0.27)",
    width: 12,
    height: 12,
    textAlign: "center"
  },
  bgDark: {
      borderRadius: 6,
      color: '#232033',        
      background: "#F9FAFB",
      paddingLeft: 12,
      height: 48,
      borderRadiusTopLeft: 6,
      borderRadiusTopRight: 6,
      "& .MuiTypography-root": {
        paddingLeft: 8,
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "24px",
      }
  },
  bgDarkShrinked: {
      borderRadius: 6,
      height: 27
  },
  filtersContianer: {
    background: "#FFF",
    borderRadius: 6,
    padding: 12,
    border: "1px solid #ECF1F9",
    "& .table-title--xs": {
      maxWidth: "60%",
      flexBasis: "60%",
    },
    "& .table-title--sm": {
      width: "15%",
      flexBasis: "15%",
    },
    "& .table-title--md": {
      width: "25%",
      flexBasis: "25%",
    },
  },

}));

const ApiService = new BookAnalysisService()

export default function HeaderRun({ clear, setClear, head = true, showTotal = true, broker = false }) {
  const defaultAgeFilter = {"All": true, "<18": false, "18-25": false, "25-35": false, "35-50": false, "50-65": false, "65-80": false, "80+": false}
  const [defaultFaceAmountFilter, setDefaultFaceAmountFilter] = useState(
    {"All": true}
  )
  const [defaultProductFilter, setDefaultProductFilter] = useState(
    {"All": true}
  )  
  const [defaultMarketLagFilter, setDefaultMarketLagFilter] = useState(
    {"All": true}
  )
  const [defaultBehavioralFilter, setDefaultBehavioralFilter] = useState(
    {"All": true}
  )
  const [defaultStatusFilter, setDefaultStatusFilter] = useState(
    {"All": true}
  )
  const [defaultDurationFilter, setDefaultDurationFilter] = useState(
    {"All": true}
  )

  const classes = useStyles();
  const run = useSelector(selectRun)
  const dispatch = useDispatch()
  const history = useHistory()
  const columns = useSelector(selectColumns)
  const faceAmountRanges = useSelector(selectFaceAmountRanges)
  const productValues = useSelector(selectProductValues)
  const behavioralClusterValues = useSelector(selectBehavioralClusterValues)
  const statusValues = useSelector(selectStatusValues)
  const marketLag = useSelector(selectMarketLag)
  const durationValues = useSelector(selectDurationValues)
  const subscriptions = useSelector(selectUserSubscriptions)
  const defaultPrecision = run.model === "lapsev2" ? { "high": false, "mid": true, "low": false } : { "high+": false, "high": false, "mid": true, "low": false }
  const currency = getCountryCurrencySymbol()
  const [ defaultPremiumUIFilter, setDefaultPremiumUIFilter ] = useState({ "All": true, [`<${currency}500`]: false, [`${currency}500-${currency}1500`]: false, [`${currency}1500-${currency}2500`]: false, [`${currency}2500-${currency}5K`]: false, [`${currency}5K-${currency}10K`]: false, [`${currency}10K`]: false })
  const [ defaultFaceAmountLapseFilter, setDefaultFaceAmountLapseFilter ] = useState({ "All": true })
  const [ openPrecision, setOpenPrecision ] = useState(false)
  const [ openColumns, setOpenColumns ] = useState(false)
  const [ tmpColumns, setTmpColumns ] = useState(null)
  const isLapse = run?.model?.toLowerCase()?.replace("v2", "") === "lapse"
  const isCluster = run?.model?.toLowerCase() === "cluster"
  const isAnnuities = localStorage.getItem("accountType") === "annuities"
  const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;
  const isFreeSubcriptionPlan = isBroker && !subscriptions?.find(({ name }) => name === "basic")

  const rootRef = useRef();

  const onChangeColumns = (item, value) => {
    dispatch(setColumns({
      ...columns,
      [item]: value
    }))
  }

  const discardChangeColumns = () => {
    dispatch(setColumns(tmpColumns))
    setOpenColumns(false)
  }

  const applyColumns = () => {
    const params = history.location.search?.replace("?", "").split("&")
    const runid = params[0]
    ApiService._patchRunColumn(
      [runid],
      Object.keys(columns)?.filter((item) => columns[item]),
      () => {
        setTmpColumns(columns)
        setOpenColumns(false)
      },
      () => {}
    )
  }

  const backToProjects = () => {
    dispatch(setUIFilter([0, 0]))
    dispatch(resetRun())
    dispatch(resetFilters())
  }

  const handleResetExstingDataFilters =  (e) => {
    setClear({
      ...clear,
      existing: true
    })
    dispatch(resetExistingFilters());
  }

  const handleResetAtidotRecomendsFilters =  (e) => {
    setClear({
      ...clear,
      recomended: true,
      status: false
    })
    dispatch(resetRecomended());
  }

  const handleResetPolicyStatusFilters =  (e) => {
    setClear({
      ...clear,
      status: true
    })
    dispatch(resetStatus());
  }

  const handleCreateCampaignClick = () => {
    history.push(`/NewCampaign?run_ids=${history.location.search?.replace("?", "").replace("&demo=true", "")}`)
  }

  useEffect(() => {
    return () => {
      setTmpColumns(null)
    }
  }, [])

  useEffect(() => {
    if (!tmpColumns) {
      setTmpColumns(columns)
    }
  }, [tmpColumns, columns])

  useEffect(() => {
    dispatch(resetValues())
  }, [dispatch])
  
  useEffect(() => {
    if(faceAmountRanges && Object.keys(defaultFaceAmountFilter)?.length === 1) {     
      let faceAmount = {
        "All": true
      }
      Object.assign(faceAmount, {["<" + nFormatter(faceAmountRanges[0], 0, currency)]: false})
      faceAmountRanges.forEach(
          (range, index) => {
            if(index !== 0 && index !== faceAmountRanges.length-1) {
              Object.assign(faceAmount, {[
                nFormatter(faceAmountRanges[index-1], 0, currency)
                + "-" + nFormatter(range, 0, currency)
                    ]: false})
            }
         }
      )
      Object.assign(faceAmount, {["<" + nFormatter(faceAmountRanges[faceAmountRanges.length-1], 0, currency)+"<"]: false})
      setDefaultFaceAmountFilter(faceAmount)
    }
  }, [faceAmountRanges])  

  useEffect(() => {
    if(behavioralClusterValues?.length && Object.keys(defaultBehavioralFilter)?.length === 1) {        
      const behavioralCluster = {
        "All": true
      }
      behavioralClusterValues.forEach((item) => Object.assign(behavioralCluster, {[item]: false}))
      setDefaultBehavioralFilter(behavioralCluster)
    }
  }, [behavioralClusterValues])

  useEffect(() => {
    if(productValues && Object.keys(defaultProductFilter)?.length === 1) {
      const product = {
        "All": true
      }
      productValues?.forEach((item) => Object.assign(product, {[item]: false}))
      setDefaultProductFilter(product)
    }
  }, [productValues])

  useEffect(() => {
    if(marketLag && Object.keys(defaultMarketLagFilter)?.length === 1) {
      const lag = {
        "All": true
      }
      marketLag?.forEach((item) => Object.assign(lag, {[item]: false}))
      setDefaultMarketLagFilter(lag)
    }
  }, [marketLag])

  useEffect(() => {
    if(statusValues && Object.keys(defaultStatusFilter)?.length === 1) {
      const status = {
        "All": true
      }
      statusValues?.forEach((item) => Object.assign(status, {[item]: false}))
      setDefaultStatusFilter(status)
    }
  }, [statusValues])

  useEffect(() => {
    if(durationValues && Object.keys(defaultDurationFilter)?.length === 1) {
      const segments = {
        "All": true
      }
      durationValues?.forEach((item) => Object.assign(segments, {[item]: false}))
      setDefaultDurationFilter(segments)
    }
  }, [durationValues])

  useEffect(() => {
    const height = rootRef.current.clientHeight
    dispatch(setHeaderHeight(height))
  }, [dispatch])

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        backToProjects()
      }
    };
  }, [history]);

  return (
    <>
      <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.projectInfoContainer} ref={rootRef}>
        
        {head && <HeaderContainer title={`${isCluster ? "Cluster" : "Run"} Report`}>
          <HeaderReport run={run} titles={
            isCluster
            ? ["Run Date", "Prospects"] :
            ["Run Date", "Prediction Start Date", "Prediction Period", "Prospects", `5y ${isAnnuities ? "AUM" : "Premium"} Upside`]
          }/>
        </HeaderContainer>}

        <Grid container className={classes.filtersContianer}>
          {showTotal && <ProspectsTotal
            count={run.underinsured}
            premium={run.policiesActual}
            disabled={!run?.underinsured || history.location.search?.includes("demo=true") || !checkPermission('create_campaign')}
            onCreateCampaignClick={handleCreateCampaignClick}
            accuracy={isLapse}
            onAccuracyClick={() => setOpenPrecision(true)}
            model={run?.model}
          />}

          <FiltersContianer
            title="Existing Policy Data"
            size={broker ? "full-width": "xs"}
            onReset={handleResetExstingDataFilters}
          >
            <Grid container>
                      {columns["Product Type"] && <SelectFilter
                        title="Product Type"
                        popoverText="Product Type"
                        defaultValue={defaultProductFilter}
                        reset={clear.existing}
                        resetFilter={
                          () => {
                            dispatch(setProductFilter(defaultProductFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setProductFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        dynamic
                        />}
                        
                      {columns["Product Name"] && <SelectFilter
                        title="Product Name"
                        popoverText="Product Name"
                        defaultValue={defaultMarketLagFilter}
                        reset={clear.existing}
                        resetFilter={
                          () => {
                            dispatch(setProductNameFilter(defaultMarketLagFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setProductNameFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        dynamic
                        />}
                            
                      {columns["Current Age"] && <SelectFilter
                        title="Current Age"
                        popoverText="Current Age"
                        defaultValue={defaultAgeFilter}
                        reset={clear.existing}
                        resetFilter={
                          (e) => {
                            dispatch(setAgeAtIssueFilter(defaultAgeFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setAgeAtIssueFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        />}
                        
                        {columns["Age"] && <SelectFilter
                        title="Age"
                        popoverText="Age"
                        defaultValue={defaultAgeFilter}
                        reset={clear.existing}
                        resetFilter={
                          (e) => {
                            dispatch(setAgeFilter(defaultAgeFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setAgeFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        />}                                             
                      
                      {columns["Annual Premium"]  && !isCluster && <SelectFilter 
                        title="Annual Premium"
                        popoverText="Annual Premium"
                        defaultValue={defaultPremiumUIFilter}
                        reset={clear.existing}
                        resetFilter={
                          () => {
                            dispatch(setAnnualizedPremiumFilter(defaultPremiumUIFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setAnnualizedPremiumFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}  
                        />} 
                        
                      {columns["Face Amount"] && !isCluster && <SelectFilter
                      title="Face Amount"
                      popoverText="face amount"
                      defaultValue={defaultFaceAmountFilter}
                      reset={clear.existing}
                      resetFilter={
                        () => {
                          setDefaultFaceAmountLapseFilter(defaultFaceAmountFilter)
                          dispatch(setUIFaceAmountFilter(defaultFaceAmountFilter))
                        }
                      }
                      onChange={(value) => {
                        dispatch(setUIFaceAmountFilter(value))
                      }}
                      disabled={isFreeSubcriptionPlan}
                      dynamic
                      />}  

                      {columns["Contract Value"] && <SelectFilter
                        title="Contract Value"
                        popoverText="Contract Value"
                        defaultValue={defaultContractValueFilter}
                        reset={clear.existing}
                        resetFilter={
                          () => {
                            dispatch(setContractValueFilter(defaultContractValueFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setContractValueFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        />} 

                      {columns["Surrender Value"] && <SelectFilter
                        title="Surrender Value"
                        popoverText="Surrender Value"
                        defaultValue={defaultContractValueFilter}
                        reset={clear.existing}
                        resetFilter={() => dispatch(setSurrValueFilter(defaultContractValueFilter))}
                        onChange={(value) => { dispatch(setSurrValueFilter(value)) }}
                        disabled={isFreeSubcriptionPlan}
                        />}  

                      {columns["Years Left in Surrender Charge"] && <SelectFilter
                        title="Years in Surrender Charge"
                        popoverText="Years in Surrender Charge"
                        defaultValue={defaultYearsFilter}
                        reset={clear.existing}
                        resetFilter={() => dispatch(setSurrYearValueFilter(defaultYearsFilter))}
                        onChange={(value) => dispatch(setSurrYearValueFilter(value))}
                        disabled={isFreeSubcriptionPlan}
                        />}

                    {columns["Orphan"] && <SelectFilter
                      title="Orphan"
                      popoverText="Orphan"
                      defaultValue={defaultOrphanFilter}
                      reset={clear}
                      resetFilter={
                        () => {
                          dispatch(setOrphanFilter(defaultOrphanFilter))
                        }
                      }
                      onChange={(value) => {
                        dispatch(setOrphanFilter(value))
                      }}
                      disabled={isFreeSubcriptionPlan}
                      />}   
                      
                      {!isAnnuities && !isCluster && <SelectFilter 
                          title="Policy Duration"
                          popoverText="Policy Duration"
                          defaultValue={defaultDurationFilter}
                          reset={clear.existing}
                          resetFilter={
                            () => {
                              dispatch(setDurationSegmentFilter(defaultDurationFilter))
                            }
                          }
                          onChange={(value) => {
                            dispatch(setDurationSegmentFilter(value))
                          }} 
                          disabled={isFreeSubcriptionPlan}
                          dynamic 
                          />}                        
                        
                      {!isCluster && <SelectFilter
                          title="Gender"
                          popoverText="Gender"
                          defaultValue={defaultGenderFilter}
                          reset={clear.existing}
                          resetFilter={() => dispatch(setGenderFilter(defaultGenderFilter))}
                          onChange={(value) => { dispatch(setGenderFilter(value)) }}
                          disabled={isFreeSubcriptionPlan}
                          />}                             
            </Grid>
          </FiltersContianer>

          {!broker && <FiltersContianer
            title={isAnnuities ? "Model Results" : "Atidot Recommends"}
            size="md"
            onReset={handleResetAtidotRecomendsFilters}
            openSettings={isCluster || !showTotal ? null : () => setOpenColumns(true)}
          >
              <Grid container>
                      {columns["Upside Story"] && <SelectFilter
                        title="Upside Story"
                        popoverText="Upside Story"
                        defaultValue={defaultBehavioralFilter}
                        reset={clear.recomended}
                        resetFilter={
                          () => {
                            dispatch(setStoryFilter(defaultBehavioralFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setStoryFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        dynamic
                        />}

                      {columns["Cluster"] && <SelectFilter
                        title="Cluster"
                        popoverText="Cluster"
                        defaultValue={defaultBehavioralFilter}
                        reset={clear.recomended}
                        resetFilter={
                          () => {
                            dispatch(setStoryFilter(defaultBehavioralFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setStoryFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        dynamic
                        />}                    

                      {columns["AUM Upside"] && <SelectFilter
                        title="AUM Upside"
                        popoverText="AUM Upside"
                        defaultValue={defaultContractValueFilter}
                        reset={clear.recomended}
                        resetFilter={
                          () => {
                            dispatch(isLapse ? setLapsePremiumFilter(defaultContractValueFilter) : setPremiumUIFilter(defaultContractValueFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(isLapse ? setLapsePremiumFilter(value) : setPremiumUIFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        dynamic
                        />}  
                      {(columns["Premium Upside"] && !isLapse) && <SelectFilter
                        title="Premium Upside"
                        popoverText="Premium Upside"
                        defaultValue={defaultPremiumUIFilter}
                        reset={clear.recomended}
                        resetFilter={
                          () => {
                            dispatch(isLapse ? setPremiumFilter(defaultPremiumUIFilter) : setPremiumUIFilter(defaultPremiumUIFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(isLapse ? setPremiumFilter(value) : setPremiumUIFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        />}
                      
                      {(columns["Probability to lapse"] && isLapse) && <SelectFilter
                        title="Probability to Lapse"
                        popoverText="Probability to Lapse"
                        defaultValue={defaultPriorityFilter}
                        icons={{
                          "high": <PriorityIndicator priority="high"/>,
                          "medium": <PriorityIndicator priority="mid"/>,
                          "low": <PriorityIndicator priority="low"/>,
                        }}
                        reset={clear.recomended}
                        resetFilter={
                          () => {
                            dispatch(setLapseScoreFilter(defaultPriorityFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setLapseScoreFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        />}

                      {(columns["Premium Sensitivity"] && isLapse) && <SelectFilter
                        title="Premium Sensitivity"
                        popoverText="Premium Sensitivity"
                        defaultValue={defaultSensetivityFilter}
                        icons={{
                          "high": <PriorityIndicator priority={3}/>,
                          "medium": <PriorityIndicator priority={2}/>,
                          "low": <PriorityIndicator priority={1}/>,
                        }}
                        reset={clear.recomended}
                        resetFilter={
                          () => {
                            dispatch(setSensetivityFilter(defaultSensetivityFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setSensetivityFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        />}

                      {(columns["Surrender Probability"] && isLapse) && <SelectFilter
                        title="Surrender Probability"
                        popoverText="Surrender Probability"
                        defaultValue={defaultPriorityFilter}
                        icons={{
                          "high": <PriorityIndicator priority="high"/>,
                          "medium": <PriorityIndicator priority="mid"/>,
                          "low": <PriorityIndicator priority="low"/>,
                        }}
                        reset={clear.recomended}
                        resetFilter={
                          () => {
                            dispatch(setPriorityFilter(defaultPriorityFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setPriorityFilter(value))
                        }}
                        disabled={isFreeSubcriptionPlan}
                        />}
              </Grid>
          </FiltersContianer>}

          {!broker && <FiltersContianer
            title={!isCluster ? "Policy Status" : ""}
            size="sm"
          >
          {columns["Status"] && <SelectFilter
            title="Status"
            popoverText="Status"
            defaultValue={defaultStatusFilter}
            reset={clear.recomended}
            resetFilter={
              () => {
                dispatch(setStatusFilter(defaultStatusFilter))
              }
            }
            onChange={(value) => {
              dispatch(setStatusFilter(value))
            }}
            disabled={isFreeSubcriptionPlan}
            dynamic
            />}

          </FiltersContianer>}
        </Grid>

        <ColumnsPopup
              open={openColumns}
              options={columns}
              handleChange={onChangeColumns}
              handleClose={discardChangeColumns}
              handleApply={applyColumns}
            />

        {isLapse && <PrecisionPopup
              open={openPrecision}
              onClose={() => setOpenPrecision(!openPrecision)}
              defaultValue={defaultPrecision}
              details={run}
          /> }   
      </Grid>
    </>
  )
}