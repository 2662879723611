import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import LimitedDigitsInput from "../LimitedDigitsInput";
import  CloseIcon from '@mui/icons-material/Close';
import { UIViewButton } from "../../ui/buttons";

const useStyles = makeStyles(() => ({
    formRoot: {
        borderRadius: 6,
        width: 460,
    },
    buttonContainer:{
        textAlign: "center",
        paddingTop: 25
    },
    buttonBlock:{
        background: '#FB4E0B',
        width: 248,
        height: 55,
        borderRadius: 8,
        boxShadow: "none",
        textTransform: 'capitalize',
        textAlign: "center",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "19px",
        '&:hover': {
            opacity: '0.85',
            backgroundColor: '#FB4E0B',
            boxShadow: "none",
        },
        '&:active': {
          backgroundColor: '#994923'
        },
        '&.Mui-disabled': {
          backgroundColor: '#c4c4c4e5',
          color: '#FFFFFF'
        },
    },
    cardTitle: {
        color: "#312E40",
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 24,
        fontWeight: 400,
        paddingBottom: 32,
        paddingTop: 32
    },
    resendText:{
        color: '#312E40',
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "120%",
        paddingTop: 32,
        '& span': {
            color: '#363ED3',
            fontWeight: 700,
            cursor: "pointer",
            textDecoration: "underline"
        },
        '& a': {
            color: '#363ED3',
            fontWeight: 700,
            cursor: "pointer",
            textDecoration: "underline"
        }
    },
    alert: {
        '& .MuiAlert-outlinedError': {
            color: "#d32f2f",
            border: "none",
            padding: 0,
            justifyContent: "center"
        },
        paddingTop: 12
    },
    resendDialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: "rgba(248, 249, 251, 0.7)"
        },
        '& .MuiDialog-paperScrollPaper': {
            maxWidth: 966,
            textAlign: "center",
            margin: 0,
            padding: "51px 0",
            border: "1px solid #EBEBEB",
            borderRadius: 6,
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)"
        },
        '& .MuiDialogActions-root': {
            justifyContent: "center"
        },
        '& h2': {
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: 32,
            lineHeight: "32px",
            color: "#312E40"
        },
        '& p': {
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
            color: "#312E40"
        }
    },
    closeButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        position: "absolute",
        top: 16,
        right: 36,
        width: 29,
        height: 29,
        "& svg": {
            width: 16,
            height: 16
        }
    }
}))

const CodeConfirm = ({ 
    onConfirm, 
    onResend, 
    timeToExpire, 
    codeLength = 6, 
    confirmText = "Continue",
    errorMessage
}) => {
    const classes = useStyles()
    const history = useHistory()
    const formRef = useRef(null)
    const expireTime = new Date();
    const [code, setCode] = useState("")
    const [openResend, setOpenResend] = useState(false)

    useEffect(() => {
        const date = new Date();
        if (history.location === "/confirmaccount") {
            const params = history.location.search
                .replace("?")
                .split("&")
            const elapsedTime = date.getTime() - params[1].split("=")[1]
            expireTime.setTime(elapsedTime)
        } else {
            const elapsedTime = date.getTime() - history.location.search.replace("?", "")
            expireTime.setTime(elapsedTime)
        }
    }, [history.location.search])

    function handleConfirm() {
        onConfirm(code)
    }
    
    const handleResendCode = () => {
        onResend(() => { 
            setCode("")
            setOpenResend(true)
        })
    }
        
    const handleCloseResendDialog = () => {
        setOpenResend(false)
    }

    return (
            <Grid container justifyContent="center" alignContent="center" direction="column">
                <Typography align="center" className={classes.cardTitle}>Please insert the code sent to your mail</Typography>
                <Grid container justifyContent="center">
                    <ValidatorForm ref={formRef} onSubmit={handleConfirm}>
                        <Grid container direction="column" className={classes.formRoot}>
                            {!openResend && <LimitedDigitsInput 
                                digits={codeLength}
                                onChange={setCode}/>
                            }
                            {timeToExpire(expireTime) ?
                            <Grid item>
                                <Typography align="center" className={classes.resendText}>
                                    Didn't receive the email? <span onClick={handleResendCode}>Send one more time.</span>
                                </Typography>
                            </Grid>
                            : 
                            <Grid item>
                                <Typography align="center" className={classes.resendText}>
                                    Your confirmation code has expired. <span onClick={handleResendCode}>Re-send the code?</span>
                                </Typography>
                            </Grid>
                            }
                            {errorMessage && <Grid item justifyContent="center" className={classes.alert}>
                                
                                    <Alert variant="outlined" severity="error">
                                        <AlertTitle>{errorMessage}</AlertTitle>
                                    </Alert>
                                    
                            </Grid>} 
                        </Grid>                
                        <Grid item className={classes.buttonContainer}  justifyContent="center" alignContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.buttonBlock}
                                data-testid="loginButton"
                                disabled={code?.length < codeLength}
                                >
                                {confirmText}
                            </Button>
                        </Grid>
                        <Typography align="center" className={classes.resendText}>
                            By clicking {confirmText} you agree to our  <a href="https://www.atidot.com/privacy-notice/">Terms & Conditions</a>
                        </Typography>                
                    </ValidatorForm>
                </Grid>
                <Dialog
                fullWidth
                open={openResend}
                onClose={handleCloseResendDialog}
                className={classes.resendDialog}
                justifyContent="center"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >            
                    <div className={classes.closeButton} onClick={handleCloseResendDialog}>
                        <CloseIcon/>
                    </div>
                    <DialogTitle id="alert-dialog-title">
                        Additional email has been sent to your mail
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please use your latest email.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <UIViewButton onClick={handleCloseResendDialog} autoFocus>
                            Ok
                        </UIViewButton>
                    </DialogActions>
                </Dialog>                
            </Grid>
    )
}

export default CodeConfirm