import React, { useEffect, useRef, useState } from "react";
import { timeSelectStyles, useStyles } from "./styles";
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Typography } from "@material-ui/core";
import FormInput from "../../Components/Forms/FormInput";
import { UIViewButton } from "../../Components/ui/buttons";
import { User } from "../../global/user";
import schedulerApiService from "../Scheduler/SchedulerApiService";
import InformationPopup from "../../Components/Popups/InformationPopup/InformationPopup";
import { Alert } from "@mui/material";
import { SelectValidator, ValidatorComponent, ValidatorForm } from "react-material-ui-form-validator"; 
import { useHistory } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../Assets/icon-plus.svg";
import { ReactComponent as MinusIcon } from "../../Assets/icon-minus-alt.svg";
import { ReactComponent as Check } from '../../Assets/icon-checked-flat.svg';
import { ReactComponent as Box } from '../../Assets/icon-unchecked-flat.svg';
import TimeSelect from "./TimeSelect";

const ApiService = new schedulerApiService()

const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
const defaultRange = [{
    "start": "",
    "end": "",
}]

const hoursOpitions = [
    {  value: "6:00", label: "6:00AM" },
    {  value: "6:30", label: "6:30AM" },
    {  value: "7:00", label: "7:00AM" },
    {  value: "7:30", label: "7:30AM" },
    {  value: "8:00", label: "8:00AM" },
    {  value: "8:30", label: "8:30AM" },
    {  value: "9:00", label: "9:00AM" },
    {  value: "9:30", label: "9:30AM" },
    {  value: "10:00", label: "10:00AM" },
    {  value: "10:30", label: "10:30AM" },
    {  value: "11:00", label: "11:00AM" },
    {  value: "11:30", label: "11:30AM" },
    {  value: "12:00", label: "12:00AM" },
    {  value: "12:30", label: "00:30PM" },
    {  value: "13:00", label: "1:00PM" },
    {  value: "13:30", label: "1:30PM" },
    {  value: "14:00", label: "2:00PM" },
    {  value: "14:30", label: "2:30PM" },
    {  value: "15:00", label: "3:00PM" },
    {  value: "15:30", label: "3:30PM" },
    {  value: "16:00", label: "4:00PM" },
    {  value: "16:30", label: "4:30PM" },
    {  value: "17:00", label: "5:00PM" },
    {  value: "17:30", label: "5:30PM" },
    {  value: "18:00", label: "6:00PM" },
    {  value: "18:30", label: "6:30PM" },
    {  value: "19:00", label: "7:00PM" },
    {  value: "19:30", label: "7:30PM" },
    {  value: "20:00", label: "8:00PM" },
    {  value: "20:30", label: "8:30PM" },
    {  value: "21:00", label: "9:00PM" },
    {  value: "21:30", label: "9:30PM" },
    {  value: "22:00", label: "10:00PM" },
]

const getValidatedOpition = (option, value) => {
    if (value) {
        const optionParams = option.split(":")
        const valueParams = value.split(":")
        const optionScale = parseInt(optionParams[0]) + (parseInt(optionParams[1])/60)
        const valueScale = parseInt(valueParams[0]) + (parseInt(valueParams[1])/60)
        return optionScale > valueScale
    }
    return true
}

const getTimeRangeByDayOfWeek = (rangesArray, dayOfWeek) => {
    const filteredRanges = rangesArray?.filter(({ day }) => day === dayOfWeek).map((i) => {
        return {
            ...i,
            batch_id: crypto.randomUUID(),
        }
    })
    return filteredRanges.length ? filteredRanges : defaultRange
}

const SchedulerConfig = () => {
    const classes = useStyles()
    const history = useHistory()
    const { currentUser } = User()
    const formRef = useRef()
    const [ loading, setLoading ] = useState(false)
    const [ loadingPrivew, setLoadingPrivew ] = useState(false)
    const [ agent, setAgent ] = useState(null)
    const [ disabledWorkingHours, setDisabledWorkingHours ] = useState(false)
    const [ workingHoursChanged, setWorkingHoursChanged ] = useState(false)
    const [ workingHours, setWorkingHours ] = useState({
        monday: defaultRange,
        tuesday: defaultRange,
        wednesday: defaultRange,
        thursday: defaultRange,
        friday: defaultRange,
    })
    const [ name, setName ] = useState("")
    const [ preview, setPrivew ] = useState("")
    const [ data, setData ] = useState([])
    const [ deleteItems, setDeleteItems] = useState([])
    const [ connected, setConnected ] = useState(false)
    const [ alertOpen, setAlertOpen ] = useState(false)
    const [ alertMessage, setAlertMessage ] = useState('')
    const [ status, setStatus ] = useState('')
    const [ error, setError ] = useState('')
    const [ email, setEmail ] = useState(currentUser['email'])
    const [ disabled, setDisabled ] = useState(false)
    const [ days, setDays ] = useState({
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
    })

    const handleChangeName = (e) => setName(e.target.value)

    const handleSaveName = () => {
        ApiService._patchShedulerAccount(agent.id, { name },
            () => {
                ApiService._getShedulerAccount(
                currentUser['email'], (response) => {
                    setAgent(response.data.user)
                }, (error) => {
                    console.log(error)
                })
            },
            (error) => console.log(error),
        )
    }
    
    const handleChangeDays = (e) => {
        const values = workingHours[e.target.name]
        const targetSlots = values.filter(({ id }) => id)
        if (!e.target.checked && targetSlots?.length) {                
            setDeleteItems([
            ...deleteItems,
            ...targetSlots.map((({ id }) => id)).filter((item) => !deleteItems.includes(item))
            ])
            setData([
                ...data, 
                ...values.map((item) => {
                    return {
                    ...item,
                    day: item.day !== undefined ? item.day : daysOfWeek.indexOf(e.target.name),
                    } 
                })
            ])
            setWorkingHoursChanged(true)
        } else  if (!targetSlots?.length) {
            const newValue = data.filter(({ day }) => day !== daysOfWeek.indexOf(e.target.name))
            setWorkingHoursChanged(newValue.length > 0)
            setData([...newValue])
        }
        if (e.target.checked && !targetSlots?.length) {
            setWorkingHours({
                ...workingHours,
                [e.target.name]: [
                    {
                        batch_id: crypto.randomUUID(),
                        day: daysOfWeek.indexOf(e.target.name),
                        start: '',
                        end: '',
                    }
                ]
            })
            
        }
        setDays({
            ...days,
            [e.target.name]: e.target.checked
        })
    }

    const handleAddTimeRange = (name) => {
        setWorkingHours({
            ...workingHours,
            [name]: [
                ...workingHours[name],
                {
                    batch_id: crypto.randomUUID(),
                    day: daysOfWeek.indexOf(name),
                    start: '',
                    end: '',
                }
            ]
        })
    }
    
    const handleRemoveTimeRange = (name) => {
        if (workingHours[name][1]?.id && !deleteItems.includes(workingHours[name][1].id)) {       
            setWorkingHoursChanged(true)  
            setDeleteItems([
            ...deleteItems,
            workingHours[name][1]?.id
            ]) 
            setData([
                ...data,
                workingHours[name][1]
            ])
        } else {       
            setWorkingHoursChanged(false)
        }
        setWorkingHours({
            ...workingHours,
            [name]: [workingHours[name][0]]
        })
    }
    
    const handleChangeWorkingHours = (e, name, key, id) => {
        const remainder = id === 0 ? workingHours[name][1] : workingHours[name][0]
        const newRange = 
        workingHours[name].length === 1 ?
        [
            {
                ...workingHours[name][0],
                [key]: e.value
            }
        ]
        : id === 0 ?
        [
            {
                ...workingHours[name][0],
                [key]: e.value
            },
            remainder
        ]
        :
        [
            remainder,
            {
                ...workingHours[name][1],
                [key]: e.value
            }
        ]
        setWorkingHours({
            ...workingHours,
            [name]: newRange
        })
        const values = [...data]
        newRange.forEach((item) => {
            const itemId  = data.findIndex(({ batch_id }) => batch_id === workingHours[name][id].batch_id)
            if (itemId > -1) {
                const prevValue = data[itemId]
                values.splice(itemId, 1, {
                    ...item,
                    day: prevValue.day !== undefined ? prevValue.day : daysOfWeek.indexOf(name),
                })
            } else {
                values.push({
                    ...item,
                    day: item.day !== undefined ? item.day : daysOfWeek.indexOf(name),
                })}
        })
        
        setData(values)
        setDisabledWorkingHours(false)
        setWorkingHoursChanged(true)
    }

    const handleSaveWorkingHoursSuccess = () => {
        setData([])
        setWorkingHoursChanged(false)
        setDisabledWorkingHours(false)
        setStatus('success')
        setAlertMessage('Your working schedule is updated.')
        setAlertOpen(true)            
    }

    const handleSaveWorkingHoursError = (error) => {
        console.log(error)
        if (error.response.status !== 500) {
            setDisabledWorkingHours(false)
            setStatus('error');
            setError('Sorry could not update your working schedule!');
            setAlertOpen(true);
        }
    }

    const handleSaveWorkingHours = () => {
        setDisabledWorkingHours(true)
        data.forEach((slot, index) => {
            if(slot.id) {
                if (deleteItems.includes(slot.id)) {
                    ApiService._deleteWorkingHours(
                        slot.id,
                        () => {
                            if (index+1 === data.length) {
                                handleSaveWorkingHoursSuccess()
                            }
                        },
                        (error) => handleSaveWorkingHoursError(error)
                    )
                } else {
                    ApiService._patchWorkingHours(
                        slot.id, {
                            day: slot.day,
                            start: slot.start,
                            end: slot.end,
                        },
                        () => {
                            if (index+1 === data.length) {
                                handleSaveWorkingHoursSuccess()
                            }
                        },
                        (error) => handleSaveWorkingHoursError(error)
                    )
                }
            } else {
                ApiService._postWorkingHours(
                    agent.id, {
                        day: slot.day,
                        start: slot.start,
                        end: slot.end,
                    },
                    () => {
                        if (index+1 === data.length) {
                            handleSaveWorkingHoursSuccess()
                        }
                    },
                    (error) => handleSaveWorkingHoursError(error)
                )
            }
        })
    }

    const handleSubmit = () => {
        setDisabled(true)
        ApiService._postCreateShedulerAccount(email, currentUser['email'], (response) => {
            setDisabled(false)
            setConnected(true)
            window.open(response.data);
        }, (error) => {
            setDisabled(false)
            if(error.response.data.error) {
                setError(error.response.data.error)
            } else {
                setError("Error, couldn't connect your account! Please try again later.")
            }
            setStatus('error')
            setAlertOpen(true)
            console.log(error)
        })
    }

    const handleCloseAlert = () => setAlertOpen(false)
   
    useEffect(() => {
        setLoading(true)
        ApiService._getShedulerAccount(
        currentUser['email'], (response) => {
            setConnected(true)
            setAgent(response.data.user)
            setLoading(false)
        }, (error) => {
            console.log(error)
            setLoading(false)
        })
    }, [])
    
    useEffect(() => {
        if (agent) {
            setName(agent.name)
            ApiService._getWorkingHours(
                agent.id, (response) => {
                    const data = response.data.working_hours
                    const newWorkingHours = {
                        monday: getTimeRangeByDayOfWeek(data, 1),
                        tuesday: getTimeRangeByDayOfWeek(data, 2),
                        wednesday: getTimeRangeByDayOfWeek(data, 3),
                        thursday: getTimeRangeByDayOfWeek(data, 4),
                        friday: getTimeRangeByDayOfWeek(data, 5),
                    }
                    setWorkingHours(newWorkingHours)
                    setDays(Object.assign({}, ...Object.keys(days).map((day) => ({ [day]: newWorkingHours[day][0]?.start }))))
                }, (error) => {
                    console.log(error)
                })
        }
        
    }, [agent])

    useEffect(() => {
        if (history.location.search.includes("code")) {
            ApiService._getOAuthCode(history.location.search?.replace("?code=", ""), () => {
                setStatus('success')
                setAlertMessage('Account connected')
                setAlertOpen(true)
            }, (error) => {
                console.log(error)
            })
        }
    }, [history.location.search])

    return (
        <div>
            <Grid container alignItems="center" className={classes.controlsContainer}>
                {
                loading ?    
                <Grid container justifyContent="center" alignContent="center" style={{minHeight: 600}}>
                    <CircularProgress style={{color: "#005071",width: 96, height: 96}}/>
                </Grid>
                : <>
                    
                    {!connected &&
                        <ValidatorForm ref={formRef} onSubmit={handleSubmit} style={{ width: "100%" }}>
                            <Grid container>
                                <Typography className={classes.tableTitle}>Connect Your Calendar</Typography>
                            </Grid>
                            <Grid container>
                                <Grid item container>
                                    <Typography className={classes.inputTitle}>Email *</Typography>
                                </Grid>
                                <Grid item container justifyContent="space-between">
                                        
                                        <Grid item className={classes.emailInputContainer}>
                                            <FormInput
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                validators={['required', 'isEmail']}
                                                errorMessages={['Email is required', 'Please enter a valid email']}
                                                autoFocus
                                                data-testid="email" />
                                        </Grid>

                                        <UIViewButton
                                                type="submit"
                                                style={{ height: 44, width: "15%", padding: 0 }}
                                                data-testid="submitButton"
                                                disabled={disabled}
                                        >
                                            Connect
                                        </UIViewButton>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    } 

                    {connected && 
                    <Grid container>

                        <Grid container direction="column" className={classes.workingDayContainer}>
                            <Grid container>
                                <Typography className={classes.tableTitle}>Scheduler Meeting Connected to <span>{email}</span></Typography>
                            </Grid>
                            <Grid container>
                                <Typography className={classes.tableTitle}>Work Days</Typography>
                            </Grid>
                            <Grid container>
                                
                                <ValidatorForm ref={formRef} onSubmit={handleSaveWorkingHours} style={{ width: "100%" }}>
                                <FormGroup className={classes.daysContainer}>
                                    {
                                        Object.keys(days)
                                            .map((item, idx) => (
                                            <Grid container>  
                                                <FormControlLabel
                                                control={
                                                <Checkbox 
                                                    className={classes.timeCheckbox}
                                                    checkedIcon={<Check/>}
                                                    icon={<Box/>}
                                                    checked={days[item]}
                                                    key={item}
                                                    name={item}
                                                    onChange={handleChangeDays}
                                                    />
                                                }
                                                label={item.slice(0, 3)}
                                                labelPlacement="end"
                                                />
                                                <Grid item key={`${item}-timeslots`}>
                                                    {workingHours[item].map((range, index) => (
                                                    <div className={classes.timeContainer}>
                                                        <TimeSelect
                                                            className={classes.timeSelect}
                                                            styles={timeSelectStyles}
                                                            key={`${item}-timeslot-start-${index}`}
                                                            validators={days[item] ? ["required"] : []}
                                                            errorMessages={['this field is required']}
                                                            isDisabled={!days[item]}
                                                            onChange={(e) => handleChangeWorkingHours(e, item, "start", index)}
                                                            value={hoursOpitions.find(({ value }) => range.start === value)}
                                                            options={hoursOpitions.filter(({ value }) => index > 0 ? getValidatedOpition(value, workingHours[item][0].end) : true)}
                                                        />
                                                        <div style={{ width: 12 }}/>
                                                        <TimeSelect
                                                            className={classes.timeSelect}
                                                            styles={timeSelectStyles}
                                                            key={`${item}-timeslot-end-${index}`}
                                                            validators={days[item] ? ["required"] : []}
                                                            errorMessages={['this field is required']}
                                                            isDisabled={!days[item]}
                                                            onChange={(e) => handleChangeWorkingHours(e, item, "end", index)}
                                                            value={hoursOpitions.find(({ value }) => range.end === value)}
                                                            options={hoursOpitions.filter(({ value }) => getValidatedOpition(value, range.start))}
                                                        />
                                                        {
                                                            workingHours[item].length < 2 && index+1 === workingHours[item].length ?   
                                                            <IconButton onClick={() => handleAddTimeRange(item)} disabled={!days[item]}>
                                                                <PlusIcon/>
                                                            </IconButton> : index === 0 && <div style={{ minWidth: 39 }}/>
                                                        }
                                                        {
                                                            workingHours[item].length > 1 && index+1 === workingHours[item].length &&    
                                                            <IconButton onClick={() => handleRemoveTimeRange(item)}>
                                                                <MinusIcon/>
                                                            </IconButton>
                                                        }
                                                        {
                                                            idx+1 === Object.keys(days)?.length && index+1 === workingHours[item].length &&
                                                            <Grid style={{ position: "absolute" }}>
                                                                <UIViewButton
                                                                        style={{ height: 44, width: 120, padding: 0, top: 54, left: 141, position: "absolute" }}
                                                                        data-testid="saveWorkingHoursButton"
                                                                        type="submit"
                                                                        disabled={disabledWorkingHours || !workingHoursChanged}
                                                                >
                                                                    Save
                                                                </UIViewButton>
                                                            </Grid>
                                                        }
                                                    </div>    
                                                    ))

                                                    }
                                                </Grid>

                                            </Grid>
                                            ))
                                    }

                                    
                                </FormGroup>
                                </ValidatorForm>
                            </Grid>

                            
                            <Grid container className={classes.nameContainer}>
                                <Grid container>
                                    <Typography className={classes.tableTitle} style={{ paddingTop: 54 }}>Agent</Typography>
                                </Grid>
                                <Grid container style={{ gap: 12 }}>
                                    <Grid item container className={classes.nameInput} >
                                        <FormInput value={name} onChange={handleChangeName} validate={false}/>
                                    </Grid>
                                    <UIViewButton
                                                style={{ height: 44, width: 120, padding: 0 }}
                                                data-testid="saveWorkingName"
                                                onClick={handleSaveName}
                                                disabled={!(name && agent.name !== name)}
                                        >
                                        Save
                                    </UIViewButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.previewContainer}>
                            <Grid container>
                                <Typography className={classes.tableTitle}>Calendar Preview</Typography>
                            </Grid>
                            {loadingPrivew ?
                                <Grid container justifyContent="center" alignContent="center" style={{minHeight: 600}}>
                                    <CircularProgress style={{color: "#005071",width: 64, height: 64}}/>
                                </Grid>
                                :
                                <Grid container>
                                    <iframe src={`https://${window.location.host === "localhost:3000" ? "dev.app.atidot.co" : window.location.host}/scheduler-preview/${agent.name ? agent.name : "Atidot"}`}  className={classes.previewContent}/> 
                                </Grid>
                            }
                            <Grid container className={classes.exampleContainer}>
                                <Typography className={classes.exampleText}>
                                    {"Public url example: "}
                                    <a target="_blank" href={`https://${window.location.host === "localhost:3000" ? "dev.app.atidot.co" : window.location.host}/scheduler/${agent.id}/eyal@atidot.com`}>
                                    {`${window.location.host === "localhost:3000" ? "dev.app.atidot.co" : window.location.host}/scheduler/${agent.id}/eyal@atidot.com`}
                                    </a>
                                </Typography>
                            </Grid>
                            <Grid container>
                                    <li className={classes.exampleText}>
                                        eyal@atidot.com is an example of lead arrival
                                    </li>
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                </>

                }   
            </Grid>
            <InformationPopup
                open={alertOpen}
                onClose={handleCloseAlert}
                onConfirm={handleCloseAlert}
                closeControl={false}
                confirmText="OK"
                title={status === "success" ? "Success" : "Error"}
            >
                <Alert severity={status} style={{ width: 600 }}>
                    {status === "success" ? alertMessage : error}
                </Alert> 
            </InformationPopup>
        </div>
    )
}

export default SchedulerConfig