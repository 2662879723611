import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    bgDark: {
        borderRadius: 6,
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "24px",
        color: '#232033',        
        background: "#F9FAFB",
        paddingLeft: 14,
        height: 57,
        "& .MuiTypography-root": {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: 700,
          lineHeight: "24px",
        }
    },
    totalsContainer: {
      paddingBottom: 17,
        "& .contacts-text": {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: 700,
          lineHeight: "22px",
          "& span": {
            color: "#005071"
          }
        },
        "& p": {
          margin: 0
        },
        "& .premium-text": {
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "22px",
          color: "#7E7C87"
        },
        "& .MuiIconButton-root": {
          color: "#005071",
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: 500,
          padding: 0,
          borderRadius: "unset",
          "& svg": {
            width: 12,
            height: 12,
          },
          "& path": {
            fill: "#005071"
          }
        },
        "& .MuiDivider-vertical": {
          height: 20,
          margin: "0 17px"
        },
    },
    totalsArrow: {
      width: 12,
      height: 8,
      margin: "0 12px",
      "& path": {
        fill: "#7E7C87"
      }
    },
    searchContainer: {
        maxWidth: 445,
        gap: 12,
        "& .MuiFormControl-fullWidth": {
          width: 270
        },
        "& svg": {
          width: 18,
          height: 18,
          fill: "#005071"
        },
    },
    searchBox: {
        "& .MuiOutlinedInput-input": {
          paddingLeft: 10,
        },
        "& .MuiInputBase-input": {
          fontSize: 12,
        },
        "& .MuiOutlinedInput-adornedStart": {
          paddingLeft: 7,
        },
    },
    totalData: {
        display: "flex",
        "& .page-data": {
          color: "#727264",
          paddingRight: 30,
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "22px",
          "& span": {
            fontWeight: 500,
          }
        }
    },
    tableHeaderTitle: {        
      fontFamily: "Roboto",
      fontSize: 24,
      fontWeight: 400,
      lineHeight: "24px",
      color: "#232033"
    },
    header: {        
      paddingBottom: 16
    },
    tableAE: {
      "& .MuiTableCell-root": {
        padding: "16px 0"
      },
      "& .MuiTableHead-root.MuiTableCell-root": {
        width: "4.4%"
      }
    },
    tableTitle: {
      width: "49%",
      flexBasis: "49%",
      "& svg": {
        marginRight: 18,
        width: 20,
        height: 20,
        cursor: "pointer"
      },
      "& path": {
        fill: "#312E40"
      },
    },
    tableTitleBg: {
      maxWidth: "73%",
      flexBasis: "73%",
      paddingRight: 5,
    },
    tableTitleSm: {
      width: "27%",
      flexBasis: "27%",
      "& svg": {
        marginRight: 18,
        width: 20,
        height: 20,
        cursor: "pointer"
      },
      "& path": {
        fill: "#312E40"
      },
    },
    typeFilterContainer: {
      display: "flex",
      padding: "6px 8px",
      paddingLeft: 38,
      height: 26,
      "& .MuiTypography-root": {
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "14px",
        color: "#232033",
        marginLeft: 10
      }
    },
    showOnPageSelect: {   
      border: "1px solid #DCDCDC",
      borderRadius: 8,
      paddingLeft: 12,
      width: 500,
    },
    typeIcon: {   
      borderRadius: "50%",
      width: 7,
      height: 7,
      cursor: "pointer"
    },
    checkBoxProps: {
      display: "flex",
      flexDirection: "row",
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "14px"
        },
        "& .MuiSvgIcon-root": {
            width: 14,
            height: 14,
        }
    },
    sticky: {
      position: "sticky",
      background: "#FFF",
    },
    modelIndicator: {
      display: "flex",
      alignItems: "center",
      //cursor: "pointer" 
    },
    headerActions: {
      display: "flex",
      justifyContent: "space-between",
      gap: 48
    }
}));