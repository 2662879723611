import { Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import HeaderContainer from "../../Components/Headers/HeaderContainer/HeaderContainer";
import HeaderProspects from "../../Components/Headers/HeaderProspects";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom/cjs/react-router-dom";
import Impacts from "../BookAnalysis/Impacts/Impacts";
import Opportunities from "../BookAnalysis/Opportunities/Opportunities";
import { selectHeaders, selectProductsData } from "../BookAnalysis/BookAnalysisSlice";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    demoText: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "20px",
        color: '#515151', 
        paddingBottom: 34,       
        "& span": {
            color: '#363ED3', 
            textDecoration: "underline",
            cursor: "pointer", 
            fontWeight: 500
        }
        
    },
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 6,
        borderTopLeftRadius: 0,
        padding: "39px 52px 48px 52px"
    }

}));

const PortfolioPage = ({ demo }) => {
    const classes = useStyles()
    const history = useHistory()
    const rootRef = useRef(null)
    const isAnnuities = localStorage.getItem("accountType") === "annuities"
    const headerProspects = useSelector(selectHeaders)
    const headersData = useSelector(selectProductsData)

    const projectType = (
        (headersData?.ui_runs?.length > 0 && headersData?.ui_runs?.find(({ config }) => config?.product_type?.toLowerCase() === "annuities") !== undefined)
        || (headersData?.lapse_runs?.length > 0 && headersData?.lapse_runs?.find(({ config }) => config?.product_type?.toLowerCase() === "annuities") !== undefined)
        ? "annuities" : "regular"
    )

    return (
    <Grid container direction="column" ref={rootRef}>
        <Switch>
            <Route path="/impacts">
            <HeaderContainer title={`${isAnnuities ? "AUM Predicted" : "Premium Predicted"}`}>
                <HeaderProspects data={headerProspects} titles={["Projects", "Prediction Start Date", "Prospects", `${isAnnuities ? "AUM Upside" : "Premium Upside"}`]} isBook={false}/>
            </HeaderContainer>                    
            </Route>   
            <Route path="/opportunities">   
            <HeaderContainer title={"Prospect Opportunity"}>
                <HeaderProspects data={headerProspects} titles={["Projects", "Prediction Start Date", "Prospects", `${isAnnuities ? "AUM Upside" : "Premium Upside"}`]} isBook={false}/>
            </HeaderContainer>                    
            </Route>  
        </Switch>

        {demo && 
        <Grid container justifyContent="flex-start">
            <Typography className={classes.demoText}>
                The following analysis is based on a demo data. Please <span onClick={() => history.push("/registration")}>create account</span> to see your real results.
            </Typography>
        </Grid>
        }
        <Grid className={classes.main} style={{ maxWidth: window.innerWidth-143 }}>
            <Switch>
                <Route path="/impacts">
                    <Impacts/>
                </Route>   
                <Route path="/opportunities">   
                    <Opportunities outSideRef={rootRef} projectType={projectType}/>
                </Route>  
            </Switch>
            
        </Grid>

    </Grid>
    )
}

export default PortfolioPage