import { color } from "@amcharts/amcharts4/core";

export const REACT_APP_WEBSOCKET_URL = "wss://";

export const defaultChartColors = ["#005071", "#a2e2ec", "#8E42BE", "#36ACA0", "#3695ac", "#BB36BB"]

export const mapperItemsUI = {
  "policy": false,
  "Product": false,
  "Policy Issue Date": false,
  "Age": false,
  "Gender": false,
  "Zip": false,
  "Premium": false,
  "Face Amount": false
} 
export const mapperItemsLapse = {
  "policy": false,
  "Product": false,
  "Policy Issue Date": false,
  "Age": false,
  "Gender": false,
  "Zip": false,
  "Premium": false,
  "Face Amount": false
}  

export const defaultColumnsAnnuities = { 
    "Policy Id": true, 
    "Product Name": true, 
    "Product Type": true, 
    "Age": true, 
    "Contract Value": true, 
    "Yrs Left in Surr Charge": true, 
    "Income Account Value": false, 
    "Surrender Value": true, 
    "LIBR status": false, 
    "LIB Fee": false, 
    "LIBR Payment if Active": false, 
    "Withdraw Activity": false, 
    "Subaccount activity": false, 
    "Contract Loss $": false, 
    "Contract Loss %": false, 
    "Income Account Loss $": false, 
    "Income loss %": false, 
    "Annual withdrawals": false, 
    "Issue state": true, 
    "Residency State": false, 
    "Agent": true, 
    "Agent status": false, 
    "Duration": true,
    "Lump Sum": true,
    "Upside Story": true, 
    "AUM Upside": false, 
    "Priority": true 
  }
export const defaultColumnsRunAnnuities = { 
    "Policy Id": true, 
    "Product Name": true, 
    "Product Type": true, 
    "Age": true, 
    "Contract Value": true, 
    "Yrs Left in Surr Charge": true, 
    "Surrender Value": true, 
    "Issue state": true, 
    "Agent": true, 
    "Duration": true,
    "Lump Sum": true,
    "Upside Story": true, 
    "Priority": true 
  }
export const defaultColumnsNewLapse = { 
    "Policy ID": true, 
    "Product Type": true, 
    "Current Age": true, 
    "Annual Premium": true, 
    "Face Amount": true, 
    "Gender": true, 
    "Issue Date": true, 
    "State": true, 
    "Zip Code": true, 
    "Agent ID": true, 
    "Orphan": true, 
    "Upside Story": true, 
    "Rank": true,
  }
export const defaultColumnsLife = { 
    "Policy ID": true, 
    "Product Name": true, 
    "Product Type": true, 
    "Current Age": true, 
    "Annual Premium": true, 
    "Account Value": true, 
    "Issue Date": true, 
    "Upside Story": true, 
    "Premium Upside": true, 
    "Priority": true, 
    "Order": true,
    "Status": true, 
    "Opportunity Expiration Date": true 
  }
  export const defaultColumnsBroker = { 
      "Policy ID": true,
      "Client Name": true,
      "Product": true,
      "Product Type": true,
      "Current Age": true, 
      "Annual Premium": true, 
      "Face Amount": true,
      "Cash Value": true,
      "Issue Date": true, 
      "Date of Birth": true, 
      "Story": true, 
      "Suggested Premium": true, 
      "Priority": true, 
    }
export const defaultColumnsLifeWL = { 
    "Policy ID": true, 
    "Product Name": true, 
    "Product Type": true, 
    "Current Age": true, 
    "Annual Premium": true, 
    "Account Value": true,
    "Cash Value": true, 
    "Issue Date": true, 
    "Upside Story": true, 
    "Priority": true, 
    "Suggested Premium": true, 
    "Premium Sensitivity": true,
    "Status": true, 
    "Opportunity Expiration Date": true 
  }
export const defaultColumnsCluster = { 
    "ID": true, 
    "Product Name": true, 
    "Current Age": true, 
    "Annual Premium": false, 
    "Face Amount": false, 
    "Date of Birth": true, 
    "State": true, 
    "City": true,
    "Agency": true,
    "Group": true,
    "Income": true,
    "Investment Focus": true,
    "Cluster": true
}

export const sortKeysAnnuities = {
  "Policy Id": "policy",
  "Product Name": "product_name",
  "Product Type": "product",
  "Age": "age",
  "Contract Value": "cash_value",
  "Yrs Left in Surr Charge": "YRS_REMAIN_SC",
  "Income Account Value": "IAV_LAG",
  "Surrender Value": "CSV_LAG",
  "LIBR status": "RIDER_STATUS_LAG_WITH_FEE",
  "LIBR Payment if Active": "QTD_LIBR_WITHS_MONTH_CUMSUM_LAG",
  "Withdraw Activity": "RATIO_WITHS_LAG",
  "Subaccount activity": "SUB_ACTIVITY",
  "Contract Loss %": "CONTRACT_LOSS",
  "Contract Loss $": "CONTRACT_LOSS_DOLLARS",
  "Income loss %": "INCOME_ACOUNT_LOSS",
  "Income Account Loss $": "INCOME_ACOUNT_LOSS_DOLLARS",
  "Annual withdrawals": "QTD_WITHS_MONTH_CUMSUM_LAG",
  "Issue state": "state",
  "Residency State": "RES_STATE_LAG",
  "Agent": "servicing_agent_deid",
  "Agent status": "AGENT_STATUS",
  "Duration": "duration_segment",
  "Lump Sum": "premium_paid_amount",
  "Upside Story": "story_lapse",
  "Cluster": "story",
  "AUM Upside": "medium_ppr",
  "Priority": "PRIORITY",
}

export const sortKeysLife = {
  "Policy Id": "policy",
  "Product Name": "MARKET_NAME_LAG",
  "Product Type": "product_type",
  "Age": "age",
  "Annual Premium": "premium",
  "Face Amount": "face_amount",
  "Account Value": "account_value",
  "Issue Date": "issue_date",
  "Upside Story": "story",
  "Premium Upside": "mean_premium_term",
  "Label": "high_m_res",
  "Probability to lapse": "lapse_score",
  "Suggested Premium": "annualized_premium_res",
  "Premium Sensitivity": "annualized_premium_sensitivity_NP",
}

export const sortKeysRunLife = {
  "Policy Id": "policy",
  "Policy ID": "policy",
  "Product Name": "MARKET_NAME_LAG",
  "Product Type": "product",
  "Current Age": "age_at_issue",
  "Annual Premium": "annualized_premium",
  "Face Amount": "face_amount",
  "Account Value": "account_value",
  "Issue Date": "issue_date",
  "Date of Birth": "dob",
  "State": "State",
  "City": "City",
  "Agency": "partner",
  "Group": "Group",
  "Income": "per_cap_income",
  "Investment Focus": "inv_risk",
  "Upside Story": "story_lapse",
  "Cluster": "cluster",
  "Premium Upside": "mean_premium_term",
  "Suggested Premium": "annualized_premium_res",
  "Order": "lapse_rank",
  "Priority": "label",
  "Premium Sensitivity": "annualized_premium_sensitivity_NP",
  "Status": "status",
  "Opportunity Expiration Date": "issue_date",
}

export const sortKeysRunNewLapse = {
  "Policy ID": "policy",
  "Product Name": "MARKET_NAME_LAG",
  "Product Type": "product",
  "Current Age": "age",
  "Annual Premium": "premium",
  "Face Amount": "face_amount",
  "Cash Value": "cash_value",
  "Issue Date": "issue_date",
  "Date of Birth": "dob",
  "State": "State",
  "City": "City",
  "Gender": "gender",
  "Agency": "partner",
  "Group": "Group",
  "Income": "per_cap_income",
  "Investment Focus": "inv_risk",
  "Upside Story": "story",
  "Cluster": "cluster",
  "Zip Code": "zip_code",
  "Agent ID": "agent_initials",
  "Orphan": "agent_terminated",
  "Premium Upside": "premium",
  "Suggested Premium": "annualized_premium_res",
  "Probability to lapse": "lapse_score",
  "Premium Sensitivity": "annualized_premium_sensitivity_NP",
  "Status": "status",
  "Opportunity Expiration Date": "issue_date",
  "Rank": "rank",
}

export const sortKeysBroker = {
  "Policy ID": "policy",
  "Client Name": "policy",
  "Product": "product",
  "Product Type": "product_name",
  "Current Age": "age_at_issue",
  "Annual Premium": "annualized_premium",
  "Face Amount": "face_amount",
  "Cash Value": "census_household_income_average_weighted",
  "Issue Date": "issue_date",
  "Date of Birth": "dob",
  "State": "State",
  "City": "City",
  "Story": "story",
  "Suggested Premium": "annualized_premium_recommended",
  "Sensitivity": "annualized_premium_sensitivity_DML",
  "Priority": "rank",
}

export const sortKeysLeads = {
  "Name": "firstname",
  "Email": "email",
  "Phone": "phone",
  "Created At": "created_at",
  "Updated At": "updated_at",
  "Company": "company",
  "Income": "income",
  "Is Agent": "isagent",
  "Status": "status",
  "utm_campaign": "utm_campaign",
  "utm_medium": "utm_medium",
  "utm_source": "utm_source"
}

export const headerKeysAnnuities = {
  "Policy Id": "Policy Id",
  "Product Name": "Product Name",
  "Product Type": "Product Type",
  "Age": "Age",
  "Contract Value": "Contract Value",
  "Yrs Left in Surr Charge": "Yrs Left in Surr Charge",
  "Income Account Value": "Income Account Value",
  "Surrender Value": "Surrender Value",
  "LIBR status": "LIBR status",
  "LIBR Payment if Active": "LIB Payment",
  "Withdraw Activity": "Withdraw Activity",
  "Subaccount activity": "Subaccount activity",
  "Contract Loss $": "Contract Loss $",
  "Contract Loss %": "Contract Loss %",
  "Income Account Loss $": "Income Account Loss $",
  "Income loss %": "Income loss %",
  "Annual withdrawals": "Annual withdrawals", 
  "Issue state": "Issue state",
  "Residency State": "Residency State",
  "Agent": "Agent",
  "Agent status": "Agent status",
  "Duration": "Duration",
  "Lump Sum": "Lump Sum",
  "Upside Story": "Upside Story",
  "AUM Upside": "AUM Upside",
  "Priority": "Priority",
}

export const headerKeysLife = {
  "Policy Id": "Policy ID",
  "Product Name": "Product Name",
  "Product Type": "Product Type",
  "Current Age": "Current Age",
  "Annual Premium": "Annual Premium",
  "Face Amount": "Face Amount",
  "Gender": "Gender",
  "Account Value": "Account Value",
  "Issue Date": "Issue Date",
  "State": "State",
  "Zip Code": "Zip Code",
  "Agent Id": "Agent ID",
  "Orphan": "Orphan",
  "Upside Story": "Upside Story",
  "Premium Upside": "Premium Upside",
  "Suggested Premium": "Suggested Premium",
  "Probability to lapse": "Probability to lapse",
  "Priority": "Priority",
  "Order": "Order",
  "Premium Sensitivity": "Premium Sensitivity",
  "Rank": "Rank",
}

export const headerKeysBroker = {
  "Policy ID": "Policy ID",
  "Client Name": "Client Name",
  "Product": "Product",
  "Product Type": "Product Type",
  "Current Age": "Current Age",
  "Annual Premium": "Annual Premium",
  "Face Amount": "Face Amount",
  "Cash Value": "Cash Value",
  "Issue Date": "Issue Date",
  "Date of Birth": "Date of Birth",
  "Story": "Story",
  "Suggested Premium": "Suggested Premium",
  "Sensitivity": "Sensitivity",
  "Priority": "Priority",
}

export const prospectsAnnuitiesTableHeaders = [
  'Policy Id',
  'Product Type',
  'Product Name',
  'Age',
  'Contract Value',
  'Yrs Left in Surr Charge',
  'Income Account Value',
  'Surrender Value',
  'LIBR status',
  'LIBR Payment if Active',
  'Withdraw Activity',
  'Subaccount activity',
  'Contract Loss %',
  'Contract Loss $',
  'Income loss %',
  'Income Account Loss $',
  'Annual withdrawals',
  'Issue state',
  'Residency State',
  'Agent',
  'Agent status',
  'Duration',
  'Lump Sum',
  'Upside Story',
  'AUM Upside',
  'Priority',
]

export const prospectsLifeTableHeaders = [
  'Policy Id',
  'Product Type',
  'Current Age',
  'Annual Premium',
  'Face Amount',
  'Gender',
  'Issue Date',
  'State',
  'Zip Code',
  'Agent Id',
  'Orphan',
  'Upside Story',
  'Suggested Premium',
  'Rank',
]

export const prospectsBrokerTableHeaders = [
  'Policy ID',
  'Client Name',
  'Product',
  'Product Type',
  'Current Age',
  'Annual Premium',
  'Face Amount',
  'Cash Value',
  'Issue Date',
  'Date of Birth',
  'Story',
  'Suggested Premium',
  'Sensitivity',
  'Priority'
]

export const leadsTableHeaders = [
  'Name',
  'Email',
  'Phone',
  'Created At',
  'Updated At',
  'Company',
  'Income',
  'Is Agent',
  'Status',
  'utm_campaign',
  'utm_medium',
  'utm_source',
]

export const runLifeTableHeaders = [
  'Policy Id',
  'Product Type',
  'Current Age',
  'Annual Premium',
  'Face Amount',
  'Gender',
  'Issue Date',
  'State',
  'Zip Code',
  'Agent Id',
  'Orphan',
  'Upside Story',
  'Suggested Premium',
  'Rank',
] 

export const headerRunKeysLife = {
  "ID": "ID",
  "Policy Id": "Policy ID",
  "Product Name": "Product Name",
  "Product Type": "Product Type",
  "Current Age": "Current Age",
  "Annual Premium": "Annual Premium",
  "Face Amount": "Face Amount",
  "Gender": "Gender",
  "Account Value": "Account Value",
  "Cash Value": "Cash Value",
  "Issue Date": "Issue Date",
  "Issue Date": "Issue Date",
  "State": "State",
  "Zip Code": "Zip Code",
  "Agent Id": "Agent ID",
  "Orphan": "Orphan",
  "Date of Birth": "Date of Birth",
  "State": "State",
  "City": "City",
  "Agency": "Agency",
  "Group": "Group",
  "Income": "Income",
  "Investment Focus": "Investment Focus",
  "Upside Story": "Upside Story",
  "Cluster": "Cluster",
  "Premium Upside": "Premium Upside",
  "Suggested Premium": "Suggested Premium",
  "Probability to lapse": "Probability to lapse",
  "Order": "Order",
  "Priority": "Priority",
  "Premium Sensitivity": "Premium Sensitivity",
  "Status": "Status",
  "Opportunity Expiration Date": "Opportunity Expiration Date",
  "Rank": "Rank",
}

export const runAnnuitiesTableHeaders = [
  'Policy Id',
  'Product Type',
  'Product Name',
  'Age',
  'Contract Value',
  'Yrs Left in Surr Charge',
  'Surrender Value',
  'Issue state',
  'Agent',
  'Duration',
  'Lump Sum',
  'Upside Story',
  'AUM Upside',
  'Priority',
]

export const runClusterTableHeaders = [
  'ID',
  'Product Name',
  'Product Type',
  'Current Age',
  'Date of Birth',
  'State',
  'City',
  'Agency',
  'Group',
  'Income',
  'Investment Focus',
  'Cluster',
  'Suggested Premium',
]

export const headerRunKeysAnnuities = {
  "Policy ID": "Policy ID",
  "Product Name": "Product Name",
  "Product Type": "Product Type",
  "Age": "Age",
  "Contract Value": "Contract Value",
  "Surrender Value": "Surrender Value",
  "Years Left in Surrender Charge": "Years Left in Surrender Charge",
  "Upside Story": "Upside Story",
  "Cluster": "Cluster",
  "Surrender Probability": "Surrender Probability",
  "AUM Upside": "AUM Upside",
  "Status": "Status",
  "Opportunity Expiration Date": "Opportunity Expiration Date",
  "State": "State",
  "City": "City",
}

export const prospectsAnnuitiesDataKeys = {
  "policy": "Policy Id",
  "product_market_name": "Product Name",
  "product_type": "Product Type",
  "age": "Age",
  "contract_value": "Contract Value",
  "years": "Years Left in Surrender Charge",
  "account_value": "Income Account Value",
  "surr_value": "Surrender Value",
  "libr_status": "LIBR status",
  "libr_payment": "LIBR Payment if Active",
  "withdraw_activity": "Withdraw Activity",
  "sub_activity": "Subaccount activity",
  "contract_loss_dollars": "Contract Loss $",
  "income_loss_dollars": "Income Account Loss $",
  "contract_loss": "Contract Loss %",
  "income_loss": "Income loss %",
  "withdraw_annual": "Annual withdrawals",
  "issue_state": "Issue state",
  "residence_state": "Residency State",
  "agent_id": "Agent",
  "agent_status": "Agent status",
  "duration_segment": 'Duration',
  "premium_paid_amount": 'Lump Sum',
  "story": "Upside Story",
  "aum_upside": "AUM Upside",
  "priority": "Priority",
}

export const prospectsLifeDataKeys = {
  "policy": "Policy ID",
  "product_name": "Product Name",
  "product_type": "Product Type",
  "age": "Current Age",
  "premium": "Annual Premium",
  "face_amount": "Face Amount",
  "account_value": "Account Value",
  "gender": "Gender",
  "issue_date": "Issue Date",
  "zip_code": "Zip Code",
  "agent_initials": "Agent ID",
  "agent_terminated": "Orphan",
  "story": "Upside Story",
  "mean_premium_term": "Premium Upside",  
  "annualized_premium_res": "Suggested Premium",
  "priority": "Probability to lapse",
  "label": "Priority",
  "lapse_rank": "Order",
  "annualized_premium_sensitivity_NP": "Premium Sensitivity",
  "status": "Status",
  "expiration_date": "Opportunity Expiration Date",
  "State": "State",
  "rank": "Rank",
  "City": "City"
}

export const prospectsBrokerDataKeys = {
  "policy": "Policy ID",
  "client_name": "Client Name",
  "product": "Product",
  "product_name": "Product Type",
  "age": "Current Age",
  "premium": "Annual Premium",
  "face_amount": "Face Amount",
  "cash_value": "Cash Value",
  "issue_date": "Issue Date",
  "dob": "Date of Birth",
  "story": "Story",
  "mean_premium_term": "Suggested Premium",  
  "priority": "Sensitivity",
  "upsell_rank": "Priority"
}

export const runLifeDataKeys = {
  "policy": "Policy ID",
  "product_name": "Product Name",
  "product_type": "Product Type",
  "age": "Current Age",
  "premium": "Annual Premium",
  "face_amount": "Face Amount",
  "account_value": "Account Value",
  "gender": "Gender",
  "cash_value": "Cash Value",
  "issue_date": "Issue Date",
  "zip_code": "Zip Code",
  "agent_initials": "Agent ID",
  "story": "Upside Story",
  "mean_premium_term": "Premium Upside",  
  "annualized_premium_res": "Suggested Premium",
  "priority": "Probability to lapse",
  "lapse_score": "Probability to lapse",
  "label": "Priority",
  "annualized_premium_sensitivity_NP": "Premium Sensitivity",
  "status": "Status",
  "expiration_date": "Opportunity Expiration Date",
  "State": "State",
  "rank": "Rank",
  "State": "State",
  "City": "City"
}

export const runClusterDataKeys = {
  "policy": "Policy ID",
  "product_name": "Product Name",
  "product_type": "Product Type",
  "age": "Current Age",
  "premium": "Annual Premium",
  "face_amount": "Face Amount",
  "dob": "Date of Birth",
  "story": "Cluster",
  "mean_premium_term": "Premium Upside",
  "annualized_premium_res": "Suggested Premium",
  "priority": "Probability to lapse",
  "status": "Status",
  "expiration_date": "Opportunity Expiration Date",
  "State": "State",
  "City": "City",
}
export const runAnnuitiesDataKeysLapse = {
  "policy": "Policy Id",
  "MARKET_NAME_LAG": "Product Name",
  "product_name": "Product Name",
  "product": "Product Type",
  "age": "Age",
  "cash_value": "Contract Value",
  "account_value": "Account Value",
  "CSV_LAG": "Surrender Value",
  "YRS_REMAIN_SC": "Yrs Left in Surr Charge",
  "servicing_agent_deid": "Agent",
  "duration_segment": "Duration",
  "premium_paid_amount": "Lump Sum",
  "story": "Upside Story",
  "story_lapse": "Upside Story",
  "PRIORITY": "Surrender Probability",
  "lapse_rank_bin": "Priority",
  "medium_ppr": "AUM Upside",
  "mean_premium_term": "AUM Upside",
  "status": "Status",
  "label": "Label",
  "expiration_date": "Opportunity Expiration Date",
  "State": "State",
  "state": "Issue State",
  "City": "City",
}


export const prospectsLifeHeaderKeys = {
  "Policy ID": "policy",
  "Product Name": "product_name",
  "Product Type": "product_type",
  "Current Age": "age",
  "Annual Premium": "premium",
  "Face Amount": "face_amount",
  "Cash Value": "cash_value",
  "Issue Date": "issue_date",
  "Upside Story": "story",
  "Cluster": "story",
  "Premium Upside": "mean_premium_term",
  "Suggested Premium": "annualized_premium_res",
  "Probability to lapse": "lapse_score",
  "Priority": "label",
  "Premium Sensitivity": "annualized_premium_sensitivity_NP",
  "Status": "status",
  "Opportunity Expiration Date": "expiration_date",
  "Rank": "rank",
}


export const runAnnuitiesHeaderKeysLapse = {
  "Policy Id": "policy",
  "Product Name": "product_name",
  "Product Type": "product_type",
  "Age": "age",
  "Contract Value": "cash_value",
  "Surrender Value": "CSV_LAG",
  "Years Left in Surrender Charge": "YRS_REMAIN_SC",
  "Upside Story": "story_lapse",
  "Priority": "lapse_rank_bin",
  "Cluster": "story",
  "Surrender Probability": "PRIORITY",
  "AUM Upside": "medium_ppr",
  "Status": "status",
  "Opportunity Expiration Date": "expiration_date",
}
export const runAnnuitiesHeaderKeysUI = {
  "Policy Id": "policy",
  "Product Name": "product_name",
  "Product Type": "product_type",
  "Age": "age",
  "Contract Value": "cash_value",
  "Surrender Value": "CSV_LAG",
  "Years Left in Surrender Charge": "YRS_REMAIN_SC",
  "Upside Story": "story_lapse",
  "Cluster": "story",
  "Probability to lapse": "PRIORITY",
  "AUM Upside": "mean_premium_term",
  "Status": "status",
  "Opportunity Expiration Date": "expiration_date",
}

export const frozenGridFieldsAnnuities = ["story", "aum_upside", "priority"]
export const frozenGridFieldsLife = ["story", "annualized_premium_res", "rank"]
export const frozenGridFieldsBroker = ["story",  "mean_premium_term", "priority", "upsell_rank"]
export const frozenGridFieldsRunLife = ["story", "rank", "annualized_premium_sensitivity_NP", "mean_premium_term", "lapse_score", "status", "expiration_date"]
export const frozenGridFieldsRunUILife = ["story",  "rank", "status", "expiration_date"]
export const frozenGridFieldsRunAnnuities = ["story_lapse", "lapse_rank_bin",  "medium_ppr", "status", "expiration_date"]
export const frozenGridFieldsRunUIAnnuities = ["story", "mean_premium_term", "status", "expiration_date"]
export const frozenGridColumnsPositions = {
  0: 0,
  1: 91,
  2: 215,
  3: 308,
}
export const frozenGridColumnsPositionsBroker = {
  0: 0,
  1: 104,
  2: 226,
  3: 341,
}

export const frozenGridRunColumnsPositions = {
  0: 0,
  1: 91,
  2: 214,
  3: 325,
  4: 452
}

export const frozenGridRunUIColumnsPositions = {
  0: 0,
  1: 91,
  2: 214,
  3: 325
}


export const frozenGridRunColumnsPositionsAnnuities = {
  0: 0,
  1: 94,
  2: 224,
  3: 325,
  4: 454
}

export const frozenGridRunUIColumnsPositionsAnnuities = {
  0: 0,
  1: 125,
  2: 225,
  3: 325
}

export const frozenGridColumnsAnnuities = [
    'Upside Story',
    'Surrender Probability',
    'AUM Upside',
    'Status',
    'Opportunity Expiration Date',
    'Priority',
]
export const frozenGridColumnsLife = [
    'Upside Story',
    'Rank',
]
export const frozenGridColumnsBroker = [
    'Story',
    'Suggested Premium',
    'Sensitivity',
    'Priority',
]

export const frozenGridColumnsCluster = [
  'Cluster',
  'Suggested Premium',
]

export const frozenRunGridColumnsLife = [
    'Upside Story',
    'Rank',
    'Premium Sensitivity',
    'Premium Upside',
    'Priority',
    'Probability to lapse',
    'Status',
    'Opportunity Expiration Date',
]

export const projectsSortKeys = {
  "Creation Date": "created_at",
  "Processed Records": "total_policies",
  "Number of Prospects": "prospects",
  "Potential AUM Upside": "aum",
  "Potential Premium Upside": "aum",
  "Covered by Campaigns": "campaigns",
}

export const runsSortKeys = {
  "Run Name": "model_type",
  "Run Date": "created_at",
  "Run Status": "run_id",
  "Prediction Date": "created_at",
  "Run ID": "run_id",
  "Status": "status",
  "Number of Prospects": "total_policies",
  "Premium Upside": "potential_retained",
  "Suggested Premium": "annualized_premium_res",
  "AUM Upside": "potential_retained",
  "Covered by Campaigns": "run_id"
}
export const lapseOnlyFields = ["Probability to lapse", 'Premium Sensitivity', "Surrender Probability", 'Suggested Premium']

export const reportTabsLife = [
  'Premium Breakdown',
  'Prospects',
]
export const reportTabsAnnuities = [
  'AUM Breakdown',
  'Prospects',
]
export const reportTabsCluster = [
  'Prospects Breakdown',
  'Prospects',
]

export const priorityMaping = {
  "H": "high",
  "M": "mid",
  "L": "low"
}

export const priorityColors = {
  "high": {
    background: "#F7D1CB",
    color: "#A22116",
    width: 48,
  },
  "medium": {
    background: "#FBE6A8",
    color: "#453924",
    width: 71,
  },
  "low": {
    background: "#D9ECC1",
    color: "#35714E",
    width: 44,
  }
}

export const usStatesData = [
  {
      "name": "Alabama",
      "abbreviation": "AL"
  },
  {
      "name": "Alaska",
      "abbreviation": "AK"
  },
  {
      "name": "American Samoa",
      "abbreviation": "AS"
  },
  {
      "name": "Arizona",
      "abbreviation": "AZ"
  },
  {
      "name": "Arkansas",
      "abbreviation": "AR"
  },
  {
      "name": "California",
      "abbreviation": "CA"
  },
  {
      "name": "Colorado",
      "abbreviation": "CO"
  },
  {
      "name": "Connecticut",
      "abbreviation": "CT"
  },
  {
      "name": "Delaware",
      "abbreviation": "DE"
  },
  {
      "name": "District Of Columbia",
      "abbreviation": "DC"
  },
  {
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
  },
  {
      "name": "Florida",
      "abbreviation": "FL"
  },
  {
      "name": "Georgia",
      "abbreviation": "GA"
  },
  {
      "name": "Guam",
      "abbreviation": "GU"
  },
  {
      "name": "Hawaii",
      "abbreviation": "HI"
  },
  {
      "name": "Idaho",
      "abbreviation": "ID"
  },
  {
      "name": "Illinois",
      "abbreviation": "IL"
  },
  {
      "name": "Indiana",
      "abbreviation": "IN"
  },
  {
      "name": "Iowa",
      "abbreviation": "IA"
  },
  {
      "name": "Kansas",
      "abbreviation": "KS"
  },
  {
      "name": "Kentucky",
      "abbreviation": "KY"
  },
  {
      "name": "Louisiana",
      "abbreviation": "LA"
  },
  {
      "name": "Maine",
      "abbreviation": "ME"
  },
  {
      "name": "Marshall Islands",
      "abbreviation": "MH"
  },
  {
      "name": "Maryland",
      "abbreviation": "MD"
  },
  {
      "name": "Massachusetts",
      "abbreviation": "MA"
  },
  {
      "name": "Michigan",
      "abbreviation": "MI"
  },
  {
      "name": "Minnesota",
      "abbreviation": "MN"
  },
  {
      "name": "Mississippi",
      "abbreviation": "MS"
  },
  {
      "name": "Missouri",
      "abbreviation": "MO"
  },
  {
      "name": "Montana",
      "abbreviation": "MT"
  },
  {
      "name": "Nebraska",
      "abbreviation": "NE"
  },
  {
      "name": "Nevada",
      "abbreviation": "NV"
  },
  {
      "name": "New Hampshire",
      "abbreviation": "NH"
  },
  {
      "name": "New Jersey",
      "abbreviation": "NJ"
  },
  {
      "name": "New Mexico",
      "abbreviation": "NM"
  },
  {
      "name": "New York",
      "abbreviation": "NY"
  },
  {
      "name": "North Carolina",
      "abbreviation": "NC"
  },
  {
      "name": "North Dakota",
      "abbreviation": "ND"
  },
  {
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
  },
  {
      "name": "Ohio",
      "abbreviation": "OH"
  },
  {
      "name": "Oklahoma",
      "abbreviation": "OK"
  },
  {
      "name": "Oregon",
      "abbreviation": "OR"
  },
  {
      "name": "Palau",
      "abbreviation": "PW"
  },
  {
      "name": "Pennsylvania",
      "abbreviation": "PA"
  },
  {
      "name": "Puerto Rico",
      "abbreviation": "PR"
  },
  {
      "name": "Rhode Island",
      "abbreviation": "RI"
  },
  {
      "name": "South Carolina",
      "abbreviation": "SC"
  },
  {
      "name": "South Dakota",
      "abbreviation": "SD"
  },
  {
      "name": "Tennessee",
      "abbreviation": "TN"
  },
  {
      "name": "Texas",
      "abbreviation": "TX"
  },
  {
      "name": "Utah",
      "abbreviation": "UT"
  },
  {
      "name": "Vermont",
      "abbreviation": "VT"
  },
  {
      "name": "Virgin Islands",
      "abbreviation": "VI"
  },
  {
      "name": "Virginia",
      "abbreviation": "VA"
  },
  {
      "name": "Washington",
      "abbreviation": "WA"
  },
  {
      "name": "West Virginia",
      "abbreviation": "WV"
  },
  {
      "name": "Wisconsin",
      "abbreviation": "WI"
  },
  {
      "name": "Wyoming",
      "abbreviation": "WY"
  }
]